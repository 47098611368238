import React, { useState, useEffect, useRef } from "react";
import "./Carousel.css";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CustomCarousel = ({ images }) => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);
  const history = useHistory()

  const showSlide = index => {
    setCurrentIndex(index);
  };

  const handleTouchStart = event => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = event => {
    const currentX = event.touches[0].clientX;
    const diffX = startX - currentX;
    if (
      diffX > 0 &&
      diffX >= window.innerWidth / 4 &&
      currentIndex < images.length - 1
    ) {
      // Swipe left to show the next slide
      showSlide(currentIndex + 1);
      setStartX(currentX);
    } else if (
      diffX < 0 &&
      diffX <= -window.innerWidth / 4 &&
      currentIndex > 0
    ) {
      // Swipe right to show the previous slide
      showSlide(currentIndex - 1);
      setStartX(currentX);
    }
  };

  const handleTransitionEnd = () => {
    carouselRef.current.style.transition = "";
  };

  const handleCarouselRef = element => {
    carouselRef.current = element;
  };

  useEffect(() => {
    // Automatically change slide after the specified interval
    const timer = setInterval(() => {
      showSlide((currentIndex + 1) % images.length);
    }, 4000);

    return () => {
      clearInterval(timer);
    };
  }, [currentIndex, images.length]);

  return (
    <>
      <div
        className="custom-carousel"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="custom-carousel-slider" ref={handleCarouselRef}>
          {images.map((image, index) => (
            <div
              key={index}
              className={`slide ${index === currentIndex ? "active" : ""}`}
            >
              <img
                key={index}
                className="img-fluid for-light"
                style={{
                  height: "100%",
                  aspectRatio: "3/1",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  if(image?.link) {
                    history.push(image?.link)
                    return;
                  }

                  if (!image.WebLink && !image.PDFPath) {
                    return; // Do nothing if both WebLink and PDFPath are not available
                  }
                  const newWindow = window.open(
                    image?.WebLink || image?.PDFPath
                  );
                  if (newWindow) newWindow.opener = null;
                }}
                src={image.imgUrl}
                alt={image.imgUrl}
              />
            </div>
          ))}
        </div>
      </div>
      {images.length !== 1 ? (
        <div className="custom-carousel-indicators">
          {images.map((_, index) => (
            <span
              onClick={() => {
                setCurrentIndex(index);
              }}
              key={index}
              className={`${index === currentIndex ? "active" : ""}`}
            />
          ))}
        </div>
      ) : null}
      {/* <div className="custom-carousel">
        {data.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <Image
              key={index}
              className="img-fluid for-light"
              style={{
                width: "90%",
                marginLeft: "5%",
                marginRight: "5%",
                height: "125px",
              }}
              onClick={() => {
                const newWindow = window.open(image?.WebLink || image?.PDFPath);
                if (newWindow) newWindow.opener = null;
              }}
              src={image.BannerImage}
              alt={image.BannerImage}
            />
          </div>
        ))}
      </div>
      <div className="custom-carousel-indicators">
        {data.map((_, index) => (
          <span
            key={index}
            className={`${index === currentSlide ? "active" : ""}`}
          />
        ))}
      </div> */}
    </>
  );
};

export default CustomCarousel;
