import { useEffect, useState, useCallback } from "react";
import { DatePicker, List, Pagination, Skeleton } from "antd";
import axios from "axios";
import moment from "moment";
import DetailPoints from "./DetailPoints";

const PAGE_SIZE = 10;

const GetPaginationData = (url, startDate, endDate) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (pageNo, urlParams) => {
      setLoading(true);
      try {
        const response = await axios({
          url,
          params: {
            pageNo,
            pageSize: PAGE_SIZE,
            startDate,
            endDate,
            ...urlParams,
          },
        });
        setData(response.data.data.nonRedeemPoints.result);
        setTotal(response.data.data.nonRedeemPoints.totalRecord);
        setLoading(false);
      } catch (error) {
        console.error("Error => ", error);
        setLoading(false);
      }
    },
    [url, endDate]
  );

  const gotoPage = (pageNo, urlParams) => {
    setCurrentPage(pageNo);
    fetchData(pageNo, { ...urlParams });
  };

  useEffect(() => {
    //currentPage
    fetchData(1);
  }, [fetchData]);

  const pages = Array.from(
    { length: Math.ceil(total / PAGE_SIZE) },
    (v, i) => i + 1
  );

  return { data, gotoPage, currentPage, pages, total, loading, fetchData };
};

const NonRedeemPoints = ({ userId }) => {
  const [showDetailPoints, setShowDetailPoints] = useState(false);
  const [dataDetailPoint, setDataDetailPoint] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { data, gotoPage, currentPage, loading, total } = GetPaginationData(
    "earnedPoints" + (userId ? `&userId=${userId}` : ""),
    startDate?.format("YYYY-MM-DD"),
    endDate?.format("YYYY-MM-DD")
  );

  return (
    <>
      <DetailPoints
        show={showDetailPoints}
        setShow={setShowDetailPoints}
        data={dataDetailPoint}
      />
      <div className="d-flex justify-content-around">
        <DatePicker
          className="mt-2"
          placeholder="Start Date"
          onChange={date => {
            setEndDate(null);
            setStartDate(date);
          }}
          format="YYYY-MM-DD"
          value={startDate}
        />
        <DatePicker
          disabled={!startDate}
          className="mt-2"
          placeholder="End Date"
          onChange={date => {
            if (moment(date).diff(startDate, "months") >= 3) {
              Swal.fire("Please Enter Date Range Between 3 Months");
            } else {
              setEndDate(date);
            }
          }}
          value={endDate}
        />
      </div>
      <Skeleton active loading={loading}>
        <div bodystyle={{ paddingTop: "15px" }}>
          <List
            style={{ marginTop: "10px" }}
            dataSource={data}
            renderItem={(item, key) => (
              <List.Item
                onClick={() => {
                  setDataDetailPoint(item);
                  setShowDetailPoints(true);
                }}
                className="d-flex my-3 flex-row justify-content-between shadow rounded-3 p-3"
                key={key}
              >
                <div className="w-75">
                  <div
                    style={{ color: "#434343", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    {item.sChemeName || item.Comment}
                  </div>
                  <div style={{ color: "#7B7B7B", fontSize: "15px" }}>
                    {moment(item.CreatedOn).format("DD-MM-YYYY, LT")}
                  </div>
                </div>
                <div
                  style={{ color: "#3A69DD", fontSize: "20px" }}
                  className="fw-bold"
                >
                  🏆 {item.point}
                </div>
              </List.Item>
            )}
          />
          <nav
            className="mt-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Pagination
              showQuickJumper
              current={currentPage}
              defaultPageSize={10}
              pageSize={PAGE_SIZE}
              total={total}
              showSizeChanger={false}
              onChange={page => gotoPage(page)}
            />
          </nav>
        </div>
      </Skeleton>
    </>
  );
};

export default NonRedeemPoints;
