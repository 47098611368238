import React, { useState } from 'react'
import moment from 'moment'
import { useAuth } from '../../context/auth-context'
import InvoiceDetailModal from './InvoiceDetailModal'
import { asyncWrap } from '../../utils/utils'
import axios from 'axios'

function RetailerInvoicesCard({data, getInvoiceDetail}) {
    const {user} = useAuth()

  return (
    <>
    <div
        style={{ background: "#2E008B" }}
        className="d-flex flex-column p-2 rounded-2 text-white"
        onClick={() => getInvoiceDetail(data)}
      >
        <div className="d-flex flex-row">
          <div
            style={{
              width: "80px",
              height: "70px",
              background: "#6A68D9",
              borderRadius: "5px",
            }}
          ></div>
          <div
            className="d-flex flex-row justify-content-between"
            style={{ marginLeft: "10px", width: "100%" }}
          >
            <div className="d-flex flex-column text-start justify-content-between">
              <div>{user?.profile?.fullName}</div>
              <div>{data?.InvoiceNumber}</div>
              <div>{moment(data?.InvoiceDate).format("DD-MM-YYYY")}</div>
            </div>
            <div className="d-flex flex-column justify-content-center">
              {/* <div>&#8377;{data?.TotalAmount?.toLocaleString("en-IN")}</div> */}
              <div>{data?.IsApproved === 0 ? "Pending" : data?.IsApproved === 1 ? "Approved" : "Rejected"}</div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      {/* <InvoiceDetailModal 
      invoiceData={invoiceData}
      showInvoiceModal={showInvoiceModal}
      setShowInvoiceModal={setShowInvoiceModal}
      /> */}
      </>
  )
}

export default RetailerInvoicesCard