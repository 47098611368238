import React from "react";
import { Modal } from "react-bootstrap";
import { Progress } from "antd";
import ReactSpeedometer from "react-d3-speedometer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function TargetAndAchievements({
  show,
  setShow,
  targetData,
  totalCompleteAchievements,
}) {
  console.log(totalCompleteAchievements);
  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body>
        <div className="d-flex flex-column p-2">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="fs-1">Targets & Achievements</div>
            <div className="d-flex flex-row">
              <Link
                to={"/home"}
                style={{
                  background: "#2E008B",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                }}
              ></Link>
            </div>
          </div>

          <div
            className="d-flex justify-content-center mt-4"
            style={{ height: "170px" }}
          >
            <ReactSpeedometer
              maxValue={targetData?.length}
              value={totalCompleteAchievements}
              segments={targetData?.length}
              needleTransitionDuration={3000}
              segmentColors={["#2E008A"]}
              needleColor={"#AFAFAF"}
            />
          </div>

          {/* <div className="mt-4" style={{ position: "relative" }}>
            <img
              src="/assets/images/target/circle.png"
              style={{ width: "100%" }}
            />
            <img
              src="/assets/images/target/arrow.png"
              style={{
                width: "100px",
                height: "100px",
                transform: "rotate(0deg)",
                transformOrigin: "100px 80px",
                position: "absolute",
                top: "50%",
                left: "calc(50% - 90px)",
              }}
            />
          </div> */}

          <div className="mt-4">
            <div
              style={{ background: "#2E008B" }}
              className="d-flex flex-row align-items-center justify-content-around rounded-4 p-2"
            >
              {targetData.map((item, index) =>
                (item?.targetUnit === "rupees"
                  ? (item?.achievedSubTotal * 100) / item?.targetValue
                  : item.targetUnit === "pieces"
                  ? (item?.achievedQuantity * 100) / item?.targetValue
                  : 0) >= 100 ? (
                  <div style={{ width: "15%", position: "relative" }}>
                    <img
                      src="/assets/images/target/trophy.png"
                      style={{ width: "100%" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "5px",
                        bottom: "-5px",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textShadow: "1px 1px #000",
                      }}
                    >
                      x{index + 1}
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "15%", position: "relative" }}>
                    <img
                      src="/assets/images/target/trophy_black.png"
                      style={{ width: "100%" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "5px",
                        bottom: "-8px",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textShadow: "1px 1px #000",
                      }}
                    >
                      x{index + 1}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="mt-4">
            <h4>Target List</h4>
            {targetData.map((item, index) => (
              <div className="d-flex flex-row shadow rounded-4 p-4 mt-3">
                <Progress
                  type="circle"
                  percent={
                    item?.targetUnit === "rupees"
                      ? (item?.achievedSubTotal * 100) / item?.targetValue
                      : item.targetUnit === "pieces"
                      ? (item?.achievedQuantity * 100) / item?.targetValue
                      : 0
                  }
                  width={80}
                />
                <div
                  className="d-flex flex-column text-start"
                  style={{ width: "100%", marginLeft: "20px" }}
                >
                  <div>Sku Id: {item?.skuUniqueId}</div>
                  <div>
                    Target : {item?.targetValue} {item?.targetUnit}
                  </div>
                  <div>
                    Achievements:{" "}
                    {item?.targetUnit === "rupees"
                      ? item?.achievedSubTotal
                      : item.targetUnit === "pieces"
                      ? item?.achievedQuantity
                      : 0}{" "}
                    {item?.targetUnit}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="mt-4">
        <div
          style={{ background: "#d9d9d9" }}
          className="d-flex flex-row align-items-center justify-content-between rounded-4 p-2"
        >
          <Progress type="circle" percent={30} width={70} />
        </div>
      </div> */}

          <div className="mt-4">
            <div>Cashback Points</div>
            <div className="d-flex flex "></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TargetAndAchievements;
