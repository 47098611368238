import { Pagination, Spin } from 'antd'
import React, { useState } from 'react'
import { Modal, Image } from "react-bootstrap";
import { usePagination } from '../../hooks';
import close from "/assets/images/popclose.png";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function RetailerList({ roleId, showInvoice }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRetailer, setSelectedRetailer] = useState()
    const { data, gotoPage, currentPage, loading, total, fetchData } =
        usePagination(`/user/mapping?roleId=${roleId}&userRole=6`);

    const history = useHistory()

    const showModal = (data) => {
        setSelectedRetailer(data)
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleRedirect = (url) => {
        history.push(url, {
            userId: selectedRetailer.RetailerUserID
        })
    }

    const handleClick = (data) => {
        if(showInvoice) {
            history.push('/invoices', {
                userId: data?.RetailerUserID
            })
        } else {
            showModal(data)
        }
    }

  return (
    <div>
        {loading ? (
            <div>
                <Spin />
            </div>
        ) : data?.length > 0 ? (
            <>
            <div style={{
                backgroundColor: "#2E008B",
                padding: "10px 12px 1px 12px",
                borderRadius: "10px",
                color: "white"
            }}>
                {data.map((item, i) => (
                    <div 
                    key={i}
                    className='d-flex justify-content-between mb-2 pb-3 pt-2'
                    style={{
                        borderBottom: i === data.length - 1 ? "none" : "1.5px solid #D9D9D9"
                    }}
                    onClick={() => handleClick(item)}
                    >
                        <div style={{lineHeight: 1}}>{item?.RetailerFullName?.trim()}</div>
                        <div style={{lineHeight: 1}}>{item?.RetailerMobile}</div>
                    </div>
                ))}
            </div>
            <nav
              className="mt-5"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Pagination
                showQuickJumper
                current={currentPage}
                defaultPageSize={10}
                total={total}
                showSizeChanger={false}
                onChange={(page) => gotoPage(page)}
              />
            </nav>

            {/* modal */}
            <Modal show={isModalOpen} onHide={handleCancel} style={{
                marginTop: "35vh"
            }}>
                <Modal.Body
                className='d-flex justify-content-center'
                >
                      <span
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
            }}
          >
            <Image
              src={close}
              alt=""
              onClick={() => setIsModalOpen(false)}
            />
          </span>
                    <div className='d-flex flex-column justify-content-center gap-3'
                    style={{
                        width: "120px"
                    }}
                    >
                        <button type="button" onClick={() => handleRedirect("target-list")} className="btn btn-primary" style={{
                            backgroundColor: "#2E008B",
                            border: "none"
                            }}>View targets</button>
                        <button type="button" onClick={() => handleRedirect("points-info")} className="btn btn-primary" style={{
                            backgroundColor: "#2E008B",
                            border: "none"
                            }}>View ledger</button>
                    </div>
                </Modal.Body>
            </Modal>
            </>
        ) : (
            <div>
                No Wholesaler available
            </div>
        )}
    </div>
  )
}

export default RetailerList;