import { usePagination } from "../../hooks";
import { List, Pagination, Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import RedeemPointDetails from "./RedeemPointDetails";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";

const RedeemPoint = ({ userId }) => {
  const [showDetailPoints, setShowDetailPoints] = useState(false);
  const [dataDetailPoint, setDataDetailPoint] = useState([]);
  const [redeemPointsData, setRedeemPointsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = redeemPointsData.slice(firstIndex, lastIndex);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const getRedeemPointDetails = async () => {
    setLoading(true);
    const [error, result] = await asyncWrap(
      axios({
        method: "get",
        url: "/redeemedPoints?pageNo=1&pageSize=50" + (userId ? `&userId=${userId}` : ""),
      })
    );
    if (!result) {
      console.log(error);
      return;
    }
    setRedeemPointsData(result.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getRedeemPointDetails();
  }, []);

  return (
    <>
      <RedeemPointDetails
        show={showDetailPoints}
        setShow={setShowDetailPoints}
        data={dataDetailPoint}
      />
      <Skeleton active loading={loading}>
        <div bodystyle={{ paddingTop: "15px" }}>
          <List
            style={{ marginTop: "10px" }}
            dataSource={currentItems}
            renderItem={(item, key) => (
              <List.Item
                onClick={() => {
                  setDataDetailPoint(item);
                  setShowDetailPoints(true);
                }}
                className="d-flex my-3 flex-row justify-content-between shadow rounded-3 p-3"
                key={key}
              >
                <div className="w-75">
                  <div
                    style={{ color: "#434343", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    {item.WithdrawType}
                  </div>
                  <div style={{ color: "#7B7B7B", fontSize: "15px" }}>
                    {item.CreatedOn}
                  </div>
                </div>
                <div
                  style={{ color: "#3A69DD", fontSize: "20px" }}
                  className="fw-bold"
                >
                  🏆 {item.Point}
                </div>
              </List.Item>
            )}
          />
          <nav
            className="mt-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Pagination
              showQuickJumper
              current={currentPage}
              defaultPageSize={10}
              pageSize={itemsPerPage}
              total={redeemPointsData.length}
              showSizeChanger={false}
              onChange={page => handlePageChange(page)}
            />
          </nav>
        </div>
      </Skeleton>
    </>
  );
};

export default RedeemPoint;
