export const translationsEnglish = {
  // home page translation
  current_balance: "Current Balance",
  latest_achivements: "Latest Achievements",
  badges: "Badges",
  quote: "Quote",
  your_opinion_matters: "Your Opinion Matters",
  whats_new: "What's New?",
  contest: "Contest",
  movement: "Movement",
  avatar: "Avatar",
  banners: "Banners",
  schemeList: "Scheme List",

  //Bottom navbar translation
  home: "Home",
  scan: "Scan",
  redeem: "Redeem",
  stories: "Stories",

  // drawer menu translation
  spin_win: "Spin & Win",
  opportunities: "Opportunities",
  analytics: "Analytics",
  rewards_cashback: "Rewards & Cashback",
  points_information: "Points Information",
  games: "Games",
  certificates: "Certificates",
  about_us: "About Us",
  terms_conditions: "Terms & Conditions",
  settings: "Settings",
  logout: "Logout",

  // spin & win translation
  spins: "Spins",
  You_havn_t_won_any_spin_and_win_till_now:
    "You have not won any spin and win till now!",

  // cashback and reward translation
  total_cashback_won: "Total Cashback Won",
  unotag_points: "UNOTAG Points",
  your_stickers: "Your Stickers",
  unlock_card: "Unlock Theses Card",
  cashback_won: "Cashback Won",
  date: "Date",
  points: "Points",

  // point information
  earned_points: "Earned Points",
  redeem_points: "Redeem Points",

  //game
  active: "Active",
  upcoming: "Upcoming",
  history: "History",

  //certificate
  rewards: "Rewards",
  total_score: "Total Score",

  // story
  recents: "Recent",
  seen: "Seen",

  //setting
  language: "Language",
  application_theme: "Application Theme",
  push_notification: "Push Notification",
  email_notification: "Email Notification",
  notification: "Notification",
  app_permissions: "App Permissions",
  camera: "Camera",
  location: "Location",

  //Redeem page
  select_your_method_to_tranfer_your_points:
    "Select your method to transfer your points",
  paytm_wallet: "Paytm Wallet",
  bank_transfer: "Bank Transfer",
  upi: "UPI",
  transfer_point: "Transfer Points",
  vouchers: "Vouchers",
  edit_details: "Edit Details",
  transfer_money_to_paytm_wallet: "Transfer money to Paytm Wallet",
  mobile_number: "Mobile Number",
  enter_points: "Enter Points: 100",
  proceed: "Proceed",
  transfer_money_to_bank_accout: "Transfer money to Bank Account",
  name: "Name",
  bank_name: "Bank Name",
  ac_number: "A/C Number",
  ifsc_code: "IFSC Code",
  transfer_money_through_upi: "Transfer money Through Upi",
  upi_id: "UPI id",
  enter_mobile_number: "Enter Mobile Number",
  transfer_points: "Transfer Points",

  //scan qr code from
  qr_code: "QR code",
  enter_qr_code: "Enter QR Code",

  //achivement
  achivement: "Achievements",
  progress: "Progress",
  awards: "Awards",

  //spin wheel
  spin_the_wheel: "SPIN THE WHEEL",

  //stickers screen
  UNO_TAG_stickers_are_here: "UNO TAG stickers are here!",
  get_a_sticker_every_time_you_use_unotag:
    "Get a sticker every time you use UNO TAG.",
  sticker_shelf: "Sticker Shelf",

  //survey
  survey: "Survey",
  no_questions_yet: "No questions yet!",
  submit: "Submit",

  //profile page
  edit: "Edit",
  profile: "Profile",
  total_points: "Total Points",
  rank: "Rank",
  user_level: "User Level",
  address_details: "Address Details",
  country: "Country",
  state: "State",
  city: "City",
  pincode: "Pin code",
  address: "Address",
  personal_details: "Personal Details",
  mobile_no: "Mobile No.",
  user_role: "User Role",
  email: "Email",
  gender: "Gender",
  birth_date: "Birth Date",
  anniversary_date: "Anniversary Date",
  edit_user: "Edit User",
  add_user: "Add User",
  full_name: "Full Name",
  phone_number: "Phone Number",
  mandatory_details: "Mandatory Details",
  kyc_number: "KYC Number",
  profile_photo: "Profile Photo",
  district: "District",
  payment_details: "Payment Details",
  optional: "Optional",
  bank_account_number: "Bank Account Number",
  full_name_as_per_bank: "FullName as Per Bank Account",
  bank_IFSC_code: "Bank IFSC Code",
  dealers: "Dealers",
  other_optional_details: "Other Optional Details",
  reset: "Reset",
  aadhar_card_number: "Aadhar Card Number",

  // chat
  chat: "Chat",

  //movemoent
  collected: "Collected",
  cheers: "Cheers",
  let_celebrate_movement: "Let's Celebrate Movement !",

  //games
  quiz: "Quiz",
  hangman_game: "Hangman Game",
  slot_machine_game: "Slot Machine Game",
  poll: "Poll",
  math_game: "Math Game",
  slot_machine: "Slot Machine",
  spin: "Spin",
  no_of_spin_left: "No of Spins Left",
  casino_montecarlo: "casino montecarlo",
  hangman: "Hangman",
  find_the_hidden_word: "Find the Hidden Word - Enter a letter",

  //math game
  do_you_know: "Do You Know",
  math: "Math?",
  press_to_start_the_game: "Press to Start the Game",
  start: "Start",
  get_ready: "GET READY...",
  game_over: "GAME OVER",
  final_score: "FINAL SCORE",
  retry: "RETRY",
  another_player: "Another Player",

  //quiz
  activity: "Activity",
  new_quiz: "New Quiz",
  see_all: "See All",
  running: "Running",
  completed: "Completed",
  unattemted: "Unattempted",
  skip: "Skip",
  something_wrong: "Something went wrong!",
  question: "Question",

  rewards_points: "Reward Points",
  you_can_transfer_your_money_to_any_of_following_method: "You can transfer your money to any of following method.",
  transfer_rate: "Transfer Rate",
  transfer_to_paytm_wallet: "Transfer to Paytm Wallet",
  transfer_to_any_upi: "Transfer to any UPI",
  transfer_to_bank: "Transfer to Bank",
  show_history: "Show History",
  click_here_to_scan_from_product: "Click here to scan from product.",

  get_rewards_now: "Get Reward Now!",
  view: "View all",
  price_list: "Price List",
  brochure: "Brochure",

  residential_details: "Residential Details",
  show_personal_details: "Show Personal Details",
  show_residential_details: "Show Residential Details",

  select_language: "Select Language",
  customer_care: "Customer Care",
  your_avatar: "Your Avatar",
  create_avatar: "Create Avatar",
  challenges: "Challenges",

  kyc_verify: "KYC Verify",
  kyc_verification: "KYC Verification",

  send_otp: "Send Otp",

  paytm_number: "Paytm Number",
  transfer_number: "Transfer Number",
  schemes: "Schemes",
  non_redeem_points: "Non Redeem Points",

  transcation_date: "Transaction Date",
  product_code: "Product Code",
  status: "Status",
  product_name: "Product Name",
  scheme_name: "Scheme Name",

  edit_profile: "Edit Profile",
  redeem_gift: "Redeem Gift",

  schemes_desc: "Learn About our new and ongoing schemes.",
  brochure_desc: "Learn About our new and ongoing brochure.",
  price_list_desc: "Learn About our new and ongoing Price list.",
  event_desc: "Learn About our new and ongoing events.",
  product_desc: "Learn About our new and ongoing products.",

  events: "Event",
  products: "Products",
  user_verified: "User Verified",

  ledger: "Ledger",
  gift_point: "Gift Points",
  current_year: "Current Year",
  last_year: "Last Year",
  previous_data: "Previous Data",

  current_year_points: "Current Year Points",
  last_year_points: "Last Year Points",
  previous_data_points: "Previous Data Points",

  scooter: "Scooter",
  football: "Football",
  lucky_draw: "Lucky Draw",
  hit_the_number: "Hit The Number",
  puzzle: "Puzzle",
  tapping: "Tapping",
  prediction_game: "Prediction Game",

  article_name: "Article Name",
  article_qty: "Article Qty",

};
export const translationsHindi = {
  key: "Hindi",
  current_balance: "वर्तमान शेष",
  latest_achivements: "नवीनतम उपलब्धियां",
  badges: "बैज",
  quote: "उद्धरण",
  your_opinion_matters: "आपकी राय मायने रखती है",
  whats_new: "नया क्या है?",
  contest: "प्रतियोगिता",
  movement: "गति",
  avatar: "अवतार",
  banners: "बैनर",
  schemeList: "योजना सूची",
  home: "घर",
  scan: "स्कैन",
  redeem: "मोचन करना",
  stories: "कहानियों",
  spin_win: "स्पिन और जीत",
  opportunities: "अवसरों",
  analytics: "एनालिटिक्स",
  rewards_cashback: "पुरस्कार और कैशबैक",
  points_information: "अंक जानकारी",
  games: "खेल",
  certificates: "प्रमाण पत्र",
  about_us: "हमारे बारे में",
  terms_conditions: "नियम एवं शर्तें",
  settings: "समायोजन",
  logout: "लॉग आउट",
  spins: "स्पिन",
  You_havn_t_won_any_spin_and_win_till_now:
    "आपने अब तक कोई स्पिन नहीं जीती है और जीत हासिल की है!",
  total_cashback_won: "कुल कैशबैक जीता",
  unotag_points: "यूएनओटीएजी अंक",
  your_stickers: "आपके स्टिकर",
  unlock_card: "थीसिस कार्ड अनलॉक करें",
  cashback_won: "कैशबैक जीता",
  date: "तारीख",
  points: "अंक",
  earned_points: "अर्जित अंक",
  redeem_points: "अंक भुनाएं",
  active: "सक्रिय",
  upcoming: "आगामी",
  history: "इतिहास",
  rewards: "पुरस्कार",
  total_score: "कुल स्कोर",
  recents: "हाल ही",
  seen: "देखा",
  language: "भाषा",
  application_theme: "एप्लिकेशन थीम",
  push_notification: "अधिसूचना करें",
  email_notification: "ई - मेल अधिसूचना",
  notification: "अधिसूचना",
  app_permissions: "एप्लिकेशन अनुमतियों",
  camera: "कैमरा",
  location: "स्थान",
  select_your_method_to_tranfer_your_points:
    "अपने अंक स्थानांतरित करने के लिए अपनी विधि का चयन करें",
  paytm_wallet: "पेटीएम वॉलेट",
  bank_transfer: "बैंक ट्रांसफर",
  upi: "है मैं",
  transfer_point: "स्थानांतरण अंक",
  vouchers: "वाउचर",
  edit_details: "विवरण संपादित करें",
  transfer_money_to_paytm_wallet: "पेटीएम वॉलेट में पैसे ट्रांसफर करें",
  mobile_number: "मोबाइल नंबर",
  enter_points: "अंक दर्ज करें: 100",
  proceed: "आगे बढ़ना",
  transfer_money_to_bank_accout: "बैंक खाते में पैसे ट्रांसफर करें",
  name: "नाम",
  bank_name: "बैंक का नाम",
  ac_number: "खाता संख्या",
  ifsc_code: "आईएफएससी कोड",
  transfer_money_through_upi: "Upi . के माध्यम से पैसे ट्रांसफर करें",
  upi_id: "यूपीआई आईडी",
  enter_mobile_number: "मोबाइल नंबर दर्ज करें",
  transfer_points: "स्थानांतरण अंक",
  qr_code: "क्यू आर संहिता",
  enter_qr_code: "क्यूआर कोड दर्ज करें",
  achivement: "उपलब्धियों",
  progress: "प्रगति",
  awards: "पुरस्कार",
  spin_the_wheel: "पहिया घुमाएं",
  UNO_TAG_stickers_are_here: "यूएनओ टैग स्टिकर यहां हैं!",
  get_a_sticker_every_time_you_use_unotag:
    "हर बार जब आप यूएनओ टैग का उपयोग करते हैं तो एक स्टिकर प्राप्त करें।",
  sticker_shelf: "स्टिकर शेल्फ",
  survey: "सर्वेक्षण",
  no_questions_yet: "अभी तक कोई सवाल नहीं!",
  submit: "प्रस्तुत करना",

  edit: "संपादन करना",
  profile: "प्रोफ़ाइल",
  total_points: "कुल अंक",
  rank: "पद",
  user_level: "उपयोगकर्ता स्तर",
  address_details: "पते का विवरण",
  country: "देश",
  state: "राज्य",
  city: "शहर",
  pincode: "पिन कोड",
  address: "पता",
  personal_details: "व्यक्तिगत विवरण",
  mobile_no: "मोबाइल नहीं है।",
  user_role: "उपयोगकर्ता भूमिका",
  email: "ईमेल",
  gender: "लिंग",
  birth_date: "जन्म तिथि",
  anniversary_date: "सालगिरह की तिथि",
  edit_user: "यूजर को संपादित करो",
  add_user: "उपयोगकर्ता जोड़ें",
  full_name: "पूरा नाम",
  phone_number: "फ़ोन नंबर",
  mandatory_details: "अनिवार्य विवरण",
  kyc_number: "केवाईसी नंबर",
  profile_photo: "प्रोफ़ाइल तस्वीर",
  district: "जिला",
  payment_details: "भुगतान विवरण",
  optional: "वैकल्पिक",
  bank_account_number: "बैंक खाता संख्या",
  full_name_as_per_bank: "बैंक खाते के अनुसार पूरा नाम",
  bank_IFSC_code: "बैंक IFSC कोड",
  dealers: "डीलरों",
  other_optional_details: "अन्य वैकल्पिक विवरण",
  reset: "रीसेट",
  aadhar_card_number: "आधार कार्ड नंबर",
  chat: "बात करना",
  collected: "जुटाया हुआ।",
  cheers: "प्रोत्साहित करना",
  let_celebrate_movement: "चलो आंदोलन का जश्न मनाएं!",
  quiz: "प्रश्न पूछना",
  hangman_game: "जल्लाद खेल",
  slot_machine_game: "स्लॉट मशीन गेम",
  poll: "मतदान",
  math_game: "गणित खेल",
  slot_machine: "स्लॉट मशीन",
  spin: "घुमाना",
  no_of_spin_left: "बाएं स्पिन की संख्या",
  casino_montecarlo: "कैसीनो मोंटेकार्लो",
  hangman: "बधिक",
  find_the_hidden_word: "छिपे हुए शब्द को खोजें - एक अक्षर दर्ज करें",
  do_you_know: "क्या आप जानते हैं",
  math: "गणित?",
  press_to_start_the_game: "खेल शुरू करने के लिए दबाएं",
  start: "शुरू करना",
  get_ready: "तैयार कर...",
  game_over: "खेल खत्म",
  final_score: "अंतिम स्कोर",
  retry: "पुन: प्रयास",
  another_player: "एक और खिलाड़ी",
  activity: "गतिविधि",
  new_quiz: "नई प्रश्नोत्तरी",
  see_all: "सभी देखें",
  running: "दौड़ना",
  completed: "पुरा होना।",
  unattemted: "प्रयासरहित",
  skip: "छोडना",
  something_wrong: "कुछ गलत हो गया!",
  question: "प्रश्न",
  rewards_points: "ईनामी अंक",
  you_can_transfer_your_money_to_any_of_following_method: "आप निम्न में से किसी भी तरीके से अपना पैसा ट्रांसफर कर सकते हैं।",
  transfer_rate: "अंतरण दर",
  transfer_to_paytm_wallet: "पेटीएम वॉलेट में स्थानांतरण",
  transfer_to_any_upi: "किसी भी यूपीआई में स्थानांतरण",
  transfer_to_bank: "बैंक में स्थानांतरण",
  show_history: "इतिहास दिखाएँ",
  click_here_to_scan_from_product: "उत्पाद से स्कैन करने के लिए यहां क्लिक करें।",
  get_rewards_now: "अभी पुरस्कार प्राप्त करें!",
  view: "सभी को देखें",
  price_list: "मूल्य सूची",
  brochure: "विवरणिका",
  residential_details: "आवासीय विवरण",
  show_personal_details: "व्यक्तिगत विवरण दिखाएं",
  show_residential_details: "आवासीय विवरण दिखाएं",
  select_language: "भाषा चुने",
  customer_care: "ग्राहक देखभाल",
  your_avatar: "आपका अवतार",
  create_avatar: "अवतार बनाएँ",
  challenges: "चुनौतियां",
  kyc_verify: "केवाईसी सत्यापित करें",
  kyc_verification: "केवाईसी सत्यापन",
  send_otp: "OTP भेजें",
  paytm_number: "पेटीएम नंबर",
  transfer_number: "स्थानांतरण संख्या",
  schemes: "योजनाओं",
  non_redeem_points: "गैर रिडीम अंक",
  transcation_date: "कार्यवाही की तिथि",
  product_code: "उत्पाद कोड",
  status: "दर्जा",
  product_name: "प्रोडक्ट का नाम",
  scheme_name: "योजना का नाम",
  edit_profile: "प्रोफ़ाइल संपादित करें",
  redeem_gift: "उपहार भुनाएं",
  schemes_desc: "हमारी नई और चल रही योजनाओं के बारे में जानें।",
  brochure_desc: "हमारे नए और चल रहे ब्रोशर के बारे में जानें।",
  price_list_desc: "हमारी नई और चल रही मूल्य सूची के बारे में जानें।",
  event_desc: "हमारे नए और चल रहे कार्यक्रमों के बारे में जानें।",
  product_desc: "हमारे नए और चल रहे उत्पादों के बारे में जानें।",
  events: "आयोजन",
  products: "उत्पादों",
  user_verified: "उपयोगकर्ता सत्यापित",
  ledger: "खाता बही",
  gift_point: "उपहार अंक",
  current_year: "चालू वर्ष",
  last_year: "पिछले साल",
  previous_data: "पिछला डेटा",
  current_year_points: "वर्तमान वर्ष अंक",
  last_year_points: "पिछले साल अंक",
  previous_data_points: "पिछला डेटा बिंदु",
  scooter: "स्कूटर",
  football: "फ़ुटबॉल",
  lucky_draw: "लकी ड्रा",
  hit_the_number: "नंबर मारो",
  puzzle: "पहेली",
  tapping: "दोहन",
  prediction_game: "भविष्यवाणी खेल",
  article_name: "वस्तु नाम",
  article_qty: "वस्तु मात्रा",

};
export const translationsKannada = {
  key: "Kannada",
  current_balance: "ಪ್ರಸ್ತುತ ಬ್ಯಾಲೆನ್ಸ್",
  latest_achivements: "ಇತ್ತೀಚಿನ ಸಾಧನೆಗಳು",
  badges: "ಬ್ಯಾಡ್ಜ್\u200cಗಳು",
  quote: "ಉಲ್ಲೇಖ",
  your_opinion_matters: "ನಿಮ್ಮ ಅಭಿಪ್ರಾಯ ಮುಖ್ಯ",
  whats_new: "ಹೊಸತೇನಿದೆ?",
  contest: "ಸ್ಪರ್ಧೆ",
  movement: "ಚಳುವಳಿ",
  avatar: "ಅವತಾರ",
  banners: "ಬ್ಯಾನರ್\u200cಗಳು",
  schemeList: "ಸ್ಕೀಮ್ ಪಟ್ಟಿ",
  home: "ಮನೆ",
  scan: "ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
  redeem: "ಪಡೆದುಕೊಳ್ಳಿ",
  stories: "ಕಥೆಗಳು",
  spin_win: "ಸ್ಪಿನ್ & ವಿನ್",
  opportunities: "ಅವಕಾಶಗಳು",
  analytics: "ಅನಾಲಿಟಿಕ್ಸ್",
  rewards_cashback: "ಬಹುಮಾನಗಳು ಮತ್ತು ಕ್ಯಾಶ್\u200cಬ್ಯಾಕ್\u200cಗಳು",
  points_information: "ಅಂಕಗಳ ಮಾಹಿತಿ",
  games: "ಆಟಗಳು",
  certificates: "ಪ್ರಮಾಣಪತ್ರಗಳು",
  about_us: "ನಮ್ಮ ಬಗ್ಗೆ",
  terms_conditions: "ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು",
  settings: "ಸಂಯೋಜನೆಗಳು",
  logout: "ಲಾಗ್ ಔಟ್",
  spins: "ಸ್ಪಿನ್ಸ್",
  You_havn_t_won_any_spin_and_win_till_now:
    "ನೀವು ಇಲ್ಲಿಯವರೆಗೆ ಯಾವುದೇ ಸ್ಪಿನ್ ಮತ್ತು ಗೆದ್ದಿಲ್ಲ!",
  total_cashback_won: "ಒಟ್ಟು ಕ್ಯಾಶ್\u200cಬ್ಯಾಕ್ ಗೆದ್ದಿದೆ",
  unotag_points: "UNOTAG ಪಾಯಿಂಟ್\u200cಗಳು",
  your_stickers: "ನಿಮ್ಮ ಸ್ಟಿಕ್ಕರ್\u200cಗಳು",
  unlock_card: "ಥೀಸಸ್ ಕಾರ್ಡ್ ಅನ್ನು ಅನ್ಲಾಕ್ ಮಾಡಿ",
  cashback_won: "ಕ್ಯಾಶ್\u200cಬ್ಯಾಕ್ ಗೆದ್ದಿದೆ",
  date: "ದಿನಾಂಕ",
  points: "ಅಂಕಗಳು",
  earned_points: "ಗಳಿಸಿದ ಅಂಕಗಳು",
  redeem_points: "ಪಾಯಿಂಟ್\u200cಗಳನ್ನು ರಿಡೀಮ್ ಮಾಡಿ",
  active: "ಸಕ್ರಿಯ",
  upcoming: "ಮುಂಬರುವ",
  history: "ಇತಿಹಾಸ",
  rewards: "ಪ್ರತಿಫಲಗಳು",
  total_score: "ಒಟ್ಟು ಅಂಕ",
  recents: "ಇತ್ತೀಚಿನವುಗಳು",
  seen: "ನೋಡಿದೆ",
  language: "ಭಾಷೆ",
  application_theme: "ಅಪ್ಲಿಕೇಶನ್ ಥೀಮ್",
  push_notification: "ಪುಶ್ ಅಧಿಸೂಚನೆ",
  email_notification: "ಇಮೇಲ್ ಅಧಿಸೂಚನೆ",
  notification: "ಅಧಿಸೂಚನೆ",
  app_permissions: "ಅಪ್ಲಿಕೇಶನ್ ಅನುಮತಿಗಳು",
  camera: "ಕ್ಯಾಮೆರಾ",
  location: "ಸ್ಥಳ",
  select_your_method_to_tranfer_your_points:
    "ನಿಮ್ಮ ಅಂಕಗಳನ್ನು ವರ್ಗಾಯಿಸಲು ನಿಮ್ಮ ವಿಧಾನವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  paytm_wallet: "ಪೇಟಿಎಂ ವಾಲೆಟ್",
  bank_transfer: "ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆ",
  upi: "UPI",
  transfer_point: "ವರ್ಗಾವಣೆ ಅಂಕಗಳು",
  vouchers: "ಚೀಟಿಗಳು",
  edit_details: "ವಿವರಗಳನ್ನು ಸಂಪಾದಿಸಿ",
  transfer_money_to_paytm_wallet: "Paytm Wallet ಗೆ ಹಣವನ್ನು ವರ್ಗಾಯಿಸಿ",
  mobile_number: "ಮೊಬೈಲ್ ನಂಬರ",
  enter_points: "ಅಂಕಗಳನ್ನು ನಮೂದಿಸಿ: 100",
  proceed: "ಮುಂದುವರೆಯಲು",
  transfer_money_to_bank_accout: "ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಹಣವನ್ನು ವರ್ಗಾಯಿಸಿ",
  name: "ಹೆಸರು",
  bank_name: "ಬ್ಯಾಂಕ್ ಹೆಸರು",
  ac_number: "A/C ಸಂಖ್ಯೆ",
  ifsc_code: "IFSC ಕೋಡ್",
  transfer_money_through_upi: "Upi ಮೂಲಕ ಹಣವನ್ನು ವರ್ಗಾಯಿಸಿ",
  upi_id: "UPI ಐಡಿ",
  enter_mobile_number: "ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  transfer_points: "ವರ್ಗಾವಣೆ ಅಂಕಗಳು",
  qr_code: "QR ಕೋಡ್",
  enter_qr_code: "QR ಕೋಡ್ ನಮೂದಿಸಿ",
  achivement: "ಸಾಧನೆಗಳು",
  progress: "ಪ್ರಗತಿ",
  awards: "ಪ್ರಶಸ್ತಿಗಳು",
  spin_the_wheel: "ಚಕ್ರವನ್ನು ತಿರುಗಿಸಿ",
  UNO_TAG_stickers_are_here: "UNO TAG ಸ್ಟಿಕ್ಕರ್\u200cಗಳು ಇಲ್ಲಿವೆ!",
  get_a_sticker_every_time_you_use_unotag:
    "ನೀವು ಪ್ರತಿ ಬಾರಿ UNO ಟ್ಯಾಗ್ ಅನ್ನು ಬಳಸುವಾಗ ಸ್ಟಿಕ್ಕರ್ ಅನ್ನು ಪಡೆಯಿರಿ.",
  sticker_shelf: "ಸ್ಟಿಕ್ಕರ್ ಶೆಲ್ಫ್",
  survey: "ಸರ್ವೇ",
  no_questions_yet: "ಇನ್ನೂ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳಿಲ್ಲ!",
  submit: "ಸಲ್ಲಿಸು",
  edit: "ತಿದ್ದು",
  profile: "ಪ್ರೊಫೈಲ್",
  total_points: "ಒಟ್ಟು ಅಂಕಗಳು",
  rank: "ಶ್ರೇಣಿ",
  user_level: "ಬಳಕೆದಾರರ ಮಟ್ಟ",
  address_details: "ವಿಳಾಸ ವಿವರಗಳು",
  country: "ದೇಶ",
  state: "ರಾಜ್ಯ",
  city: "ನಗರ",
  pincode: "ಪಿನ್\u200cಕೋಡ್",
  address: "ವಿಳಾಸ",
  personal_details: "ವೈಯಕ್ತಿಕ ವಿವರಗಳು",
  mobile_no: "ಮೊಬೈಲ್ ನಂ.",
  user_role: "ಬಳಕೆದಾರರ ಪಾತ್ರ",
  email: "ಇಮೇಲ್",
  gender: "ಲಿಂಗ",
  birth_date: "ಹುಟ್ಟಿದ ದಿನಾಂಕ",
  anniversary_date: "ವಾರ್ಷಿಕೋತ್ಸವದ ದಿನಾಂಕ",
  edit_user: "ಬಳಕೆದಾರರನ್ನು ಸಂಪಾದಿಸಿ",
  add_user: "ಬಳಕೆದಾರರನ್ನು ಸೇರಿಸಿ",
  full_name: "ಪೂರ್ಣ ಹೆಸರು",
  phone_number: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
  mandatory_details: "ಕಡ್ಡಾಯ ವಿವರಗಳು",
  kyc_number: "KYC ಸಂಖ್ಯೆ",
  profile_photo: "ಪ್ರೊಫೈಲ್ ಫೋಟೋ",
  district: "ಜಿಲ್ಲೆ",
  payment_details: "ಪಾವತಿ ವಿವರಗಳು",
  optional: "ಐಚ್ಛಿಕ",
  bank_account_number: "ಬ್ಯಾಂಕ್ ಖಾತೆ ಸಂಖ್ಯೆ",
  full_name_as_per_bank: "ಪ್ರತಿ ಬ್ಯಾಂಕ್ ಖಾತೆಯಂತೆ ಪೂರ್ಣ ಹೆಸರು",
  bank_IFSC_code: "ಬ್ಯಾಂಕ್ IFSC ಕೋಡ್",
  dealers: "ವಿತರಕರು",
  other_optional_details: "ಇತರ ಐಚ್ಛಿಕ ವಿವರಗಳು",
  reset: "ಮರುಹೊಂದಿಸಿ",
  aadhar_card_number: "ಆಧಾರ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆ",
  chat: "ಚಾಟ್ ಮಾಡಿ",
  collected: "ಸಂಗ್ರಹಿಸಲಾಗಿದೆ",
  cheers: "ಚೀರ್ಸ್",
  let_celebrate_movement: "ಚಳುವಳಿಯನ್ನು ಆಚರಿಸೋಣ!",
  quiz: "ರಸಪ್ರಶ್ನೆ",
  hangman_game: "ಹ್ಯಾಂಗ್\u200cಮ್ಯಾನ್ ಆಟ",
  slot_machine_game: "ಸ್ಲಾಟ್ ಯಂತ್ರ ಆಟ",
  poll: "ಮತದಾನ",
  math_game: "ಗಣಿತ ಆಟ",
  slot_machine: "ಸ್ಲಾಟ್ ಯಂತ್ರ",
  spin: "ಸ್ಪಿನ್",
  no_of_spin_left: "ಉಳಿದಿರುವ ಸ್ಪಿನ್\u200cಗಳ ಸಂಖ್ಯೆ",
  casino_montecarlo: "ಕ್ಯಾಸಿನೊ ಮಾಂಟೆಕಾರ್ಲೊ",
  hangman: "ಹ್ಯಾಂಗ್ಮನ್",
  find_the_hidden_word: "ಗುಪ್ತ ಪದವನ್ನು ಹುಡುಕಿ - ಪತ್ರವನ್ನು ನಮೂದಿಸಿ",
  do_you_know: "ನಿನಗೆ ಗೊತ್ತೆ",
  math: "ಗಣಿತ?",
  press_to_start_the_game: "ಆಟವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಒತ್ತಿರಿ",
  start: "ಪ್ರಾರಂಭಿಸಿ",
  get_ready: "ತಯಾರಾಗು...",
  game_over: "ಆಟ ಮುಗಿದಿದೆ",
  final_score: "ಅಂತಿಮ ಅಂಕ",
  retry: "ಮರುಪ್ರಯತ್ನಿಸಿ",
  another_player: "ಇನ್ನೊಬ್ಬ ಆಟಗಾರ",
  activity: "ಚಟುವಟಿಕೆ",
  new_quiz: "ಹೊಸ ರಸಪ್ರಶ್ನೆ",
  see_all: "ಎಲ್ಲವನ್ನೂ ನೋಡು",
  running: "ಓಡುತ್ತಿದೆ",
  completed: "ಪೂರ್ಣಗೊಂಡಿದೆ",
  unattemted: "ಪ್ರಯತ್ನವಿಲ್ಲದ",
  skip: "ಬಿಟ್ಟುಬಿಡಿ",
  something_wrong: "ಏನೋ ತಪ್ಪಾಗಿದೆ!",
  question: "ಪ್ರಶ್ನೆ",
  rewards_points: "ರಿವಾರ್ಡ್ ಪಾಯಿಂಟ್\u200cಗಳು",
  you_can_transfer_your_money_to_any_of_following_method: "ನಿಮ್ಮ ಹಣವನ್ನು ಈ ಕೆಳಗಿನ ಯಾವುದೇ ವಿಧಾನಕ್ಕೆ ವರ್ಗಾಯಿಸಬಹುದು.",
  transfer_rate: "ವರ್ಗಾವಣೆ ಪ್ರಮಾಣ",
  transfer_to_paytm_wallet: "Paytm ವಾಲೆಟ್\u200cಗೆ ವರ್ಗಾಯಿಸಿ",
  transfer_to_any_upi: "ಯಾವುದೇ UPI ಗೆ ವರ್ಗಾಯಿಸಿ",
  transfer_to_bank: "ಬ್ಯಾಂಕ್\u200cಗೆ ವರ್ಗಾಯಿಸಿ",
  show_history: "ಇತಿಹಾಸವನ್ನು ತೋರಿಸಿ",
  click_here_to_scan_from_product: "ಉತ್ಪನ್ನದಿಂದ ಸ್ಕ್ಯಾನ್ ಮಾಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.",
  get_rewards_now: "ಈಗ ಬಹುಮಾನ ಪಡೆಯಿರಿ!",
  view: "ಎಲ್ಲಾ ವೀಕ್ಷಿಸಿ",
  price_list: "ದರ ಪಟ್ಟಿ",
  brochure: "ಕರಪತ್ರ",
  residential_details: "ವಸತಿ ವಿವರಗಳು",
  show_personal_details: "ವೈಯಕ್ತಿಕ ವಿವರಗಳನ್ನು ತೋರಿಸಿ",
  show_residential_details: "ವಸತಿ ವಿವರಗಳನ್ನು ತೋರಿಸಿ",
  select_language: "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  customer_care: "ಕಸ್ಟಮರ್ ಕೇರ್",
  your_avatar: "ನಿಮ್ಮ ಅವತಾರ",
  create_avatar: "ಅವತಾರ್ ರಚಿಸಿ",
  challenges: "ಸವಾಲುಗಳು",
  kyc_verify: "KYC ಪರಿಶೀಲಿಸಿ",
  kyc_verification: "KYC ಪರಿಶೀಲನೆ",
  send_otp: "Otp ಕಳುಹಿಸಿ",
  paytm_number: "ಪೇಟಿಎಂ ಸಂಖ್ಯೆ",
  transfer_number: "ವರ್ಗಾವಣೆ ಸಂಖ್ಯೆ",
  schemes: "ಯೋಜನೆಗಳು",
  non_redeem_points: "ರಿಡೀಮ್ ಮಾಡದ ಪಾಯಿಂಟ್\u200cಗಳು",
  transcation_date: "ವಹಿವಾಟಿನ ದಿನಾಂಕ",
  product_code: "ಉತ್ಪನ್ನ ಕೋಡ್",
  status: "ಸ್ಥಿತಿ",
  product_name: "ಉತ್ಪನ್ನದ ಹೆಸರು",
  scheme_name: "ಯೋಜನೆಯ ಹೆಸರು",
  edit_profile: "ಪ್ರೊಫೈಲ್ ಬದಲಿಸು",
  redeem_gift: "ಉಡುಗೊರೆಯನ್ನು ಪಡೆದುಕೊಳ್ಳಿ",
  schemes_desc: "ನಮ್ಮ ಹೊಸ ಮತ್ತು ಚಾಲ್ತಿಯಲ್ಲಿರುವ ಯೋಜನೆಗಳ ಬಗ್ಗೆ ತಿಳಿಯಿರಿ.",
  brochure_desc: "ನಮ್ಮ ಹೊಸ ಮತ್ತು ನಡೆಯುತ್ತಿರುವ ಬ್ರೋಷರ್ ಬಗ್ಗೆ ತಿಳಿಯಿರಿ.",
  price_list_desc: "ನಮ್ಮ ಹೊಸ ಮತ್ತು ಚಾಲ್ತಿಯಲ್ಲಿರುವ ಬೆಲೆ ಪಟ್ಟಿಯ ಬಗ್ಗೆ ತಿಳಿಯಿರಿ.",
  event_desc: "ನಮ್ಮ ಹೊಸ ಮತ್ತು ನಡೆಯುತ್ತಿರುವ ಈವೆಂಟ್\u200cಗಳ ಬಗ್ಗೆ ತಿಳಿಯಿರಿ.",
  product_desc: "ನಮ್ಮ ಹೊಸ ಮತ್ತು ನಡೆಯುತ್ತಿರುವ ಉತ್ಪನ್ನಗಳ ಬಗ್ಗೆ ತಿಳಿಯಿರಿ.",
  events: "ಈವೆಂಟ್",
  products: "ಉತ್ಪನ್ನಗಳು",
  user_verified: "ಬಳಕೆದಾರ ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
  ledger: "ಖಾತಾ ಪುಸ್ತಕ",
  gift_point: "ಗಿಫ್ಟ್ ಪಾಯಿಂಟ್\u200cಗಳು",
  current_year: "ಪ್ರಸ್ತುತ ವರ್ಷ",
  last_year: "ಹಿಂದಿನ ವರ್ಷ",
  previous_data: "ಹಿಂದಿನ ಡೇಟಾ",
  current_year_points: "ಪ್ರಸ್ತುತ ವರ್ಷದ ಅಂಕಗಳು",
  last_year_points: "ಕಳೆದ ವರ್ಷದ ಅಂಕಗಳು",
  previous_data_points: "ಹಿಂದಿನ ಡೇಟಾ ಪಾಯಿಂಟ್\u200cಗಳು",
  scooter: "ಸ್ಕೂಟರ್",
  football: "ಫುಟ್ಬಾಲ್",
  lucky_draw: "ಲಕ್ಕಿ ಡ್ರಾ",
  hit_the_number: "ಸಂಖ್ಯೆಯನ್ನು ಒತ್ತಿರಿ",
  puzzle: "ಒಗಟು",
  tapping: "ಟ್ಯಾಪಿಂಗ್",
  prediction_game: "ಮುನ್ಸೂಚನೆ ಆಟ"
};
export const translationsTamil = {
  key: "Tamil",
  current_balance: "தற்போதைய இருப்பு",
  latest_achivements: "சமீபத்திய சாதனைகள்",
  badges: "பேட்ஜ்கள்",
  quote: "மேற்கோள்",
  your_opinion_matters: "உங்கள் கருத்து முக்கியமானது",
  whats_new: "புதியது என்ன?",
  contest: "போட்டி",
  movement: "இயக்கம்",
  avatar: "அவதாரம்",
  banners: "பதாகைகள்",
  schemeList: "திட்டப் பட்டியல்",
  home: "வீடு",
  scan: "ஊடுகதிர்",
  redeem: "மீட்டுக்கொள்ளுங்கள்",
  stories: "கதைகள்",
  spin_win: "ஸ்பின் & வின்",
  opportunities: "வாய்ப்புகள்",
  analytics: "பகுப்பாய்வு",
  rewards_cashback: "வெகுமதிகள் & கேஷ்பேக்குகள்",
  points_information: "புள்ளிகள் தகவல்",
  games: "விளையாட்டுகள்",
  certificates: "சான்றிதழ்கள்",
  about_us: "எங்களை பற்றி",
  terms_conditions: "விதிமுறைகளும் நிபந்தனைகளும்",
  settings: "அமைப்புகள்",
  logout: "வெளியேறு",
  spins: "சுழல்கிறது",
  You_havn_t_won_any_spin_and_win_till_now:
    "நீங்கள் இதுவரை எந்த சுழலிலும் வெற்றி பெறவில்லை!",
  total_cashback_won: "மொத்த கேஷ்பேக் வென்றது",
  unotag_points: "UNOTAG புள்ளிகள்",
  your_stickers: "உங்கள் ஸ்டிக்கர்கள்",
  unlock_card: "தீஸ் கார்டைத் திறக்கவும்",
  cashback_won: "கேஷ்பேக் வென்றது",
  date: "தேதி",
  points: "புள்ளிகள்",
  earned_points: "பெற்ற புள்ளிகள்",
  redeem_points: "புள்ளிகளை மீட்டெடுக்கவும்",
  active: "செயலில்",
  upcoming: "வரவிருக்கிறது",
  history: "வரலாறு",
  rewards: "வெகுமதிகள்",
  total_score: "மொத்த மதிப்பெண்",
  recents: "சமீபத்தியவை",
  seen: "பார்த்தேன்",
  language: "மொழி",
  application_theme: "விண்ணப்ப தீம்",
  push_notification: "புஷ் அறிவிப்பு",
  email_notification: "மின்னஞ்சல் அறிவிப்பு",
  notification: "அறிவிப்பு",
  app_permissions: "பயன்பாட்டு அனுமதிகள்",
  camera: "புகைப்பட கருவி",
  location: "இடம்",
  select_your_method_to_tranfer_your_points:
    "உங்கள் புள்ளிகளை மாற்றுவதற்கான முறையைத் தேர்ந்தெடுக்கவும்",
  paytm_wallet: "Paytm Wallet",
  bank_transfer: "வங்கி பரிமாற்றம்",
  upi: "UPI",
  transfer_point: "பரிமாற்ற புள்ளிகள்",
  vouchers: "வவுச்சர்கள்",
  edit_details: "விவரங்களைத் திருத்தவும்",
  transfer_money_to_paytm_wallet: "Paytm Wallet க்கு பணத்தை மாற்றவும்",
  mobile_number: "கைபேசி எண்",
  enter_points: "புள்ளிகளை உள்ளிடவும்: 100",
  proceed: "தொடரவும்",
  transfer_money_to_bank_accout: "வங்கிக் கணக்கிற்கு பணத்தை மாற்றவும்",
  name: "பெயர்",
  bank_name: "வங்கி பெயர்",
  ac_number: "ஏ/சி எண்",
  ifsc_code: "IFSC குறியீடு",
  transfer_money_through_upi: "உபி மூலம் பணத்தை மாற்றவும்",
  upi_id: "UPI ஐடி",
  enter_mobile_number: "மொபைல் எண்ணை உள்ளிடவும்",
  transfer_points: "பரிமாற்ற புள்ளிகள்",
  qr_code: "க்யு ஆர் குறியீடு",
  enter_qr_code: "QR குறியீட்டை உள்ளிடவும்",
  achivement: "சாதனைகள்",
  progress: "முன்னேற்றம்",
  awards: "விருதுகள்",
  spin_the_wheel: "சக்கரத்தை சுழற்றவும்",
  UNO_TAG_stickers_are_here: "UNO TAG ஸ்டிக்கர்கள் இங்கே உள்ளன!",
  get_a_sticker_every_time_you_use_unotag:
    "நீங்கள் UNO TAG ஐப் பயன்படுத்தும் ஒவ்வொரு முறையும் ஒரு ஸ்டிக்கரைப் பெறுங்கள்.",
  sticker_shelf: "ஸ்டிக்கர் ஷெல்ஃப்",
  survey: "சர்வே",
  no_questions_yet: "இன்னும் கேள்விகள் இல்லை!",
  submit: "சமர்ப்பிக்கவும்",
  edit: "தொகு",
  profile: "சுயவிவரம்",
  total_points: "மொத்த புள்ளிகள்",
  rank: "தரவரிசை",
  user_level: "பயனர் நிலை",
  address_details: "முகவரி விவரங்கள்",
  country: "நாடு",
  state: "நிலை",
  city: "நகரம்",
  pincode: "அஞ்சல் குறியீடு",
  address: "முகவரி",
  personal_details: "சொந்த விவரங்கள்",
  mobile_no: "அலைபேசி எண்.",
  user_role: "பயனர் பங்கு",
  email: "மின்னஞ்சல்",
  gender: "பாலினம்",
  birth_date: "பிறந்த தேதி",
  anniversary_date: "ஆண்டுவிழா தேதி",
  edit_user: "பயனரைத் திருத்து",
  add_user: "பயனரைச் சேர்க்கவும்",
  full_name: "முழு பெயர்",
  phone_number: "தொலைபேசி எண்",
  mandatory_details: "கட்டாய விவரங்கள்",
  kyc_number: "KYC எண்",
  profile_photo: "சுயவிவரப் புகைப்படம்",
  district: "மாவட்டம்",
  payment_details: "கட்டண விவரங்கள்",
  optional: "விருப்பமானது",
  bank_account_number: "வங்கி கணக்கு எண்",
  full_name_as_per_bank: "வங்கிக் கணக்கின்படி முழுப்பெயர்",
  bank_IFSC_code: "வங்கி IFSC குறியீடு",
  dealers: "டீலர்கள்",
  other_optional_details: "பிற விருப்ப விவரங்கள்",
  reset: "மீட்டமை",
  aadhar_card_number: "ஆதார் அட்டை எண்",
  chat: "அரட்டை",
  collected: "சேகரிக்கப்பட்டது",
  cheers: "சியர்ஸ்",
  let_celebrate_movement: "இயக்கத்தைக் கொண்டாடுவோம்!",
  quiz: "வினாடி வினா",
  hangman_game: "ஹேங்மேன் விளையாட்டு",
  slot_machine_game: "துளை இயந்திர விளையாட்டு",
  poll: "கருத்து கணிப்பு",
  math_game: "கணித விளையாட்டு",
  slot_machine: "துளை இயந்திரம்",
  spin: "சுழல்",
  no_of_spin_left: "இடது ஸ்பின்களின் எண்ணிக்கை",
  casino_montecarlo: "சூதாட்ட மாண்டேகார்லோ",
  hangman: "தூக்கிலிடுபவர்",
  find_the_hidden_word:
    "மறைக்கப்பட்ட வார்த்தையைக் கண்டுபிடி - ஒரு கடிதத்தை உள்ளிடவும்",
  do_you_know: "தெரியுமா",
  math: "கணிதமா?",
  press_to_start_the_game: "விளையாட்டைத் தொடங்க அழுத்தவும்",
  start: "தொடங்கு",
  get_ready: "தயாராய் இரு...",
  game_over: "ஆட்டம் முடிந்தது",
  final_score: "இறுதி மதிப்பெண்",
  retry: "மீண்டும் முயற்சிக்கவும்",
  another_player: "மற்றொரு வீரர்",
  activity: "செயல்பாடு",
  new_quiz: "புதிய வினாடிவினா",
  see_all: "அனைத்தையும் பார்",
  running: "ஓடுதல்",
  completed: "நிறைவு",
  unattemted: "முயற்சி செய்யாதது",
  skip: "தவிர்க்கவும்",
  something_wrong: "ஏதோ தவறு நடந்துவிட்டது!",
  question: "கேள்வி",
  rewards_points: "வெகுமதி புள்ளிகள்",
  you_can_transfer_your_money_to_any_of_following_method: "பின்வரும் எந்த முறையிலும் உங்கள் பணத்தை மாற்றலாம்.",
  transfer_rate: "பரிமாற்ற விகிதம்",
  transfer_to_paytm_wallet: "Paytm Wallet க்கு மாற்றவும்",
  transfer_to_any_upi: "எந்த UPI க்கும் மாற்றவும்",
  transfer_to_bank: "வங்கிக்கு மாற்றவும்",
  show_history: "வரலாற்றைக் காட்டு",
  click_here_to_scan_from_product: "தயாரிப்பிலிருந்து ஸ்கேன் செய்ய இங்கே கிளிக் செய்யவும்.",
  get_rewards_now: "இப்போதே வெகுமதியைப் பெறுங்கள்!",
  view: "அனைத்தையும் காட்டு",
  price_list: "விலைப்பட்டியல்",
  brochure: "சிற்றேடு",
  residential_details: "குடியிருப்பு விவரங்கள்",
  show_personal_details: "தனிப்பட்ட விவரங்களைக் காட்டு",
  show_residential_details: "குடியிருப்பு விவரங்களைக் காட்டு",
  select_language: "மொழியை தேர்ந்தெடுங்கள்",
  customer_care: "வாடிக்கையாளர் சேவை",
  your_avatar: "உங்கள் அவதாரம்",
  create_avatar: "அவதாரத்தை உருவாக்கவும்",
  challenges: "சவால்கள்",
  kyc_verify: "KYC சரிபார்க்கவும்",
  kyc_verification: "KYC சரிபார்ப்பு",
  send_otp: "Otp அனுப்பு",
  paytm_number: "Paytm எண்",
  transfer_number: "பரிமாற்ற எண்",
  schemes: "திட்டங்கள்",
  non_redeem_points: "ரிடீம் செய்யாத புள்ளிகள்",
  transcation_date: "பரிவர்த்தனை தேதி",
  product_code: "தயாரிப்பு குறியீடு",
  status: "நிலை",
  product_name: "பொருளின் பெயர்",
  scheme_name: "திட்டத்தின் பெயர்",
  edit_profile: "சுயவிவரத்தைத் திருத்து",
  redeem_gift: "பரிசை மீட்டுக்கொள்ளுங்கள்",
  schemes_desc: "எங்களின் புதிய மற்றும் தற்போதைய திட்டங்களைப் பற்றி அறிக.",
  brochure_desc: "எங்களின் புதிய மற்றும் தற்போதைய சிற்றேட்டைப் பற்றி அறிக.",
  price_list_desc: "எங்களின் புதிய மற்றும் தற்போதைய விலைப் பட்டியலைப் பற்றி அறிக.",
  event_desc: "எங்களின் புதிய மற்றும் நடப்பு நிகழ்வுகள் பற்றி அறிக.",
  product_desc: "எங்களின் புதிய மற்றும் தற்போதைய தயாரிப்புகள் பற்றி அறிக.",
  events: "நிகழ்வு",
  products: "தயாரிப்புகள்",
  user_verified: "பயனர் சரிபார்க்கப்பட்டது",
  ledger: "பேரேடு",
  gift_point: "பரிசு புள்ளிகள்",
  current_year: "இந்த வருடம்",
  last_year: "கடந்த ஆண்டு",
  previous_data: "முந்தைய தரவு",
  current_year_points: "நடப்பு ஆண்டு புள்ளிகள்",
  last_year_points: "கடந்த ஆண்டு புள்ளிகள்",
  previous_data_points: "முந்தைய தரவு புள்ளிகள்",
  scooter: "ஸ்கூட்டர்",
  football: "கால்பந்து",
  lucky_draw: "அதிருஷ்ட குலுக்கல்",
  hit_the_number: "எண்ணை அழுத்தவும்",
  puzzle: "புதிர்",
  tapping: "தட்டுவதன்",
  prediction_game: "கணிப்பு விளையாட்டு"
};
export const translationsMalyalam = {
  key: "Malyalam",
  current_balance: "നിലവിലെ ബാലൻസ്",
  latest_achivements: "ഏറ്റവും പുതിയ നേട്ടങ്ങൾ",
  badges: "ബാഡ്ജുകൾ",
  quote: "ഉദ്ധരണി",
  your_opinion_matters: "നിങ്ങളുടെ അഭിപ്രായം പ്രധാനമാണ്",
  whats_new: "പുതിയതെന്താണ്?",
  contest: "മത്സരം",
  movement: "പ്രസ്ഥാനം",
  avatar: "അവതാർ",
  banners: "ബാനറുകൾ",
  schemeList: "സ്കീം ലിസ്റ്റ്",
  home: "വീട്",
  scan: "സ്കാൻ ചെയ്യുക",
  redeem: "വീണ്ടെടുക്കുക",
  stories: "കഥകൾ",
  spin_win: "സ്പിൻ & വിൻ",
  opportunities: "അവസരങ്ങൾ",
  analytics: "അനലിറ്റിക്സ്",
  rewards_cashback: "റിവാർഡുകളും ക്യാഷ്ബാക്കുകളും",
  points_information: "പോയിന്റ് വിവരങ്ങൾ",
  games: "ഗെയിമുകൾ",
  certificates: "സർട്ടിഫിക്കറ്റുകൾ",
  about_us: "ഞങ്ങളേക്കുറിച്ച്",
  terms_conditions: "നിബന്ധനകളും വ്യവസ്ഥകളും",
  settings: "ക്രമീകരണങ്ങൾ",
  logout: "പുറത്തുകടക്കുക",
  spins: "കറങ്ങുന്നു",
  You_havn_t_won_any_spin_and_win_till_now:
    "നിങ്ങൾ ഇതുവരെ ഒരു സ്പിന്നിലും വിജയിച്ചിട്ടില്ല!",
  total_cashback_won: "മൊത്തം ക്യാഷ്ബാക്ക് നേടി",
  unotag_points: "UNOTAG പോയിന്റുകൾ",
  your_stickers: "നിങ്ങളുടെ സ്റ്റിക്കറുകൾ",
  unlock_card: "തീസ് കാർഡ് അൺലോക്ക് ചെയ്യുക",
  cashback_won: "ക്യാഷ്ബാക്ക് നേടി",
  date: "തീയതി",
  points: "പോയിന്റുകൾ",
  earned_points: "നേടിയ പോയിന്റുകൾ",
  redeem_points: "പോയിന്റുകൾ വീണ്ടെടുക്കുക",
  active: "സജീവമാണ്",
  upcoming: "വരാനിരിക്കുന്ന",
  history: "ചരിത്രം",
  rewards: "പ്രതിഫലം",
  total_score: "ആകെ സ്കോർ",
  recents: "സമീപകാലങ്ങൾ",
  seen: "കണ്ടു",
  language: "ഭാഷ",
  application_theme: "ആപ്ലിക്കേഷൻ തീം",
  push_notification: "പുഷ് അറിയിപ്പ്",
  email_notification: "ഇമെയിൽ അറിയിപ്പ്",
  notification: "അറിയിപ്പ്",
  app_permissions: "ആപ്പ് അനുമതികൾ",
  camera: "ക്യാമറ",
  location: "സ്ഥാനം",
  select_your_method_to_tranfer_your_points:
    "നിങ്ങളുടെ പോയിന്റുകൾ കൈമാറാൻ നിങ്ങളുടെ രീതി തിരഞ്ഞെടുക്കുക",
  paytm_wallet: "പേടിഎം വാലറ്റ്",
  bank_transfer: "ബാങ്ക് ട്രാൻസ്ഫർ",
  upi: "യുപിഐ",
  transfer_point: "ട്രാൻസ്ഫർ പോയിന്റുകൾ",
  vouchers: "വൗച്ചറുകൾ",
  edit_details: "വിശദാംശങ്ങൾ എഡിറ്റ് ചെയ്യുക",
  transfer_money_to_paytm_wallet: "പേടിഎം വാലറ്റിലേക്ക് പണം ട്രാൻസ്ഫർ ചെയ്യുക",
  mobile_number: "മൊബൈൽ നമ്പർ",
  enter_points: "പോയിന്റുകൾ നൽകുക: 100",
  proceed: "തുടരുക",
  transfer_money_to_bank_accout: "ബാങ്ക് അക്കൗണ്ടിലേക്ക് പണം ട്രാൻസ്ഫർ ചെയ്യുക",
  name: "പേര്",
  bank_name: "ബാങ്കിന്റെ പേര്",
  ac_number: "എ/സി നമ്പർ",
  ifsc_code: "IFSC കോഡ്",
  transfer_money_through_upi: "Upi വഴി പണം കൈമാറുക",
  upi_id: "UPI ഐഡി",
  enter_mobile_number: "മൊബൈൽ നമ്പർ നൽകുക",
  transfer_points: "ട്രാൻസ്ഫർ പോയിന്റുകൾ",
  qr_code: "QR കോഡ്",
  enter_qr_code: "QR കോഡ് നൽകുക",
  achivement: "നേട്ടങ്ങൾ",
  progress: "പുരോഗതി",
  awards: "അവാർഡുകൾ",
  spin_the_wheel: "ചക്രം കറക്കുക",
  UNO_TAG_stickers_are_here: "UNO TAG സ്റ്റിക്കറുകൾ ഇവിടെയുണ്ട്!",
  get_a_sticker_every_time_you_use_unotag:
    "നിങ്ങൾ UNO ടാഗ് ഉപയോഗിക്കുമ്പോഴെല്ലാം ഒരു സ്റ്റിക്കർ നേടുക.",
  sticker_shelf: "സ്റ്റിക്കർ ഷെൽഫ്",
  survey: "സർവേ",
  no_questions_yet: "ഇതുവരെ ചോദ്യങ്ങളൊന്നുമില്ല!",
  submit: "സമർപ്പിക്കുക",
  edit: "എഡിറ്റ് ചെയ്യുക",
  profile: "പ്രൊഫൈൽ",
  total_points: "ആകെ പോയിന്റുകൾ",
  rank: "റാങ്ക്",
  user_level: "ഉപയോക്തൃ നില",
  address_details: "വിലാസത്തിന്റെ വിശദാംശങ്ങൾ",
  country: "രാജ്യം",
  state: "സംസ്ഥാനം",
  city: "നഗരം",
  pincode: "പിൻ കോഡ്",
  address: "വിലാസം",
  personal_details: "വ്യക്തിഗത വിശദാംശങ്ങൾ",
  mobile_no: "മൊബൈൽ നമ്പർ.",
  user_role: "ഉപയോക്തൃ പങ്ക്",
  email: "ഇമെയിൽ",
  gender: "ലിംഗഭേദം",
  birth_date: "ജനിച്ച ദിവസം",
  anniversary_date: "വാർഷിക തീയതി",
  edit_user: "ഉപയോക്താവിനെ എഡിറ്റ് ചെയ്യുക",
  add_user: "ഉപയോക്താവിനെ ചേർക്കുക",
  full_name: "പൂർണ്ണമായ പേര്",
  phone_number: "ഫോൺ നമ്പർ",
  mandatory_details: "നിർബന്ധിത വിശദാംശങ്ങൾ",
  kyc_number: "KYC നമ്പർ",
  profile_photo: "പ്രൊഫൈൽ ഫോട്ടോ",
  district: "ജില്ല",
  payment_details: "പേയ്മെന്റ് വിശദാംശങ്ങൾ",
  optional: "ഓപ്ഷണൽ",
  bank_account_number: "ബാങ്ക് അക്കൗണ്ട് നമ്പർ",
  full_name_as_per_bank: "ബാങ്ക് അക്കൗണ്ട് പ്രകാരം മുഴുവൻ പേര്",
  bank_IFSC_code: "ബാങ്ക് IFSC കോഡ്",
  dealers: "ഡീലർമാർ",
  other_optional_details: "മറ്റ് ഓപ്ഷണൽ വിശദാംശങ്ങൾ",
  reset: "പുനഃസജ്ജമാക്കുക",
  aadhar_card_number: "ആധാർ കാർഡ് നമ്പർ",
  chat: "ചാറ്റ് ചെയ്യുക",
  collected: "ശേഖരിച്ചു",
  cheers: "ചിയേഴ്സ്",
  let_celebrate_movement: "നമുക്ക് പ്രസ്ഥാനം ആഘോഷിക്കാം!",
  quiz: "ക്വിസ്",
  hangman_game: "ഹാംഗ്മാൻ ഗെയിം",
  slot_machine_game: "സ്ലോട്ട് മെഷീൻ ഗെയിം",
  poll: "വോട്ടെടുപ്പ്",
  math_game: "ഗണിത ഗെയിം",
  slot_machine: "സ്ലോട്ട് മെഷീൻ",
  spin: "സ്പിൻ",
  no_of_spin_left: "സ്പിന്നുകളുടെ എണ്ണം അവശേഷിക്കുന്നു",
  casino_montecarlo: "കാസിനോ മോണ്ടെകാർലോ",
  hangman: "ഹാംഗ്മാൻ",
  find_the_hidden_word: "മറഞ്ഞിരിക്കുന്ന വാക്ക് കണ്ടെത്തുക - ഒരു കത്ത് നൽകുക",
  do_you_know: "നിനക്കറിയാമോ",
  math: "ഗണിതം?",
  press_to_start_the_game: "ഗെയിം ആരംഭിക്കാൻ അമർത്തുക",
  start: "ആരംഭിക്കുക",
  get_ready: "തയ്യാറാകൂ...",
  game_over: "കളി തീർന്നു",
  final_score: "ഫൈനൽ സ്കോർ",
  retry: "വീണ്ടും ശ്രമിക്കുക",
  another_player: "മറ്റൊരു കളിക്കാരൻ",
  activity: "പ്രവർത്തനം",
  new_quiz: "പുതിയ ക്വിസ്",
  see_all: "എല്ലാം കാണൂ",
  running: "പ്രവർത്തിക്കുന്ന",
  completed: "പൂർത്തിയാക്കി",
  unattemted: "ശ്രമിച്ചിട്ടില്ല",
  skip: "ഒഴിവാക്കുക",
  something_wrong: "എന്തോ കുഴപ്പം സംഭവിച്ചു!",
  question: "ചോദ്യം",
  rewards_points: "റിവാർഡ് പോയിന്റുകൾ",
  you_can_transfer_your_money_to_any_of_following_method: "ഇനിപ്പറയുന്ന ഏതെങ്കിലും രീതിയിലേക്ക് നിങ്ങളുടെ പണം ട്രാൻസ്ഫർ ചെയ്യാം.",
  transfer_rate: "കൈമാറ്റ നിരക്ക്",
  transfer_to_paytm_wallet: "പേടിഎം വാലറ്റിലേക്ക് മാറ്റുക",
  transfer_to_any_upi: "ഏതെങ്കിലും യുപിഐയിലേക്ക് കൈമാറുക",
  transfer_to_bank: "ബാങ്കിലേക്ക് മാറ്റുക",
  show_history: "ചരിത്രം കാണിക്കുക",
  click_here_to_scan_from_product: "ഉൽപ്പന്നത്തിൽ നിന്ന് സ്കാൻ ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക.",
  get_rewards_now: "ഇപ്പോൾ റിവാർഡ് നേടൂ!",
  view: "എല്ലാം കാണുക",
  price_list: "വിലവിവരപട്ടിക",
  brochure: "ലഘുപത്രിക",
  residential_details: "റെസിഡൻഷ്യൽ വിശദാംശങ്ങൾ",
  show_personal_details: "വ്യക്തിഗത വിശദാംശങ്ങൾ കാണിക്കുക",
  show_residential_details: "റെസിഡൻഷ്യൽ വിശദാംശങ്ങൾ കാണിക്കുക",
  select_language: "ഭാഷ തിരഞ്ഞെടുക്കുക",
  customer_care: "കസ്റ്റമർ കെയർ",
  your_avatar: "നിങ്ങളുടെ അവതാർ",
  create_avatar: "അവതാർ സൃഷ്ടിക്കുക",
  challenges: "വെല്ലുവിളികൾ",
  kyc_verify: "KYC പരിശോധിച്ചുറപ്പിക്കുക",
  kyc_verification: "KYC പരിശോധന",
  send_otp: "Otp അയയ്ക്കുക",
  paytm_number: "പേടിഎം നമ്പർ",
  transfer_number: "ട്രാൻസ്ഫർ നമ്പർ",
  schemes: "സ്കീമുകൾ",
  non_redeem_points: "റിഡീം ചെയ്യാത്ത പോയിന്റുകൾ",
  transcation_date: "ഇടപാട് തീയതി",
  product_code: "ഉൽപ്പന്ന കോഡ്",
  status: "പദവി",
  product_name: "ഉത്പന്നത്തിന്റെ പേര്",
  scheme_name: "സ്കീമിന്റെ പേര്",
  edit_profile: "പ്രൊഫൈൽ എഡിറ്റ് ചെയ്യുക",
  redeem_gift: "സമ്മാനം വീണ്ടെടുക്കുക",
  schemes_desc: "ഞങ്ങളുടെ പുതിയതും നിലവിലുള്ളതുമായ സ്കീമുകളെക്കുറിച്ച് അറിയുക.",
  brochure_desc: "ഞങ്ങളുടെ പുതിയതും നിലവിലുള്ളതുമായ ബ്രോഷറിനെക്കുറിച്ച് അറിയുക.",
  price_list_desc: "ഞങ്ങളുടെ പുതിയതും നിലവിലുള്ളതുമായ വില പട്ടികയെക്കുറിച്ച് അറിയുക.",
  event_desc: "ഞങ്ങളുടെ പുതിയതും നടന്നുകൊണ്ടിരിക്കുന്നതുമായ ഇവന്റുകളെ കുറിച്ച് അറിയുക.",
  product_desc: "ഞങ്ങളുടെ പുതിയതും നിലവിലുള്ളതുമായ ഉൽപ്പന്നങ്ങളെക്കുറിച്ച് അറിയുക.",
  events: "സംഭവം",
  products: "ഉൽപ്പന്നങ്ങൾ",
  user_verified: "ഉപയോക്താവ് പരിശോധിച്ചുറപ്പിച്ചു",
  ledger: "ലെഡ്ജർ",
  gift_point: "സമ്മാന പോയിന്റുകൾ",
  current_year: "ഈ വർഷം",
  last_year: "കഴിഞ്ഞ വര്ഷം",
  previous_data: "മുമ്പത്തെ ഡാറ്റ",
  current_year_points: "നിലവിലെ വർഷത്തെ പോയിന്റുകൾ",
  last_year_points: "കഴിഞ്ഞ വർഷത്തെ പോയിന്റുകൾ",
  previous_data_points: "മുമ്പത്തെ ഡാറ്റ പോയിന്റുകൾ",
  scooter: "സ്കൂട്ടർ",
  football: "ഫുട്ബോൾ",
  lucky_draw: "നറുക്കെടുപ്പ്",
  hit_the_number: "നമ്പർ അടിക്കുക",
  puzzle: "പസിൽ",
  tapping: "ടാപ്പിംഗ്",
  prediction_game: "പ്രവചന ഗെയിം"
};
export const translationsPanjabi = {
  key: "Punjabi",
  current_balance: "ਮੌਜੂਦਾ ਬਕਾਇਆ",
  latest_achivements: "ਨਵੀਨਤਮ ਪ੍ਰਾਪਤੀਆਂ",
  badges: "ਬੈਜ",
  quote: "ਹਵਾਲਾ",
  your_opinion_matters: "ਤੁਹਾਡੀ ਰਾਏ ਮਾਇਨੇ ਰੱਖਦੀ ਹੈ",
  whats_new: "ਨਵਾਂ ਕੀ ਹੈ?",
  contest: "ਮੁਕਾਬਲਾ",
  movement: "ਅੰਦੋਲਨ",
  avatar: "ਅਵਤਾਰ",
  banners: "ਬੈਨਰ",
  schemeList: "ਸਕੀਮ ਸੂਚੀ",
  home: "ਘਰ",
  scan: "ਸਕੈਨ ਕਰੋ",
  redeem: "ਰੀਡੀਮ ਕਰੋ",
  stories: "ਕਹਾਣੀਆਂ",
  spin_win: "ਸਪਿਨ ਅਤੇ ਜਿੱਤ",
  opportunities: "ਮੌਕੇ",
  analytics: "ਵਿਸ਼ਲੇਸ਼ਣ",
  rewards_cashback: "ਇਨਾਮ ਅਤੇ ਕੈਸ਼ਬੈਕ",
  points_information: "ਅੰਕ ਜਾਣਕਾਰੀ",
  games: "ਖੇਡਾਂ",
  certificates: "ਸਰਟੀਫਿਕੇਟ",
  about_us: "ਸਾਡੇ ਬਾਰੇ",
  terms_conditions: "ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ",
  settings: "ਸੈਟਿੰਗਾਂ",
  logout: "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
  spins: "ਸਪਿਨ",
  You_havn_t_won_any_spin_and_win_till_now:
    "ਤੁਸੀਂ ਹੁਣ ਤੱਕ ਕੋਈ ਸਪਿਨ ਨਹੀਂ ਜਿੱਤੇ ਅਤੇ ਜਿੱਤੇ!",
  total_cashback_won: "ਕੁੱਲ ਕੈਸ਼ਬੈਕ ਜਿੱਤਿਆ",
  unotag_points: "UNOTAG ਪੁਆਇੰਟਸ",
  your_stickers: "ਤੁਹਾਡੇ ਸਟਿੱਕਰ",
  unlock_card: "ਥੀਸਸ ਕਾਰਡ ਨੂੰ ਅਨਲੌਕ ਕਰੋ",
  cashback_won: "ਕੈਸ਼ਬੈਕ ਜਿੱਤਿਆ",
  date: "ਤਾਰੀਖ਼",
  points: "ਅੰਕ",
  earned_points: "ਕਮਾਏ ਅੰਕ",
  redeem_points: "ਪੁਆਇੰਟ ਰੀਡੀਮ ਕਰੋ",
  active: "ਕਿਰਿਆਸ਼ੀਲ",
  upcoming: "ਆਗਾਮੀ",
  history: "ਇਤਿਹਾਸ",
  rewards: "ਇਨਾਮ",
  total_score: "ਕੁੱਲ ਸਕੋਰ",
  recents: "ਹਾਲੀਆ",
  seen: "ਦੇਖਿਆ",
  language: "ਭਾਸ਼ਾ",
  application_theme: "ਐਪਲੀਕੇਸ਼ਨ ਥੀਮ",
  push_notification: "ਪੁਸ਼ ਸੂਚਨਾ",
  email_notification: "ਈਮੇਲ ਸੂਚਨਾ",
  notification: "ਸੂਚਨਾ",
  app_permissions: "ਐਪ ਅਨੁਮਤੀਆਂ",
  camera: "ਕੈਮਰਾ",
  location: "ਟਿਕਾਣਾ",
  select_your_method_to_tranfer_your_points:
    "ਆਪਣੇ ਪੁਆਇੰਟ ਟ੍ਰਾਂਸਫਰ ਕਰਨ ਲਈ ਆਪਣਾ ਤਰੀਕਾ ਚੁਣੋ",
  paytm_wallet: "ਪੇਟੀਐਮ ਵਾਲਿਟ",
  bank_transfer: "ਬਕ ਤਬਾਦਲਾ",
  upi: "UPI",
  transfer_point: "ਟ੍ਰਾਂਸਫਰ ਪੁਆਇੰਟ",
  vouchers: "ਵਾਊਚਰ",
  edit_details: "ਵੇਰਵਿਆਂ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  transfer_money_to_paytm_wallet: "Paytm ਵਾਲੇਟ ਵਿੱਚ ਪੈਸੇ ਟ੍ਰਾਂਸਫਰ ਕਰੋ",
  mobile_number: "ਮੋਬਾਈਲ ਨੰਬਰ",
  enter_points: "ਅੰਕ ਦਰਜ ਕਰੋ: 100",
  proceed: "ਅੱਗੇ ਵਧੋ",
  transfer_money_to_bank_accout: "ਬੈਂਕ ਖਾਤੇ ਵਿੱਚ ਪੈਸੇ ਟ੍ਰਾਂਸਫਰ ਕਰੋ",
  name: "ਨਾਮ",
  bank_name: "ਬੈਂਕ ਦਾ ਨਾਮ",
  ac_number: "A/C ਨੰਬਰ",
  ifsc_code: "IFSC ਕੋਡ",
  transfer_money_through_upi: "Upi ਰਾਹੀਂ ਪੈਸੇ ਟ੍ਰਾਂਸਫਰ ਕਰੋ",
  upi_id: "UPI ਆਈ.ਡੀ",
  enter_mobile_number: "ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰੋ",
  transfer_points: "ਟ੍ਰਾਂਸਫਰ ਪੁਆਇੰਟ",
  qr_code: "QR ਕੋਡ",
  enter_qr_code: "QR ਕੋਡ ਦਾਖਲ ਕਰੋ",
  achivement: "ਪ੍ਰਾਪਤੀਆਂ",
  progress: "ਤਰੱਕੀ",
  awards: "ਅਵਾਰਡ",
  spin_the_wheel: "ਪਹੀਏ ਨੂੰ ਸਪਿਨ ਕਰੋ",
  UNO_TAG_stickers_are_here: "UNO TAG ਸਟਿੱਕਰ ਇੱਥੇ ਹਨ!",
  get_a_sticker_every_time_you_use_unotag:
    "ਹਰ ਵਾਰ ਜਦੋਂ ਤੁਸੀਂ UNO TAG ਦੀ ਵਰਤੋਂ ਕਰਦੇ ਹੋ ਤਾਂ ਇੱਕ ਸਟਿੱਕਰ ਪ੍ਰਾਪਤ ਕਰੋ।",
  sticker_shelf: "ਸਟਿੱਕਰ ਸ਼ੈਲਫ",
  survey: "ਸਰਵੇਖਣ",
  no_questions_yet: "ਅਜੇ ਕੋਈ ਸਵਾਲ ਨਹੀਂ!",
  submit: "ਜਮ੍ਹਾਂ ਕਰੋ",
  edit: "ਸੰਪਾਦਿਤ ਕਰੋ",
  profile: "ਪ੍ਰੋਫਾਈਲ",
  total_points: "ਕੁੱਲ ਅੰਕ",
  rank: "ਰੈਂਕ",
  user_level: "ਉਪਭੋਗਤਾ ਪੱਧਰ",
  address_details: "ਪਤੇ ਦੇ ਵੇਰਵੇ",
  country: "ਦੇਸ਼",
  state: "ਰਾਜ",
  city: "ਸ਼ਹਿਰ",
  pincode: "ਪਿੰਨ ਕੋਡ",
  address: "ਪਤਾ",
  personal_details: "ਪਰਸਨਲ ਡਿਟੇਲ, ਨਿੱਜੀ ਜਾਣਕਾਰੀ",
  mobile_no: "ਮੋਬਾਈਲ ਨੰ.",
  user_role: "ਉਪਭੋਗਤਾ ਦੀ ਭੂਮਿਕਾ",
  email: "ਈ - ਮੇਲ",
  gender: "ਲਿੰਗ",
  birth_date: "ਜਨਮ ਮਿਤੀ",
  anniversary_date: "ਵਰ੍ਹੇਗੰਢ ਦੀ ਮਿਤੀ",
  edit_user: "ਉਪਭੋਗਤਾ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  add_user: "ਉਪਭੋਗਤਾ ਸ਼ਾਮਲ ਕਰੋ",
  full_name: "ਪੂਰਾ ਨਾਂਮ",
  phone_number: "ਫੋਨ ਨੰਬਰ",
  mandatory_details: "ਲਾਜ਼ਮੀ ਵੇਰਵੇ",
  kyc_number: "ਕੇਵਾਈਸੀ ਨੰਬਰ",
  profile_photo: "ਪ੍ਰੋਫਾਈਲ ਫੋਟੋ",
  district: "ਜ਼ਿਲ੍ਹਾ",
  payment_details: "ਭੁਗਤਾਨ ਵੇਰਵੇ",
  optional: "ਵਿਕਲਪਿਕ",
  bank_account_number: "ਖਾਤਾ ਨੰਬਰ",
  full_name_as_per_bank: "ਪ੍ਰਤੀ ਬੈਂਕ ਖਾਤੇ ਵਜੋਂ ਪੂਰਾ ਨਾਮ",
  bank_IFSC_code: "ਬੈਂਕ IFSC ਕੋਡ",
  dealers: "ਡੀਲਰ",
  other_optional_details: "ਹੋਰ ਵਿਕਲਪਿਕ ਵੇਰਵੇ",
  reset: "ਰੀਸੈਟ ਕਰੋ",
  aadhar_card_number: "ਆਧਾਰ ਕਾਰਡ ਨੰਬਰ",
  chat: "ਚੈਟ",
  collected: "ਇਕੱਠੀ ਕੀਤੀ",
  cheers: "ਚੀਅਰਸ",
  let_celebrate_movement: "ਆਉ ਅੰਦੋਲਨ ਦਾ ਜਸ਼ਨ ਮਨਾਈਏ!",
  quiz: "ਕਵਿਜ਼",
  hangman_game: "ਹੈਂਗਮੈਨ ਗੇਮ",
  slot_machine_game: "ਸਲਾਟ ਮਸ਼ੀਨ ਗੇਮ",
  poll: "ਚੋਣ",
  math_game: "ਗਣਿਤ ਦੀ ਖੇਡ",
  slot_machine: "ਸਲਾਟ ਮਸ਼ੀਨ",
  spin: "ਸਪਿਨ",
  no_of_spin_left: "ਖੱਬੇ ਸਪਿਨ ਦੀ ਸੰਖਿਆ",
  casino_montecarlo: "ਕੈਸੀਨੋ ਮੋਂਟੇਕਾਰਲੋ",
  hangman: "ਹੈਂਗਮੈਨ",
  find_the_hidden_word: "ਲੁਕਿਆ ਹੋਇਆ ਸ਼ਬਦ ਲੱਭੋ - ਇੱਕ ਅੱਖਰ ਦਾਖਲ ਕਰੋ",
  do_you_know: "ਕੀ ਤੁਸੀਂ ਜਾਣਦੇ ਹੋ",
  math: "ਗਣਿਤ?",
  press_to_start_the_game: "ਗੇਮ ਸ਼ੁਰੂ ਕਰਨ ਲਈ ਦਬਾਓ",
  start: "ਸ਼ੁਰੂ ਕਰੋ",
  get_ready: "ਤਿਆਰ ਹੋ ਜਾਉ...",
  game_over: "ਖੇਲ ਖਤਮ",
  final_score: "ਅੰਤਿਮ ਸਕੋਰ",
  retry: "ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
  another_player: "ਇੱਕ ਹੋਰ ਖਿਡਾਰੀ",
  activity: "ਸਰਗਰਮੀ",
  new_quiz: "ਨਵੀਂ ਕਵਿਜ਼",
  see_all: "ਸਭ ਦੇਖੋ",
  running: "ਚੱਲ ਰਿਹਾ ਹੈ",
  completed: "ਪੂਰਾ ਹੋਇਆ",
  unattemted: "ਅਚਨਚੇਤ",
  skip: "ਛੱਡੋ",
  something_wrong: "ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ!",
  question: "ਸਵਾਲ",
  rewards_points: "ਇਨਾਮ ਪੁਆਇੰਟ",
  you_can_transfer_your_money_to_any_of_following_method: "ਤੁਸੀਂ ਹੇਠਾਂ ਦਿੱਤੇ ਕਿਸੇ ਵੀ ਤਰੀਕੇ ਨਾਲ ਆਪਣਾ ਪੈਸਾ ਟ੍ਰਾਂਸਫਰ ਕਰ ਸਕਦੇ ਹੋ।",
  transfer_rate: "ਤਬਾਦਲਾ ਦਰ",
  transfer_to_paytm_wallet: "Paytm ਵਾਲੇਟ ਵਿੱਚ ਟ੍ਰਾਂਸਫਰ ਕਰੋ",
  transfer_to_any_upi: "ਕਿਸੇ ਵੀ UPI ਵਿੱਚ ਟ੍ਰਾਂਸਫਰ ਕਰੋ",
  transfer_to_bank: "ਬੈਂਕ ਵਿੱਚ ਟ੍ਰਾਂਸਫਰ ਕਰੋ",
  show_history: "ਇਤਿਹਾਸ ਦਿਖਾਓ",
  click_here_to_scan_from_product: "ਉਤਪਾਦ ਤੋਂ ਸਕੈਨ ਕਰਨ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ।",
  get_rewards_now: "ਹੁਣੇ ਇਨਾਮ ਪ੍ਰਾਪਤ ਕਰੋ!",
  view: "ਸਾਰੇ ਦੇਖੋ",
  price_list: "ਕੀਮਤ ਸੂਚੀ",
  brochure: "ਬਰੋਸ਼ਰ",
  residential_details: "ਰਿਹਾਇਸ਼ੀ ਵੇਰਵੇ",
  show_personal_details: "ਨਿੱਜੀ ਵੇਰਵੇ ਦਿਖਾਓ",
  show_residential_details: "ਰਿਹਾਇਸ਼ੀ ਵੇਰਵੇ ਦਿਖਾਓ",
  select_language: "ਭਾਸ਼ਾ ਚੁਣੋ",
  customer_care: "ਗ੍ਰਾਹਕ ਸੇਵਾ",
  your_avatar: "ਤੁਹਾਡਾ ਅਵਤਾਰ",
  create_avatar: "ਅਵਤਾਰ ਬਣਾਓ",
  challenges: "ਚੁਣੌਤੀਆਂ",
  kyc_verify: "KYC ਪੁਸ਼ਟੀ ਕਰੋ",
  kyc_verification: "ਕੇਵਾਈਸੀ ਪੁਸ਼ਟੀਕਰਨ",
  send_otp: "Otp ਭੇਜੋ",
  paytm_number: "ਪੇਟੀਐਮ ਨੰਬਰ",
  transfer_number: "ਟ੍ਰਾਂਸਫਰ ਨੰਬਰ",
  schemes: "ਸਕੀਮਾਂ",
  non_redeem_points: "ਗੈਰ-ਰਿਡੀਮ ਪੁਆਇੰਟ",
  transcation_date: "ਲੈਣ-ਦੇਣ ਦੀ ਮਿਤੀ",
  product_code: "ਉਤਪਾਦ ਕੋਡ",
  status: "ਸਥਿਤੀ",
  product_name: "ਉਤਪਾਦ ਦਾ ਨਾਮ",
  scheme_name: "ਸਕੀਮ ਦਾ ਨਾਮ",
  edit_profile: "ਸੋਧ ਪ੍ਰੋਫ਼ਾਈਲ",
  redeem_gift: "ਤੋਹਫ਼ਾ ਰੀਡੀਮ ਕਰੋ",
  schemes_desc: "ਸਾਡੀਆਂ ਨਵੀਆਂ ਅਤੇ ਚੱਲ ਰਹੀਆਂ ਸਕੀਮਾਂ ਬਾਰੇ ਜਾਣੋ।",
  brochure_desc: "ਸਾਡੇ ਨਵੇਂ ਅਤੇ ਚੱਲ ਰਹੇ ਬਰੋਸ਼ਰ ਬਾਰੇ ਜਾਣੋ।",
  price_list_desc: "ਸਾਡੀ ਨਵੀਂ ਅਤੇ ਚੱਲ ਰਹੀ ਕੀਮਤ ਸੂਚੀ ਬਾਰੇ ਜਾਣੋ।",
  event_desc: "ਸਾਡੇ ਨਵੇਂ ਅਤੇ ਚੱਲ ਰਹੇ ਸਮਾਗਮਾਂ ਬਾਰੇ ਜਾਣੋ।",
  product_desc: "ਸਾਡੇ ਨਵੇਂ ਅਤੇ ਚੱਲ ਰਹੇ ਉਤਪਾਦਾਂ ਬਾਰੇ ਜਾਣੋ।",
  events: "ਘਟਨਾ",
  products: "ਉਤਪਾਦ",
  user_verified: "ਉਪਭੋਗਤਾ ਪ੍ਰਮਾਣਿਤ",
  ledger: "ਬਹੀ",
  gift_point: "ਗਿਫਟ ਪੁਆਇੰਟ",
  current_year: "ਮੌਜੂਦਾ ਸਾਲ",
  last_year: "ਪਿਛਲੇ ਸਾਲ",
  previous_data: "ਪਿਛਲਾ ਡੇਟਾ",
  current_year_points: "ਮੌਜੂਦਾ ਸਾਲ ਦੇ ਅੰਕ",
  last_year_points: "ਪਿਛਲੇ ਸਾਲ ਦੇ ਅੰਕ",
  previous_data_points: "ਪਿਛਲੇ ਡੇਟਾ ਪੁਆਇੰਟਸ",
  scooter: "ਸਕੂਟਰ",
  football: "ਫੁੱਟਬਾਲ",
  lucky_draw: "ਲੱਕੀ ਡਰਾਅ",
  hit_the_number: "ਨੰਬਰ ਮਾਰੋ",
  puzzle: "ਬੁਝਾਰਤ",
  tapping: "ਟੈਪ ਕਰਨਾ",
  prediction_game: "ਭਵਿੱਖਬਾਣੀ ਦੀ ਖੇਡ"
};
export const translationsMarathi = {
  key: "Marathi",
  current_balance: "चालू शिल्लक",
  latest_achivements: "नवीनतम उपलब्धी",
  badges: "बॅज",
  quote: "कोट",
  your_opinion_matters: "तुमचे मत महत्त्वाचे आहे",
  whats_new: "नवीन काय आहे?",
  contest: "स्पर्धा",
  movement: "हालचाल",
  avatar: "अवतार",
  banners: "बॅनर",
  schemeList: "योजनेची यादी",
  home: "मुख्यपृष्ठ",
  scan: "स्कॅन करा",
  redeem: "रिडीम करा",
  stories: "कथा",
  spin_win: "फिरकी आणि विजय",
  opportunities: "संधी",
  analytics: "विश्लेषण",
  rewards_cashback: "बक्षिसे आणि कॅशबॅक",
  points_information: "गुण माहिती",
  games: "खेळ",
  certificates: "प्रमाणपत्रे",
  about_us: "आमच्याबद्दल",
  terms_conditions: "अटी व शर्ती",
  settings: "सेटिंग्ज",
  logout: "बाहेर पडणे",
  spins: "फिरते",
  You_havn_t_won_any_spin_and_win_till_now:
    "तुम्ही आतापर्यंत एकही फिरकी जिंकली नाही आणि जिंकली नाही!",
  total_cashback_won: "एकूण कॅशबॅक जिंकला",
  unotag_points: "UNOTAG पॉइंट्स",
  your_stickers: "तुमचे स्टिकर्स",
  unlock_card: "थीसेस कार्ड अनलॉक करा",
  cashback_won: "कॅशबॅक जिंकला",
  date: "तारीख",
  points: "गुण",
  earned_points: "मिळवले गुण",
  redeem_points: "गुणांची पूर्तता करा",
  active: "सक्रिय",
  upcoming: "आगामी",
  history: "इतिहास",
  rewards: "बक्षिसे",
  total_score: "एकूण स्कोअर",
  recents: "अलीकडील",
  seen: "पाहिले",
  language: "इंग्रजी",
  application_theme: "अनुप्रयोग थीम",
  push_notification: "पुश सूचना",
  email_notification: "ईमेल सूचना",
  notification: "सूचना",
  app_permissions: "अॅप परवानग्या",
  camera: "कॅमेरा",
  location: "स्थान",
  select_your_method_to_tranfer_your_points:
    "तुमचे गुण हस्तांतरित करण्यासाठी तुमची पद्धत निवडा",
  paytm_wallet: "पेटीएम वॉलेट",
  bank_transfer: "बँक हस्तांतरण",
  upi: "UPI",
  transfer_point: "ट्रान्सफर पॉइंट्स",
  vouchers: "व्हाउचर",
  edit_details: "तपशील संपादित करा",
  transfer_money_to_paytm_wallet: "पेटीएम वॉलेटमध्ये पैसे ट्रान्सफर करा",
  mobile_number: "मोबाईल नंबर",
  enter_points: "गुण प्रविष्ट करा: 100",
  proceed: "पुढे जा",
  transfer_money_to_bank_accout: "बँक खात्यात पैसे ट्रान्सफर करा",
  name: "नाव",
  bank_name: "बँकेचे नाव",
  ac_number: "A/C क्रमांक",
  ifsc_code: "IFSC कोड",
  transfer_money_through_upi: "Upi द्वारे पैसे ट्रान्सफर करा",
  upi_id: "UPI आयडी",
  enter_mobile_number: "मोबाईल नंबर टाका",
  transfer_points: "ट्रान्सफर पॉइंट्स",
  qr_code: "QR कोड",
  enter_qr_code: "QR कोड प्रविष्ट करा",
  achivement: "उपलब्धी",
  progress: "प्रगती",
  awards: "पुरस्कार",
  spin_the_wheel: "चाक फिरवा",
  UNO_TAG_stickers_are_here: "UNO TAG स्टिकर्स येथे आहेत!",
  get_a_sticker_every_time_you_use_unotag:
    "प्रत्येक वेळी तुम्ही UNO TAG वापरता तेव्हा एक स्टिकर मिळवा.",
  sticker_shelf: "स्टिकर शेल्फ",
  survey: "सर्वेक्षण",
  no_questions_yet: "अद्याप कोणतेही प्रश्न नाहीत!",
  submit: "प्रस्तुत करणे",
  edit: "सुधारणे",
  profile: "प्रोफाइल",
  total_points: "एकूण गुण",
  rank: "रँक",
  user_level: "वापरकर्ता स्तर",
  address_details: "पत्ता तपशील",
  country: "देश",
  state: "राज्य",
  city: "शहर",
  pincode: "पिन कोड",
  address: "पत्ता",
  personal_details: "वैयक्तिक माहिती",
  mobile_no: "मोबाईल क्र.",
  user_role: "वापरकर्ता भूमिका",
  email: "ईमेल",
  gender: "लिंग",
  birth_date: "जन्मदिनांक",
  anniversary_date: "वर्धापनदिन तारीख",
  edit_user: "वापरकर्ता संपादित करा",
  add_user: "वापरकर्ता जोडा",
  full_name: "पूर्ण नाव",
  phone_number: "फोन नंबर",
  mandatory_details: "अनिवार्य तपशील",
  kyc_number: "केवायसी क्रमांक",
  profile_photo: "प्रोफाइल फोटो",
  district: "जिल्हा",
  payment_details: "देयक तपशील",
  optional: "ऐच्छिक",
  bank_account_number: "बँक खाते क्रमांक",
  full_name_as_per_bank: "बँक खात्यानुसार पूर्ण नाव",
  bank_IFSC_code: "बँक IFSC कोड",
  dealers: "डीलर्स",
  other_optional_details: "इतर पर्यायी तपशील",
  reset: "रीसेट करा",
  aadhar_card_number: "आधार कार्ड क्रमांक",
  chat: "गप्पा",
  collected: "गोळा केले",
  cheers: "चिअर्स",
  let_celebrate_movement: "चळवळ साजरी करूया!",
  quiz: "प्रश्नमंजुषा",
  hangman_game: "हँगमन गेम",
  slot_machine_game: "स्लॉट मशीन गेम",
  poll: "मतदान",
  math_game: "गणिताचा खेळ",
  slot_machine: "स्लॉट मशीन",
  spin: "फिरकी",
  no_of_spin_left: "डावीकडे फिरकीची संख्या",
  casino_montecarlo: "कॅसिनो मॉन्टेकार्लो",
  hangman: "जल्लाद",
  find_the_hidden_word: "लपलेले शब्द शोधा - एक पत्र प्रविष्ट करा",
  do_you_know: "डू यू नो",
  math: "गणित?",
  press_to_start_the_game: "गेम सुरू करण्यासाठी दाबा",
  start: "सुरू करा",
  get_ready: "तयार करा...",
  game_over: "खेळ संपला",
  final_score: "अंतिम स्कोअर",
  retry: "पुन्हा प्रयत्न करा",
  another_player: "दुसरा खेळाडू",
  activity: "क्रियाकलाप",
  new_quiz: "नवीन क्विझ",
  see_all: "सर्व पाहा",
  running: "धावत आहे",
  completed: "पूर्ण झाले",
  unattemted: "प्रयत्न न केलेला",
  skip: "वगळा",
  something_wrong: "काहीतरी चूक झाली!",
  question: "प्रश्न",
  rewards_points: "बक्षीस गुण",
  you_can_transfer_your_money_to_any_of_following_method: "तुम्ही तुमचे पैसे खालीलपैकी कोणत्याही पद्धतीने ट्रान्सफर करू शकता.",
  transfer_rate: "हस्तांतरण दर",
  transfer_to_paytm_wallet: "पेटीएम वॉलेटमध्ये ट्रान्सफर करा",
  transfer_to_any_upi: "कोणत्याही UPI वर ट्रान्सफर करा",
  transfer_to_bank: "बँकेत हस्तांतरित करा",
  show_history: "इतिहास दाखवा",
  click_here_to_scan_from_product: "उत्पादनातून स्कॅन करण्यासाठी येथे क्लिक करा.",
  get_rewards_now: "आता बक्षीस मिळवा!",
  view: "सर्व पहा",
  price_list: "किंमत सूची",
  brochure: "माहितीपत्रक",
  residential_details: "निवासी तपशील",
  show_personal_details: "वैयक्तिक तपशील दर्शवा",
  show_residential_details: "निवासी तपशील दर्शवा",
  select_language: "भाषा निवडा",
  customer_care: "ग्राहक सेवा",
  your_avatar: "तुझा अवतार",
  create_avatar: "अवतार तयार करा",
  challenges: "आव्हाने",
  kyc_verify: "केवायसी सत्यापित करा",
  kyc_verification: "केवायसी पडताळणी",
  send_otp: "Otp पाठवा",
  paytm_number: "पेटीएम क्रमांक",
  transfer_number: "हस्तांतरण क्रमांक",
  schemes: "योजना",
  non_redeem_points: "नॉन रिडीम पॉइंट्स",
  transcation_date: "व्यवहाराची तारीख",
  product_code: "उत्पादन सांकेतांक",
  status: "स्थिती",
  product_name: "उत्पादनाचे नांव",
  scheme_name: "योजनेचे नाव",
  edit_profile: "प्रोफाईल संपादित करा",
  redeem_gift: "भेटवस्तू रिडीम करा",
  schemes_desc: "आमच्या नवीन आणि चालू असलेल्या योजनांबद्दल जाणून घ्या.",
  brochure_desc: "आमच्या नवीन आणि चालू माहितीपत्रकाबद्दल जाणून घ्या.",
  price_list_desc: "आमच्या नवीन आणि चालू किंमत सूचीबद्दल जाणून घ्या.",
  event_desc: "आमच्या नवीन आणि चालू इव्हेंटबद्दल जाणून घ्या.",
  product_desc: "आमच्या नवीन आणि चालू उत्पादनांबद्दल जाणून घ्या.",
  events: "कार्यक्रम",
  products: "उत्पादने",
  user_verified: "वापरकर्ता सत्यापित",
  ledger: "लेजर",
  gift_point: "भेट गुण",
  current_year: "चालू वर्ष",
  last_year: "गेल्या वर्षी",
  previous_data: "मागील डेटा",
  current_year_points: "चालू वर्षाचे गुण",
  last_year_points: "गेल्या वर्षी गुण",
  previous_data_points: "मागील डेटा पॉइंट्स",
  scooter: "स्कूटर",
  football: "फुटबॉल",
  lucky_draw: "लकी ड्रॉ",
  hit_the_number: "नंबर दाबा",
  puzzle: "कोडे",
  tapping: "टॅप करणे",
  prediction_game: "अंदाज खेळ"
};
export const translationsBengali = {
  key: "Bengali",
  current_balance: "বর্তমান হিসাব",
  latest_achivements: "সর্বশেষ অর্জন",
  badges: "ব্যাজ",
  quote: "উদ্ধৃতি",
  your_opinion_matters: "তোমার মতের মুল্য আছে",
  whats_new: "নতুন কি?",
  contest: "প্রতিযোগিতা",
  movement: "আন্দোলন",
  avatar: "অবতার",
  banners: "ব্যানার",
  schemeList: "স্কিম তালিকা",
  home: "বাড়ি",
  scan: "স্ক্যান",
  redeem: "খালাস",
  stories: "গল্পসমূহ",
  spin_win: "স্পিন এবং জয়",
  opportunities: "সুযোগ",
  analytics: "বিশ্লেষণ",
  rewards_cashback: "পুরষ্কার এবং ক্যাশব্যাক",
  points_information: "পয়েন্ট তথ্য",
  games: "গেমস",
  certificates: "সার্টিফিকেট",
  about_us: "আমাদের সম্পর্কে",
  terms_conditions: "শর্তাবলী",
  settings: "সেটিংস",
  logout: "প্রস্থান",
  spins: "স্পিন",
  You_havn_t_won_any_spin_and_win_till_now:
    "আপনি এখন পর্যন্ত কোনো স্পিন জিততে পারেননি এবং জিততে পারেননি!",
  total_cashback_won: "মোট ক্যাশব্যাক জিতেছে",
  unotag_points: "UNOTAG পয়েন্ট",
  your_stickers: "আপনার স্টিকার",
  unlock_card: "থিসিস কার্ড আনলক করুন",
  cashback_won: "ক্যাশব্যাক জিতেছে",
  date: "তারিখ",
  points: "পয়েন্ট",
  earned_points: "অর্জিত পয়েন্ট",
  redeem_points: "পয়েন্ট রিডিম করুন",
  active: "সক্রিয়",
  upcoming: "আসন্ন",
  history: "ইতিহাস",
  rewards: "পুরস্কার",
  total_score: "সম্পূর্ণ ফলাফল",
  recents: "সাম্প্রতিক",
  seen: "দেখা হয়েছে",
  language: "ভাষা",
  application_theme: "অ্যাপ্লিকেশন থিম",
  push_notification: "ধাক্কা বিজ্ঞপ্তি",
  email_notification: "ইমেল বিজ্ঞপ্তি",
  notification: "বিজ্ঞপ্তি",
  app_permissions: "অ্যাপ অনুমতি",
  camera: "ক্যামেরা",
  location: "অবস্থান",
  select_your_method_to_tranfer_your_points:
    "আপনার পয়েন্ট স্থানান্তর করার জন্য আপনার পদ্ধতি নির্বাচন করুন",
  paytm_wallet: "পেটিএম ওয়ালেট",
  bank_transfer: "ব্যাংক লেনদেন",
  upi: "ইউপিআই",
  transfer_point: "স্থানান্তর পয়েন্ট",
  vouchers: "ভাউচার",
  edit_details: "তথ্য সংশোধন কর",
  transfer_money_to_paytm_wallet: "Paytm Wallet এ টাকা ট্রান্সফার করুন",
  mobile_number: "মোবাইল নম্বর",
  enter_points: "পয়েন্ট লিখুন: 100",
  proceed: "এগিয়ে যান",
  transfer_money_to_bank_accout: "ব্যাঙ্ক অ্যাকাউন্টে টাকা স্থানান্তর করুন",
  name: "নাম",
  bank_name: "ব্যাংকের নাম",
  ac_number: "A/C নম্বর",
  ifsc_code: "IFSC কোড",
  transfer_money_through_upi: "Upi এর মাধ্যমে টাকা ট্রান্সফার করুন",
  upi_id: "UPI আইডি",
  enter_mobile_number: "মোবাইল নম্বর লিখুন",
  transfer_points: "স্থানান্তর পয়েন্ট",
  qr_code: "QR কোড",
  enter_qr_code: "QR কোড লিখুন",
  achivement: "অর্জন",
  progress: "অগ্রগতি",
  awards: "পুরস্কার",
  spin_the_wheel: "চাকাটি ঘুরাও",
  UNO_TAG_stickers_are_here: "ইউএনও ট্যাগ স্টিকার এখানে!",
  get_a_sticker_every_time_you_use_unotag:
    "প্রতিবার UNO ট্যাগ ব্যবহার করার সময় একটি স্টিকার পান।",
  sticker_shelf: "স্টিকার শেল্ফ",
  survey: "জরিপ",
  no_questions_yet: "এখনো কোন প্রশ্ন নেই!",
  submit: "জমা দিন",
  edit: "সম্পাদনা করুন",
  profile: "প্রোফাইল",
  total_points: "মোট পয়েন্ট",
  rank: "পদমর্যাদা",
  user_level: "ব্যবহারকারীর স্তর",
  address_details: "বিস্তারিত ঠিকানা",
  country: "দেশ",
  state: "রাষ্ট্র",
  city: "শহর",
  pincode: "পিনকোড",
  address: "ঠিকানা",
  personal_details: "ব্যক্তিগত বিবরণ",
  mobile_no: "মোবাইল নাম্বার.",
  user_role: "ব্যবহারকারীর ভূমিকা",
  email: "ইমেইল",
  gender: "লিঙ্গ",
  birth_date: "জন্ম তারিখ",
  anniversary_date: "বার্ষিকী তারিখ",
  edit_user: "ব্যবহারকারী সম্পাদনা করুন",
  add_user: "ব্যবহারকারী যোগ করুন",
  full_name: "পুরো নাম",
  phone_number: "ফোন নম্বর",
  mandatory_details: "বাধ্যতামূলক বিবরণ",
  kyc_number: "KYC নম্বর",
  profile_photo: "প্রোফাইল ফটো",
  district: "জেলা",
  payment_details: "পেমেন্ট বিবরণ",
  optional: "ঐচ্ছিক",
  bank_account_number: "ব্যাংক একাউন্ট নম্বর",
  full_name_as_per_bank: "ব্যাঙ্ক অ্যাকাউন্ট অনুযায়ী পুরো নাম",
  bank_IFSC_code: "ব্যাঙ্ক IFSC কোড",
  dealers: "বিক্রেতা",
  other_optional_details: "অন্যান্য ঐচ্ছিক বিবরণ",
  reset: "রিসেট",
  aadhar_card_number: "আধার কার্ড নম্বর",
  chat: "চ্যাট",
  collected: "সংগৃহীত",
  cheers: "চিয়ার্স",
  let_celebrate_movement: "আসুন আন্দোলন উদযাপন করি!",
  quiz: "কুইজ",
  hangman_game: "জল্লাদ খেলা",
  slot_machine_game: "স্লট মেশিন গেম",
  poll: "পোল",
  math_game: "গণিত খেলা",
  slot_machine: "স্লট মেশিন",
  spin: "স্পিন",
  no_of_spin_left: "বাম স্পিন সংখ্যা",
  casino_montecarlo: "ক্যাসিনো মন্টেকার্লো",
  hangman: "জল্লাদ",
  find_the_hidden_word: "লুকানো শব্দ খুঁজুন - একটি চিঠি লিখুন",
  do_you_know: "তুমি কি জানো",
  math: "গণিত?",
  press_to_start_the_game: "গেমটি শুরু করতে টিপুন",
  start: "শুরু করুন",
  get_ready: "প্রস্তুত হও...",
  game_over: "খেলা শেষ",
  final_score: "চূড়ান্ত ফল",
  retry: "পুনরায় চেষ্টা করা",
  another_player: "আরেকজন প্লেয়ার",
  activity: "কার্যকলাপ",
  new_quiz: "নতুন কুইজ",
  see_all: "সবগুলো দেখ",
  running: "চলমান",
  completed: "সম্পন্ন",
  unattemted: "অপ্রয়াসিত",
  skip: "এড়িয়ে যান",
  something_wrong: "কিছু ভুল হয়েছে!",
  question: "প্রশ্ন",
  rewards_points: "পুরস্কার পয়েন্ট",
  you_can_transfer_your_money_to_any_of_following_method: "আপনি নিম্নলিখিত যে কোনও পদ্ধতিতে আপনার অর্থ স্থানান্তর করতে পারেন।",
  transfer_rate: "স্থানান্তর হার",
  transfer_to_paytm_wallet: "Paytm Wallet এ স্থানান্তর করুন",
  transfer_to_any_upi: "যেকোনো UPI-তে ট্রান্সফার করুন",
  transfer_to_bank: "ব্যাংকে স্থানান্তর করুন",
  show_history: "ইতিহাস দেখান",
  click_here_to_scan_from_product: "পণ্য থেকে স্ক্যান করতে এখানে ক্লিক করুন.",
  get_rewards_now: "এখন পুরস্কার পান!",
  view: "সব দেখ",
  price_list: "মূল্য তালিকা",
  brochure: "ব্রোশিওর",
  residential_details: "আবাসিক বিবরণ",
  show_personal_details: "ব্যক্তিগত বিবরণ দেখান",
  show_residential_details: "আবাসিক বিবরণ দেখান",
  select_language: "ভাষা নির্বাচন কর",
  customer_care: "গ্রাহক সেবা",
  your_avatar: "তোমার অবতার",
  create_avatar: "অবতার তৈরি করুন",
  challenges: "চ্যালেঞ্জ",
  kyc_verify: "কেওয়াইসি যাচাই করুন",
  kyc_verification: "কেওয়াইসি যাচাইকরণ",
  send_otp: "Otp পাঠান",
  paytm_number: "পেটিএম নম্বর",
  transfer_number: "স্থানান্তর নম্বর",
  schemes: "স্কিম",
  non_redeem_points: "নন রিডিম পয়েন্ট",
  transcation_date: "লেনদেন তারিখ",
  product_code: "পণ্য কোড",
  status: "স্ট্যাটাস",
  product_name: "পণ্যের নাম",
  scheme_name: "স্কিমের নাম",
  edit_profile: "জীবন বৃত্তান্ত সম্পাদনা",
  redeem_gift: "উপহার রিডিম করুন",
  schemes_desc: "আমাদের নতুন এবং চলমান স্কিম সম্পর্কে জানুন।",
  brochure_desc: "আমাদের নতুন এবং চলমান ব্রোশিওর সম্পর্কে জানুন।",
  price_list_desc: "আমাদের নতুন এবং চলমান মূল্য তালিকা সম্পর্কে জানুন।",
  event_desc: "আমাদের নতুন এবং চলমান ইভেন্ট সম্পর্কে জানুন।",
  product_desc: "আমাদের নতুন এবং চলমান পণ্য সম্পর্কে জানুন।",
  events: "ঘটনা",
  products: "পণ্য",
  user_verified: "ব্যবহারকারী যাচাই করা হয়েছে",
  ledger: "খাতা",
  gift_point: "উপহার পয়েন্ট",
  current_year: "বর্তমান বছর",
  last_year: "গত বছর",
  previous_data: "পূর্ববর্তী ডেটা",
  current_year_points: "চলতি বছরের পয়েন্ট",
  last_year_points: "গত বছরের পয়েন্ট",
  previous_data_points: "পূর্ববর্তী ডেটা পয়েন্ট",
  scooter: "স্কুটার",
  football: "ফুটবল",
  lucky_draw: "লাকি ড্র",
  hit_the_number: "নম্বর হিট",
  puzzle: "ধাঁধা",
  tapping: "ট্যাপিং",
  prediction_game: "ভবিষ্যদ্বাণী খেলা"
};
export const translationsGujarati = {
  key: "Gujarati",
  current_balance: "વર્તમાન રકમ",
  latest_achivements: "નવીનતમ સિદ્ધિઓ",
  badges: "બેજ",
  quote: "ભાવ",
  your_opinion_matters: "તમારો અભિપ્રાય મહત્વપૂર્ણ છે",
  whats_new: "નવું શું છે?",
  contest: "સ્પર્ધા",
  movement: "ચળવળ",
  avatar: "અવતાર",
  banners: "બેનરો",
  schemeList: "યોજના યાદી",
  home: "ઘર",
  scan: "સ્કેન કરો",
  redeem: "રિડીમ કરો",
  stories: "વાર્તાઓ",
  spin_win: "સ્પિન અને વિન",
  opportunities: "તકો",
  analytics: "એનાલિટિક્સ",
  rewards_cashback: "પુરસ્કારો અને કેશબેક",
  points_information: "પોઈન્ટ માહિતી",
  games: "રમતો",
  certificates: "પ્રમાણપત્રો",
  about_us: "અમારા વિશે",
  terms_conditions: "શરતો અને નિયમો",
  settings: "સેટિંગ્સ",
  logout: "લૉગ આઉટ",
  spins: "સ્પિન",
  You_havn_t_won_any_spin_and_win_till_now:
    "તમે અત્યાર સુધી કોઈ સ્પિન જીત્યા નથી અને જીત્યા નથી!",
  total_cashback_won: "કુલ કેશબેક જીત્યું",
  unotag_points: "UNOTAG પોઈન્ટ્સ",
  your_stickers: "તમારા સ્ટીકરો",
  unlock_card: "થીસીસ કાર્ડ અનલોક કરો",
  cashback_won: "કેશબેક જીત્યું",
  date: "તારીખ",
  points: "પોઈન્ટ",
  earned_points: "કમાવ્યા પોઈન્ટ",
  redeem_points: "પોઈન્ટ રિડીમ કરો",
  active: "સક્રિય",
  upcoming: "આવનારી",
  history: "ઇતિહાસ",
  rewards: "પારિતોષિકો",
  total_score: "કુલ સ્કોર",
  recents: "તાજેતરના",
  seen: "જોયું",
  language: "ભાષા",
  application_theme: "એપ્લિકેશન થીમ",
  push_notification: "દબાણ સૂચના",
  email_notification: "ઈમેલ સૂચના",
  notification: "સૂચના",
  app_permissions: "એપ્લિકેશન પરવાનગીઓ",
  camera: "કેમેરા",
  location: "સ્થાન",
  select_your_method_to_tranfer_your_points:
    "તમારા પોઈન્ટ ટ્રાન્સફર કરવા માટે તમારી પદ્ધતિ પસંદ કરો",
  paytm_wallet: "પેટીએમ વોલેટ",
  bank_transfer: "બેન્ક ટ્રાન્સફર",
  upi: "UPI",
  transfer_point: "સ્થાનાંતરણ પોઈન્ટ",
  vouchers: "વાઉચર્સ",
  edit_details: "વિગતો સંપાદિત કરો",
  transfer_money_to_paytm_wallet: "Paytm વોલેટમાં પૈસા ટ્રાન્સફર કરો",
  mobile_number: "મોબાઇલ નંબર",
  enter_points: "પોઈન્ટ્સ દાખલ કરો: 100",
  proceed: "આગળ વધો",
  transfer_money_to_bank_accout: "બેંક ખાતામાં પૈસા ટ્રાન્સફર કરો",
  name: "નામ",
  bank_name: "બેંકનું નામ",
  ac_number: "A/C નંબર",
  ifsc_code: "IFSC કોડ",
  transfer_money_through_upi: "Upi દ્વારા પૈસા ટ્રાન્સફર કરો",
  upi_id: "UPI આઈડી",
  enter_mobile_number: "મોબાઈલ નંબર દાખલ કરો",
  transfer_points: "સ્થાનાંતરણ પોઈન્ટ",
  qr_code: "QR કોડ",
  enter_qr_code: "QR કોડ દાખલ કરો",
  achivement: "સિદ્ધિઓ",
  progress: "પ્રગતિ",
  awards: "પુરસ્કારો",
  spin_the_wheel: "સ્પિન ધ વ્હીલ",
  UNO_TAG_stickers_are_here: "UNO TAG સ્ટીકરો અહીં છે!",
  get_a_sticker_every_time_you_use_unotag:
    "જ્યારે પણ તમે UNO TAG નો ઉપયોગ કરો ત્યારે એક સ્ટીકર મેળવો.",
  sticker_shelf: "સ્ટીકર શેલ્ફ",
  survey: "સર્વે",
  no_questions_yet: "હજુ સુધી કોઈ પ્રશ્નો નથી!",
  submit: "સબમિટ કરો",
  edit: "સંપાદિત કરો",
  profile: "પ્રોફાઇલ",
  total_points: "કુલ પોઈન્ટ",
  rank: "ક્રમ",
  user_level: "વપરાશકર્તા સ્તર",
  address_details: "સરનામાંની વિગતો",
  country: "દેશ",
  state: "રાજ્ય",
  city: "શહેર",
  pincode: "પીન કોડ",
  address: "સરનામું",
  personal_details: "અંગત વિગતો",
  mobile_no: "મોબાઈલ નમ્બર.",
  user_role: "વપરાશકર્તા ભૂમિકા",
  email: "ઈમેલ",
  gender: "જાતિ",
  birth_date: "જન્મતારીખ",
  anniversary_date: "વર્ષગાંઠની તારીખ",
  edit_user: "વપરાશકર્તાને સંપાદિત કરો",
  add_user: "વપરાશકર્તા ઉમેરો",
  full_name: "પૂરું નામ",
  phone_number: "ફોન નંબર",
  mandatory_details: "ફરજિયાત વિગતો",
  kyc_number: "KYC નંબર",
  profile_photo: "પ્રોફાઇલ ફોટો",
  district: "જિલ્લો",
  payment_details: "ચુકવણીની વિગતો",
  optional: "વૈકલ્પિક",
  bank_account_number: "બેંક એકાઉન્ટ નંબર",
  full_name_as_per_bank: "બેંક ખાતા દીઠ સંપૂર્ણ નામ",
  bank_IFSC_code: "બેંક IFSC કોડ",
  dealers: "ડીલર્સ",
  other_optional_details: "અન્ય વૈકલ્પિક વિગતો",
  reset: "રીસેટ કરો",
  aadhar_card_number: "આધાર કાર્ડ નંબર",
  chat: "ચેટ",
  collected: "એકત્ર",
  cheers: "ચીયર્સ",
  let_celebrate_movement: "ચાલો ચળવળની ઉજવણી કરીએ!",
  quiz: "ક્વિઝ",
  hangman_game: "હેંગમેન ગેમ",
  slot_machine_game: "સ્લોટ મશીન ગેમ",
  poll: "મતદાન",
  math_game: "ગણિતની રમત",
  slot_machine: "સ્લોટ મશીન",
  spin: "સ્પિન",
  no_of_spin_left: "ડાબી સ્પિન્સની સંખ્યા",
  casino_montecarlo: "કેસિનો મોન્ટેકાર્લો",
  hangman: "જલ્લાદ",
  find_the_hidden_word: "છુપાયેલ શબ્દ શોધો - એક પત્ર દાખલ કરો",
  do_you_know: "શું તમે જાણો છો",
  math: "ગણિત?",
  press_to_start_the_game: "રમત શરૂ કરવા માટે દબાવો",
  start: "શરૂઆત",
  get_ready: "તૈયાર થાઓ...",
  game_over: "ખેલ ખતમ",
  final_score: "અંતિમ સ્કોર",
  retry: "ફરી પ્રયાસ કરો",
  another_player: "અન્ય ખેલાડી",
  activity: "પ્રવૃત્તિ",
  new_quiz: "નવી ક્વિઝ",
  see_all: "બધા જુઓ",
  running: "ચાલી રહી છે",
  completed: "પૂર્ણ થયું",
  unattemted: "પ્રયાસ વિનાનો",
  skip: "છોડો",
  something_wrong: "કંઈક ખોટું થયું!",
  question: "પ્રશ્ન",
  rewards_points: "રિવોર્ડ પોઈન્ટ",
  you_can_transfer_your_money_to_any_of_following_method: "તમે તમારા પૈસા નીચેની કોઈપણ પદ્ધતિમાં ટ્રાન્સફર કરી શકો છો.",
  transfer_rate: "ટ્રાન્સફર રેટ",
  transfer_to_paytm_wallet: "Paytm વૉલેટમાં ટ્રાન્સફર કરો",
  transfer_to_any_upi: "કોઈપણ UPI પર ટ્રાન્સફર કરો",
  transfer_to_bank: "બેંકમાં ટ્રાન્સફર કરો",
  show_history: "ઇતિહાસ બતાવો",
  click_here_to_scan_from_product: "ઉત્પાદનમાંથી સ્કેન કરવા માટે અહીં ક્લિક કરો.",
  get_rewards_now: "હમણાં પુરસ્કાર મેળવો!",
  view: "બધુજ જુઓ",
  price_list: "ભાવ યાદી",
  brochure: "બ્રોશર",
  residential_details: "રહેઠાણની વિગતો",
  show_personal_details: "વ્યક્તિગત વિગતો બતાવો",
  show_residential_details: "રહેઠાણની વિગતો બતાવો",
  select_language: "ભાષા પસંદ કરો",
  customer_care: "ગ્રાહક સંભાળ",
  your_avatar: "તમારો અવતાર",
  create_avatar: "અવતાર બનાવો",
  challenges: "પડકારો",
  kyc_verify: "KYC ચકાસો",
  kyc_verification: "કેવાયસી ચકાસણી",
  send_otp: "ઓટીપી મોકલો",
  paytm_number: "પેટીએમ નંબર",
  transfer_number: "ટ્રાન્સફર નંબર",
  schemes: "યોજનાઓ",
  non_redeem_points: "નોન રીડીમ પોઈન્ટ",
  transcation_date: "વ્યવહારની તારીખ",
  product_code: "ઉત્પાદન કોડ",
  status: "સ્થિતિ",
  product_name: "ઉત્પાદન નામ",
  scheme_name: "યોજનાનું નામ",
  edit_profile: "પ્રોફાઇલ સંપાદિત કરો",
  redeem_gift: "ભેટ રિડીમ કરો",
  schemes_desc: "અમારી નવી અને ચાલુ યોજનાઓ વિશે જાણો.",
  brochure_desc: "અમારા નવા અને ચાલુ બ્રોશર વિશે જાણો.",
  price_list_desc: "અમારી નવી અને ચાલુ કિંમતની સૂચિ વિશે જાણો.",
  event_desc: "અમારી નવી અને ચાલુ ઇવેન્ટ્સ વિશે જાણો.",
  product_desc: "અમારા નવા અને ચાલુ ઉત્પાદનો વિશે જાણો.",
  events: "ઘટના",
  products: "ઉત્પાદનો",
  user_verified: "વપરાશકર્તા ચકાસાયેલ",
  ledger: "ખાતાવહી",
  gift_point: "ભેટ પોઈન્ટ",
  current_year: "ચાલુ વર્ષ",
  last_year: "ગયું વરસ",
  previous_data: "અગાઉનો ડેટા",
  current_year_points: "વર્તમાન વર્ષના પોઈન્ટ",
  last_year_points: "ગયા વર્ષના પોઈન્ટ્સ",
  previous_data_points: "અગાઉના ડેટા પોઈન્ટ્સ",
  scooter: "સ્કૂટર",
  football: "ફૂટબોલ",
  lucky_draw: "લકી ડ્રો",
  hit_the_number: "નંબરને હિટ કરો",
  puzzle: "કોયડો",
  tapping: "ટેપીંગ",
  prediction_game: "આગાહી રમત"
};
export const translationsTelugu = {
  key: "Telugu",
  current_balance: "ప్రస్తుత నిల్వ",
  latest_achivements: "తాజా విజయాలు",
  badges: "బ్యాడ్జీలు",
  quote: "కోట్",
  your_opinion_matters: "మీ అభిప్రాయం ముఖ్యం",
  whats_new: "కొత్తవి ఏమిటి?",
  contest: "పోటీ",
  movement: "ఉద్యమం",
  avatar: "అవతార్",
  banners: "బ్యానర్లు",
  schemeList: "పథకం జాబితా",
  home: "హోమ్",
  scan: "స్కాన్ చేయండి",
  redeem: "రీడీమ్ చేయండి",
  stories: "కథలు",
  spin_win: "స్పిన్ & విన్",
  opportunities: "అవకాశాలు",
  analytics: "విశ్లేషణలు",
  rewards_cashback: "రివార్డ్\u200cలు & క్యాష్\u200cబ్యాక్\u200cలు",
  points_information: "పాయింట్ల సమాచారం",
  games: "ఆటలు",
  certificates: "సర్టిఫికెట్లు",
  about_us: "మా గురించి",
  terms_conditions: "నిబంధనలు & షరతులు",
  settings: "సెట్టింగ్\u200cలు",
  logout: "లాగ్అవుట్",
  spins: "స్పిన్స్",
  You_havn_t_won_any_spin_and_win_till_now:
    "మీరు ఇప్పటి వరకు ఎలాంటి స్పిన్ గెలవలేదు మరియు గెలవలేదు!",
  total_cashback_won: "మొత్తం క్యాష్\u200cబ్యాక్ గెలుచుకుంది",
  unotag_points: "UNOTAG పాయింట్లు",
  your_stickers: "మీ స్టిక్కర్లు",
  unlock_card: "థీసెస్ కార్డ్\u200cని అన్\u200cలాక్ చేయండి",
  cashback_won: "క్యాష్\u200cబ్యాక్ గెలిచింది",
  date: "తేదీ",
  points: "పాయింట్లు",
  earned_points: "పాయింట్లు సంపాదించారు",
  redeem_points: "పాయింట్లను రీడీమ్ చేయండి",
  active: "చురుకుగా",
  upcoming: "రాబోయేది",
  history: "చరిత్ర",
  rewards: "బహుమతులు",
  total_score: "మొత్తం స్కోరు",
  recents: "ఇటీవలివి",
  seen: "చూసింది",
  language: "భాష",
  application_theme: "అప్లికేషన్ థీమ్",
  push_notification: "పుష్ నోటిఫికేషన్",
  email_notification: "ఇమెయిల్ నోటిఫికేషన్",
  notification: "నోటిఫికేషన్",
  app_permissions: "యాప్ అనుమతులు",
  camera: "కెమెరా",
  location: "స్థానం",
  select_your_method_to_tranfer_your_points:
    "మీ పాయింట్లను బదిలీ చేయడానికి మీ పద్ధతిని ఎంచుకోండి",
  paytm_wallet: "Paytm వాలెట్",
  bank_transfer: "బ్యాంకు బదిలీ",
  upi: "UPI",
  transfer_point: "బదిలీ పాయింట్లు",
  vouchers: "వోచర్లు",
  edit_details: "వివరాలను సవరించండి",
  transfer_money_to_paytm_wallet: "Paytm వాలెట్\u200cకి డబ్బును బదిలీ చేయండి",
  mobile_number: "మొబైల్ నంబర్",
  enter_points: "పాయింట్లను నమోదు చేయండి: 100",
  proceed: "కొనసాగండి",
  transfer_money_to_bank_accout: "బ్యాంక్ ఖాతాకు డబ్బును బదిలీ చేయండి",
  name: "పేరు",
  bank_name: "బ్యాంక్ పేరు",
  ac_number: "A/C నంబర్",
  ifsc_code: "IFSC కోడ్",
  transfer_money_through_upi: "Upi ద్వారా డబ్బును బదిలీ చేయండి",
  upi_id: "UPI id",
  enter_mobile_number: "మొబైల్ నంబర్\u200cను నమోదు చేయండి",
  transfer_points: "బదిలీ పాయింట్లు",
  qr_code: "QR కోడ్",
  enter_qr_code: "QR కోడ్\u200cని నమోదు చేయండి",
  achivement: "విజయాలు",
  progress: "పురోగతి",
  awards: "అవార్డులు",
  spin_the_wheel: "చక్రాన్ని తిప్పండి",
  UNO_TAG_stickers_are_here: "UNO TAG స్టిక్కర్లు ఇక్కడ ఉన్నాయి!",
  get_a_sticker_every_time_you_use_unotag:
    "మీరు UNO TAGను ఉపయోగించిన ప్రతిసారీ స్టిక్కర్\u200cను పొందండి.",
  sticker_shelf: "స్టిక్కర్ షెల్ఫ్",
  survey: "సర్వే",
  no_questions_yet: "ఇంకా ప్రశ్నలు లేవు!",
  submit: "సమర్పించండి",
  edit: "సవరించు",
  profile: "ప్రొఫైల్",
  total_points: "మొత్తం పాయింట్లు",
  rank: "ర్యాంక్",
  user_level: "వినియోగదారు స్థాయి",
  address_details: "చిరునామా వివరాలు",
  country: "దేశం",
  state: "రాష్ట్రం",
  city: "నగరం",
  pincode: "పిన్ కోడ్",
  address: "చిరునామా",
  personal_details: "వ్యక్తిగత వివరాలు",
  mobile_no: "మొబైల్ నెం.",
  user_role: "వినియోగదారు పాత్ర",
  email: "ఇమెయిల్",
  gender: "లింగం",
  birth_date: "పుట్టిన తేదీ",
  anniversary_date: "వార్షికోత్సవ తేదీ",
  edit_user: "వినియోగదారుని సవరించండి",
  add_user: "వినియోగదారుని జోడించండి",
  full_name: "పూర్తి పేరు",
  phone_number: "ఫోను నంబరు",
  mandatory_details: "తప్పనిసరి వివరాలు",
  kyc_number: "KYC నంబర్",
  profile_photo: "ప్రొఫైల్ ఫోటో",
  district: "జిల్లా",
  payment_details: "చెల్లింపు వివరాలు",
  optional: "ఐచ్ఛికం",
  bank_account_number: "బ్యాంకు ఖాతా సంఖ్య",
  full_name_as_per_bank: "బ్యాంక్ ఖాతా ప్రకారం పూర్తి పేరు",
  bank_IFSC_code: "బ్యాంక్ IFSC కోడ్",
  dealers: "డీలర్లు",
  other_optional_details: "ఇతర ఐచ్ఛిక వివరాలు",
  reset: "రీసెట్ చేయండి",
  aadhar_card_number: "ఆధార్ కార్డ్ నంబర్",
  chat: "చాట్",
  collected: "సేకరించారు",
  cheers: "చీర్స్",
  let_celebrate_movement: "ఉద్యమాన్ని జరుపుకుందాం!",
  quiz: "క్విజ్",
  hangman_game: "ఉరితీయువాడు గేమ్",
  slot_machine_game: "స్లాట్ మెషిన్ గేమ్",
  poll: "ఎన్నికలో",
  math_game: "గణిత గేమ్",
  slot_machine: "స్లాట్ యంత్రం",
  spin: "స్పిన్",
  no_of_spin_left: "స్పిన్\u200cల సంఖ్య మిగిలి ఉంది",
  casino_montecarlo: "కాసినో మోంటెకార్లో",
  hangman: "ఉరితీయువాడు",
  find_the_hidden_word: "దాచిన పదాన్ని కనుగొనండి - అక్షరాన్ని నమోదు చేయండి",
  do_you_know: "నీకు తెలుసా",
  math: "గణితమా?",
  press_to_start_the_game: "గేమ్\u200cను ప్రారంభించడానికి నొక్కండి",
  start: "ప్రారంభించండి",
  get_ready: "సిద్దంగా ఉండండి...",
  game_over: "ఆట సమాప్తం",
  final_score: "ఫైనల్ స్కోర్",
  retry: "మళ్లీ ప్రయత్నించండి",
  another_player: "మరో ప్లేయర్",
  activity: "కార్యాచరణ",
  new_quiz: "కొత్త క్విజ్",
  see_all: "అన్నింటిని చూడు",
  running: "నడుస్తోంది",
  completed: "పూర్తయింది",
  unattemted: "ప్రయత్నించలేదు",
  skip: "దాటవేయి",
  something_wrong: "ఎక్కడో తేడ జరిగింది!",
  question: "ప్రశ్న",
  rewards_points: "కానుక పాయింట్లు",
  you_can_transfer_your_money_to_any_of_following_method: "మీరు మీ డబ్బును క్రింది పద్ధతిలో దేనికైనా బదిలీ చేయవచ్చు.",
  transfer_rate: "బదిలీ రేటు",
  transfer_to_paytm_wallet: "Paytm వాలెట్\u200cకి బదిలీ చేయండి",
  transfer_to_any_upi: "ఏదైనా UPIకి బదిలీ చేయండి",
  transfer_to_bank: "బ్యాంకుకు బదిలీ చేయండి",
  show_history: "చరిత్రను చూపించు",
  click_here_to_scan_from_product: "ఉత్పత్తి నుండి స్కాన్ చేయడానికి ఇక్కడ క్లిక్ చేయండి.",
  get_rewards_now: "ఇప్పుడు రివార్డ్ పొందండి!",
  view: "అన్నీ చూడండి",
  price_list: "కొనుగోలు ధర",
  brochure: "కరపత్రం",
  residential_details: "నివాస వివరాలు",
  show_personal_details: "వ్యక్తిగత వివరాలను చూపించు",
  show_residential_details: "నివాస వివరాలను చూపు",
  select_language: "భాషను ఎంచుకోండి",
  customer_care: "వినియోగదారుల సహాయ కేంద్రం",
  your_avatar: "మీ అవతార్",
  create_avatar: "అవతార్ సృష్టించండి",
  challenges: "సవాళ్లు",
  kyc_verify: "KYC ధృవీకరించండి",
  kyc_verification: "KYC ధృవీకరణ",
  send_otp: "Otp పంపండి",
  paytm_number: "Paytm నంబర్",
  transfer_number: "బదిలీ సంఖ్య",
  schemes: "పథకాలు",
  non_redeem_points: "నాన్ రీడీమ్ పాయింట్\u200cలు",
  transcation_date: "లావాదేవీ తేదీ",
  product_code: "ఉత్పత్తి కోడ్",
  status: "స్థితి",
  product_name: "ఉత్పత్తి నామం",
  scheme_name: "పథకం పేరు",
  edit_profile: "ప్రొఫైల్\u200cని సవరించండి",
  redeem_gift: "బహుమతిని రీడీమ్ చేయండి",
  schemes_desc: "మా కొత్త మరియు కొనసాగుతున్న పథకాల గురించి తెలుసుకోండి.",
  brochure_desc: "మా కొత్త మరియు కొనసాగుతున్న బ్రోచర్ గురించి తెలుసుకోండి.",
  price_list_desc: "మా కొత్త మరియు కొనసాగుతున్న ధరల జాబితా గురించి తెలుసుకోండి.",
  event_desc: "మా కొత్త మరియు కొనసాగుతున్న ఈవెంట్\u200cల గురించి తెలుసుకోండి.",
  product_desc: "మా కొత్త మరియు కొనసాగుతున్న ఉత్పత్తుల గురించి తెలుసుకోండి.",
  events: "ఈవెంట్",
  products: "ఉత్పత్తులు",
  user_verified: "వినియోగదారు ధృవీకరించబడింది",
  ledger: "లెడ్జర్",
  gift_point: "గిఫ్ట్ పాయింట్లు",
  current_year: "ప్రస్తుత సంవత్సరం",
  last_year: "గత సంవత్సరం",
  previous_data: "మునుపటి డేటా",
  current_year_points: "ప్రస్తుత సంవత్సరం పాయింట్లు",
  last_year_points: "గత సంవత్సరం పాయింట్లు",
  previous_data_points: "మునుపటి డేటా పాయింట్లు",
  scooter: "స్కూటర్",
  football: "ఫుట్బాల్",
  lucky_draw: "లక్కీ డ్రా",
  hit_the_number: "నంబర్\u200cని నొక్కండి",
  puzzle: "పజిల్",
  tapping: "నొక్కడం",
  prediction_game: "ప్రిడిక్షన్ గేమ్"
};
export const translationsFrench = {
  key: "French",
  current_balance: "Solde actuel",
  latest_achivements: "Dernières réalisations",
  badges: "Insignes",
  quote: "Citation",
  your_opinion_matters: "Votre avis compte",
  whats_new: "Quoi de neuf?",
  contest: "concours",
  movement: "Mouvement",
  avatar: "Avatar",
  banners: "Bannières",
  schemeList: "Liste des schémas",
  home: "Maison",
  scan: "Analyse",
  redeem: "Racheter",
  stories: "Histoires",
  spin_win: "Faites tourner et gagnez",
  opportunities: "Opportunités",
  analytics: "Analytique",
  rewards_cashback: "Récompenses et remises en argent",
  points_information: "Informations sur les points",
  games: "Jeux",
  certificates: "Certificats",
  about_us: "À propos de nous",
  terms_conditions: "termes et conditions",
  settings: "Réglages",
  logout: "Se déconnecter",
  spins: "Tours",
  You_havn_t_won_any_spin_and_win_till_now:
    "Vous n'avez gagné aucun tour et gagné jusqu'à maintenant !",
  total_cashback_won: "Remise en argent totale gagnée",
  unotag_points: "Points UNOTAG",
  your_stickers: "Vos Autocollants",
  unlock_card: "Déverrouiller la carte des thèses",
  cashback_won: "Remboursement gagné",
  date: "Date",
  points: "Points",
  earned_points: "Points gagnés",
  redeem_points: "Échanger des points",
  active: "actif",
  upcoming: "A venir",
  history: "Histoire",
  rewards: "Récompenses",
  total_score: "Score total",
  recents: "Récents",
  seen: "Vu",
  language: "Langue",
  application_theme: "Thème d'application",
  push_notification: "Notification push",
  email_notification: "Notification par e-mail",
  notification: "Notification",
  app_permissions: "Autorisations d'application",
  camera: "Appareil photo",
  location: "Lieu",
  select_your_method_to_tranfer_your_points:
    "Sélectionnez votre méthode pour transférer vos points",
  paytm_wallet: "Portefeuille PayTM",
  bank_transfer: "Virement",
  upi: "UPI",
  transfer_point: "Points de transfert",
  vouchers: "Pièces justificatives",
  edit_details: "Modifier les détails",
  transfer_money_to_paytm_wallet: "Transférer de l'argent vers Paytm Wallet",
  mobile_number: "Numéro de portable",
  enter_points: "Entrez des points : 100",
  proceed: "Procéder",
  transfer_money_to_bank_accout:
    "Transférer de l'argent sur un compte bancaire",
  name: "Nom",
  bank_name: "Nom de banque",
  ac_number: "Numéro de climatisation",
  ifsc_code: "Code IFSC",
  transfer_money_through_upi: "Transférer de l'argent via Upi",
  upi_id: "identifiant UPI",
  enter_mobile_number: "Entrez le numéro de portable",
  transfer_points: "Points de transfert",
  qr_code: "QR Code",
  enter_qr_code: "Entrez le code QR",
  achivement: "Réalisations",
  progress: "Le progrès",
  awards: "Récompenses",
  spin_the_wheel: "TOURNE LA ROUE",
  UNO_TAG_stickers_are_here: "Les autocollants UNO TAG sont là !",
  get_a_sticker_every_time_you_use_unotag:
    "Obtenez un autocollant chaque fois que vous utilisez UNO TAG.",
  sticker_shelf: "Autocollant Étagère",
  survey: "Enquête",
  no_questions_yet: "Pas encore de questions !",
  submit: "Nous faire parvenir",
  edit: "Modifier",
  profile: "Profil",
  total_points: "Points totaux",
  rank: "Rang",
  user_level: "Niveau de l'utilisateur",
  address_details: "Détails de l'adresse",
  country: "Pays",
  state: "État",
  city: "Ville",
  pincode: "Code PIN",
  address: "Adresse",
  personal_details: "Détails personnels",
  mobile_no: "N° de portable",
  user_role: "Rôle d'utilisateur",
  email: "E-mail",
  gender: "Le sexe",
  birth_date: "Date de naissance",
  anniversary_date: "Date d'anniversaire",
  edit_user: "Modifier l'utilisateur",
  add_user: "Ajouter un utilisateur",
  full_name: "Nom complet",
  phone_number: "Numéro de téléphone",
  mandatory_details: "Détails obligatoires",
  kyc_number: "Numéro KYC",
  profile_photo: "Photo de profil",
  district: "District",
  payment_details: "Détails de paiement",
  optional: "Optionnel",
  bank_account_number: "Numéro de compte bancaire",
  full_name_as_per_bank: "Nom complet selon le compte bancaire",
  bank_IFSC_code: "Code IFSC de la banque",
  dealers: "Concessionnaires",
  other_optional_details: "Autres détails facultatifs",
  reset: "Réinitialiser",
  aadhar_card_number: "Numéro de carte Aadhar",
  chat: "Discuter",
  collected: "Collecté",
  cheers: "Acclamations",
  let_celebrate_movement: "Célébrons le mouvement !",
  quiz: "Questionnaire",
  hangman_game: "Jeu du Pendu",
  slot_machine_game: "Jeu de machines à sous",
  poll: "Sondage",
  math_game: "Jeu de mathématiques",
  slot_machine: "Machine à sous",
  spin: "Tournoyer",
  no_of_spin_left: "Nombre de tours restants",
  casino_montecarlo: "casino de montecarlo",
  hangman: "Bourreau",
  find_the_hidden_word: "Trouvez le mot caché - Entrez une lettre",
  do_you_know: "Sais-tu",
  math: "Math?",
  press_to_start_the_game: "Appuyez pour démarrer le jeu",
  start: "Démarrer",
  get_ready: "SOIS PRÊT...",
  game_over: "JEU TERMINÉ",
  final_score: "SCORE FINAL",
  retry: "RETENTER",
  another_player: "Un autre joueur",
  activity: "Activité",
  new_quiz: "Nouveau questionnaire",
  see_all: "Voir tout",
  running: "Fonctionnement",
  completed: "Terminé",
  unattemted: "Sans tentative",
  skip: "Sauter",
  something_wrong: "Quelque chose s'est mal passé !",
  question: "Question",
  rewards_points: "Points de récompense",
  you_can_transfer_your_money_to_any_of_following_method: "Vous pouvez transférer votre argent vers l'une des méthodes suivantes.",
  transfer_rate: "Taux de transfert",
  transfer_to_paytm_wallet: "Transfert vers le portefeuille Paytm",
  transfer_to_any_upi: "Transfert vers n'importe quelle UPI",
  transfer_to_bank: "Transfert à la banque",
  show_history: "Afficher l'historique",
  click_here_to_scan_from_product: "Cliquez ici pour numériser à partir du produit.",
  get_rewards_now: "Obtenez une récompense maintenant !",
  view: "Voir tout",
  price_list: "Liste de prix",
  brochure: "Brochure",
  residential_details: "Détails résidentiels",
  show_personal_details: "Afficher les détails personnels",
  show_residential_details: "Afficher les détails résidentiels",
  select_language: "Choisir la langue",
  customer_care: "Service client",
  your_avatar: "Votre avatar",
  create_avatar: "Créer un avatar",
  challenges: "Défis",
  kyc_verify: "Vérification KYC",
  kyc_verification: "Vérification KYC",
  send_otp: "Envoyer OTP",
  paytm_number: "Numéro de paiement",
  transfer_number: "Numéro de transfert",
  schemes: "Régimes",
  non_redeem_points: "Points non échangeables",
  transcation_date: "Date de la transaction",
  product_code: "Code produit",
  status: "Statut",
  product_name: "Nom du produit",
  scheme_name: "Nom du schéma",
  edit_profile: "Editer le profil",
  redeem_gift: "Échanger un cadeau",
  schemes_desc: "En savoir plus sur nos programmes nouveaux et en cours.",
  brochure_desc: "Découvrez notre nouvelle brochure en cours.",
  price_list_desc: "En savoir plus sur notre nouvelle liste de prix en cours.",
  event_desc: "En savoir plus sur nos événements nouveaux et en cours.",
  product_desc: "En savoir plus sur nos produits nouveaux et en cours.",
  events: "Événement",
  products: "Des produits",
  user_verified: "Vérifié par l'utilisateur",
  ledger: "registre",
  gift_point: "Points-cadeaux",
  current_year: "Année actuelle",
  last_year: "L'année dernière",
  previous_data: "Données précédentes",
  current_year_points: "Points de l'année en cours",
  last_year_points: "Points de l'année dernière",
  previous_data_points: "Points de données précédents",
  scooter: "Scooter",
  football: "Football",
  lucky_draw: "Tirage au sort",
  hit_the_number: "Frappez le numéro",
  puzzle: "Puzzle",
  tapping: "Tapotement",
  prediction_game: "Jeu de prédiction"
};
export const translationsSpanish = {
  key: "Spanish",
  current_balance: "Saldo actual",
  latest_achivements: "Últimos logros",
  badges: "Insignias",
  quote: "Cita",
  your_opinion_matters: "Tu opinion importa",
  whats_new: "¿Qué hay de nuevo?",
  contest: "el concurso",
  movement: "Movimiento",
  avatar: "Avatar",
  banners: "pancartas",
  schemeList: "Lista de esquemas",
  home: "Casa",
  scan: "Escanear",
  redeem: "Redimir",
  stories: "Cuentos",
  spin_win: "Gira y gana",
  opportunities: "Oportunidades",
  analytics: "Analítica",
  rewards_cashback: "Recompensas y reembolsos",
  points_information: "Información de puntos",
  games: "Juegos",
  certificates: "Certificados",
  about_us: "Sobre nosotros",
  terms_conditions: "Términos y condiciones",
  settings: "Ajustes",
  logout: "Cerrar sesión",
  spins: "Giros",
  You_havn_t_won_any_spin_and_win_till_now:
    "¡No has ganado ningún giro y victoria hasta ahora!",
  total_cashback_won: "Reembolso total ganado",
  unotag_points: "Puntos UNOTAG",
  your_stickers: "Tus pegatinas",
  unlock_card: "Desbloquear tarjeta de tesis",
  cashback_won: "Reembolso ganado",
  date: "Fecha",
  points: "Puntos",
  earned_points: "Puntos Ganados",
  redeem_points: "Canjear puntos",
  active: "Activo",
  upcoming: "próximos",
  history: "Historia",
  rewards: "Recompensas",
  total_score: "Puntaje total",
  recents: "Recientes",
  seen: "Visto",
  language: "Idioma",
  application_theme: "Tema de la aplicación",
  push_notification: "Notificación de inserción",
  email_notification: "Notificación de correo electrónico",
  notification: "Notificación",
  app_permissions: "Permisos de la aplicación",
  camera: "Cámara",
  location: "Ubicación",
  select_your_method_to_tranfer_your_points:
    "Seleccione su método para transferir sus puntos",
  paytm_wallet: "Monedero Paytm",
  bank_transfer: "Transferencia bancaria",
  upi: "UPI",
  transfer_point: "Puntos de transferencia",
  vouchers: "vales",
  edit_details: "Editar detalles",
  transfer_money_to_paytm_wallet: "Transferir dinero a Paytm Wallet",
  mobile_number: "Número de teléfono móvil",
  enter_points: "Introducir puntos: 100",
  proceed: "Continuar",
  transfer_money_to_bank_accout: "Transferir dinero a cuenta bancaria",
  name: "Nombre",
  bank_name: "Nombre del banco",
  ac_number: "Número de aire acondicionado",
  ifsc_code: "Código IFSC",
  transfer_money_through_upi: "Transferir dinero a través de Upi",
  upi_id: "identificación UPI",
  enter_mobile_number: "Ingrese el número de móvil",
  transfer_points: "Puntos de transferencia",
  qr_code: "Código QR",
  enter_qr_code: "Ingrese el código QR",
  achivement: "logros",
  progress: "Progreso",
  awards: "Premios",
  spin_the_wheel: "GIRA LA RUEDA",
  UNO_TAG_stickers_are_here: "¡Llegaron las pegatinas UNO TAG!",
  get_a_sticker_every_time_you_use_unotag:
    "Consigue una pegatina cada vez que utilices UNO TAG.",
  sticker_shelf: "Estante de pegatinas",
  survey: "Encuesta",
  no_questions_yet: "¡Aún no hay preguntas!",
  submit: "Enviar",
  edit: "Editar",
  profile: "Perfil",
  total_points: "Puntos totales",
  rank: "Rango",
  user_level: "Nivel de usuario",
  address_details: "detalles de dirección",
  country: "País",
  state: "Expresar",
  city: "Ciudad",
  pincode: "Código PIN",
  address: "Dirección",
  personal_details: "Detalles personales",
  mobile_no: "No móviles.",
  user_role: "Rol del usuario",
  email: "Correo electrónico",
  gender: "Género",
  birth_date: "Fecha de nacimiento",
  anniversary_date: "Fecha de aniversario",
  edit_user: "editar usuario",
  add_user: "Agregar usuario",
  full_name: "Nombre completo",
  phone_number: "Número de teléfono",
  mandatory_details: "Detalles Obligatorios",
  kyc_number: "Número KYC",
  profile_photo: "Foto de perfil",
  district: "Distrito",
  payment_details: "Detalles del pago",
  optional: "Opcional",
  bank_account_number: "Número de cuenta bancaria",
  full_name_as_per_bank: "Nombre completo según cuenta bancaria",
  bank_IFSC_code: "Código IFSC del banco",
  dealers: "distribuidores",
  other_optional_details: "Otros detalles opcionales",
  reset: "Reiniciar",
  aadhar_card_number: "Número de tarjeta Aadhar",
  chat: "Chat",
  collected: "Recogido",
  cheers: "Salud",
  let_celebrate_movement: "¡Celebremos el movimiento!",
  quiz: "Examen",
  hangman_game: "Juego del ahorcado",
  slot_machine_game: "Juego de máquinas tragamonedas",
  poll: "Encuesta",
  math_game: "juego de matematicas",
  slot_machine: "Maquina de casino",
  spin: "Girar",
  no_of_spin_left: "Número de giros restantes",
  casino_montecarlo: "casino montecarlo",
  hangman: "Verdugo",
  find_the_hidden_word: "Encuentra la palabra oculta - Introduce una letra",
  do_you_know: "Lo sabías",
  math: "¿Matemáticas?",
  press_to_start_the_game: "Presione para iniciar el juego",
  start: "Comienzo",
  get_ready: "PREPARARSE...",
  game_over: "JUEGO TERMINADO",
  final_score: "PUNTUACIÓN FINAL",
  retry: "REVER",
  another_player: "otro jugador",
  activity: "Actividad",
  new_quiz: "Nuevo cuestionario",
  see_all: "Ver todo",
  running: "Corriendo",
  completed: "Terminado",
  unattemted: "no intentado",
  skip: "Saltear",
  something_wrong: "¡Algo salió mal!",
  question: "Pregunta",
  rewards_points: "Puntos de recompensa",
  you_can_transfer_your_money_to_any_of_following_method: "Puede transferir su dinero a cualquiera de los siguientes métodos.",
  transfer_rate: "Ratio de transferencia",
  transfer_to_paytm_wallet: "Transferir a Paytm Wallet",
  transfer_to_any_upi: "Transferir a cualquier UPI",
  transfer_to_bank: "Transferencia al banco",
  show_history: "Mostrar historial",
  click_here_to_scan_from_product: "Haga clic aquí para escanear desde el producto.",
  get_rewards_now: "¡Obtenga recompensa ahora!",
  view: "Ver todo",
  price_list: "Lista de precios",
  brochure: "Folleto",
  residential_details: "Detalles Residenciales",
  show_personal_details: "Mostrar datos personales",
  show_residential_details: "Mostrar detalles residenciales",
  select_language: "Seleccione el idioma",
  customer_care: "Atención al cliente",
  your_avatar: "Tu avatar",
  create_avatar: "Crear avatar",
  challenges: "Desafíos",
  kyc_verify: "Verificar KYC",
  kyc_verification: "Verificación KYC",
  send_otp: "Enviar OTP",
  paytm_number: "Número de pago",
  transfer_number: "Número de transferencia",
  schemes: "esquemas",
  non_redeem_points: "Puntos no canjeables",
  transcation_date: "Fecha de Transacción",
  product_code: "Código de producto",
  status: "Estado",
  product_name: "nombre del producto",
  scheme_name: "Nombre del esquema",
  edit_profile: "Editar perfil",
  redeem_gift: "Canjear regalo",
  schemes_desc: "Obtenga información sobre nuestros esquemas nuevos y en curso.",
  brochure_desc: "Obtenga información sobre nuestro folleto nuevo y en curso.",
  price_list_desc: "Conozca nuestra lista de precios nueva y en curso.",
  event_desc: "Obtenga información sobre nuestros eventos nuevos y en curso.",
  product_desc: "Obtenga información sobre nuestros productos nuevos y en curso.",
  events: "Evento",
  products: "productos",
  user_verified: "Usuario verificado",
  ledger: "Libro mayor",
  gift_point: "Puntos de regalo",
  current_year: "Año corriente",
  last_year: "El año pasado",
  previous_data: "Datos anteriores",
  current_year_points: "Puntos del año actual",
  last_year_points: "Puntos del año pasado",
  previous_data_points: "Puntos de datos anteriores",
  scooter: "Scooter",
  football: "Fútbol americano",
  lucky_draw: "Sorteo",
  hit_the_number: "Golpea el número",
  puzzle: "Rompecabezas",
  tapping: "tocando",
  prediction_game: "Juego de predicción"
};
export const translationsGerman = {
  key: "German",
  current_balance: "Aktuelles Guthaben",
  latest_achivements: "Neueste Errungenschaften",
  badges: "Abzeichen",
  quote: "Zitieren",
  your_opinion_matters: "Deine Meinung zählt",
  whats_new: "Was gibt's Neues?",
  contest: "Wettbewerb",
  movement: "Bewegung",
  avatar: "Benutzerbild",
  banners: "Banner",
  schemeList: "Schemaliste",
  home: "Zuhause",
  scan: "Scan",
  redeem: "Tilgen",
  stories: "Geschichten",
  spin_win: "Spin & Win",
  opportunities: "Chancen",
  analytics: "Analytik",
  rewards_cashback: "Prämien & Cashbacks",
  points_information: "Punkteinformationen",
  games: "Spiele",
  certificates: "Zertifikate",
  about_us: "Über uns",
  terms_conditions: "Terms & amp; Bedingungen",
  settings: "Einstellungen",
  logout: "Ausloggen",
  spins: "Spins",
  You_havn_t_won_any_spin_and_win_till_now:
    "Sie haben bis jetzt noch keinen Spin & Win gewonnen!",
  total_cashback_won: "Gesamter Cashback gewonnen",
  unotag_points: "UNOTAG-Punkte",
  your_stickers: "Ihre Aufkleber",
  unlock_card: "Schalte die Thesenkarte frei",
  cashback_won: "Cashback gewonnen",
  date: "Datum",
  points: "Punkte",
  earned_points: "Verdiente Punkte",
  redeem_points: "Punkte einlösen",
  active: "Aktiv",
  upcoming: "Bevorstehende",
  history: "Geschichte",
  rewards: "Belohnung",
  total_score: "Gesamtpunktzahl",
  recents: "Letzte",
  seen: "Gesehen",
  language: "Sprache",
  application_theme: "Anwendungsthema",
  push_notification: "Push-Benachrichtigung",
  email_notification: "Email Benachrichtigung",
  notification: "Benachrichtigung",
  app_permissions: "App-Berechtigungen",
  camera: "Kamera",
  location: "Standort",
  select_your_method_to_tranfer_your_points:
    "Wählen Sie Ihre Methode, um Ihre Punkte zu übertragen",
  paytm_wallet: "Paytm-Wallet",
  bank_transfer: "Banküberweisung",
  upi: "UPI",
  transfer_point: "Transferpunkte",
  vouchers: "Gutscheine",
  edit_details: "Details bearbeiten",
  transfer_money_to_paytm_wallet: "Überweisen Sie Geld an Paytm Wallet",
  mobile_number: "Handynummer",
  enter_points: "Punkte eingeben: 100",
  proceed: "Vorgehen",
  transfer_money_to_bank_accout: "Geld auf Bankkonto überweisen",
  name: "Name",
  bank_name: "Bank Name",
  ac_number: "A/C-Nummer",
  ifsc_code: "IFSC-Code",
  transfer_money_through_upi: "Überweisen Sie Geld über Upi",
  upi_id: "UPI-ID",
  enter_mobile_number: "Handynummer eingeben",
  transfer_points: "Transferpunkte",
  qr_code: "QR-Code",
  enter_qr_code: "QR-Code eingeben",
  achivement: "Erfolge",
  progress: "Progress",
  awards: "Auszeichnungen",
  spin_the_wheel: "DREHE DAS RAD",
  UNO_TAG_stickers_are_here: "UNO TAG Aufkleber sind da!",
  get_a_sticker_every_time_you_use_unotag:
    "Holen Sie sich jedes Mal einen Aufkleber, wenn Sie UNO TAG verwenden.",
  sticker_shelf: "Aufkleber-Regal",
  survey: "Umfrage",
  no_questions_yet: "Noch keine Fragen!",
  submit: "einreichen",
  edit: "Bearbeiten",
  profile: "Profil",
  total_points: "Gesamtpunktzahl",
  rank: "Rang",
  user_level: "Benutzerlevel",
  address_details: "Adressen Details",
  country: "Land",
  state: "Bundesland",
  city: "Stadt",
  pincode: "Geheimzahl",
  address: "Die Anschrift",
  personal_details: "Persönliche Daten",
  mobile_no: "Handy-Nr.",
  user_role: "Benutzer-Rolle",
  email: "Email",
  gender: "Geschlecht",
  birth_date: "Geburtsdatum",
  anniversary_date: "Jubiläumsdatum",
  edit_user: "Benutzer bearbeiten",
  add_user: "Benutzer hinzufügen",
  full_name: "Vollständiger Name",
  phone_number: "Telefonnummer",
  mandatory_details: "Pflichtangaben",
  kyc_number: "KYC-Nummer",
  profile_photo: "Profilfoto",
  district: "Kreis",
  payment_details: "Zahlungsdetails",
  optional: "Optional",
  bank_account_number: "Kontonummer",
  full_name_as_per_bank: "FullName wie pro Bankkonto",
  bank_IFSC_code: "IFSC-Code der Bank",
  dealers: "Händler",
  other_optional_details: "Andere optionale Details",
  reset: "Zurücksetzen",
  aadhar_card_number: "Aadhar-Kartennummer",
  chat: "Plaudern",
  collected: "Gesammelt",
  cheers: "Beifall",
  let_celebrate_movement: "Lasst uns Bewegung feiern!",
  quiz: "Quiz",
  hangman_game: "Henker-Spiel",
  slot_machine_game: "Spielautomaten",
  poll: "Umfrage",
  math_game: "Mathe-Spiel",
  slot_machine: "Einarmiger Bandit",
  spin: "Rotieren",
  no_of_spin_left: "Anzahl der verbleibenden Drehungen",
  casino_montecarlo: "Kasino montecarlo",
  hangman: "Henker",
  find_the_hidden_word:
    "Finden Sie das versteckte Wort - Geben Sie einen Buchstaben ein",
  do_you_know: "Wissen Sie",
  math: "Mathematik?",
  press_to_start_the_game: "Drücken Sie , um das Spiel zu starten",
  start: "Start",
  get_ready: "SICH FERTIG MACHEN...",
  game_over: "SPIEL IST AUS",
  final_score: "ENDSTAND",
  retry: "WIEDERHOLEN",
  another_player: "Ein anderer Spieler",
  activity: "Aktivität",
  new_quiz: "Neues Quiz",
  see_all: "Alles sehen",
  running: "Betrieb",
  completed: "Abgeschlossen",
  unattemted: "Unversucht",
  skip: "Überspringen",
  something_wrong: "Etwas ist schief gelaufen!",
  question: "Frage",
  rewards_points: "Belohnungspunkte",
  you_can_transfer_your_money_to_any_of_following_method: "Sie können Ihr Geld auf eine der folgenden Methoden überweisen.",
  transfer_rate: "Übertragungsrate",
  transfer_to_paytm_wallet: "Überweisung an Paytm Wallet",
  transfer_to_any_upi: "Übertragung auf einen beliebigen UPI",
  transfer_to_bank: "Überweisung an die Bank",
  show_history: "Zeige die Geschichte",
  click_here_to_scan_from_product: "Klicken Sie hier, um vom Produkt zu scannen.",
  get_rewards_now: "Jetzt Belohnung erhalten!",
  view: "Alle ansehen",
  price_list: "Preisliste",
  brochure: "Broschüre",
  residential_details: "Wohndetails",
  show_personal_details: "Persönliche Daten anzeigen",
  show_residential_details: "Wohndetails anzeigen",
  select_language: "Sprache auswählen",
  customer_care: "Kundendienst",
  your_avatar: "Dein Avatar",
  create_avatar: "Avatar erstellen",
  challenges: "Herausforderungen",
  kyc_verify: "KYC-Verifizierung",
  kyc_verification: "KYC-Verifizierung",
  send_otp: "Senden Sie Otp",
  paytm_number: "Paytm-Nummer",
  transfer_number: "Überweisungsnummer",
  schemes: "Schemata",
  non_redeem_points: "Punkte nicht einlösen",
  transcation_date: "Transaktionsdatum",
  product_code: "Produktcode",
  status: "Status",
  product_name: "Produktname",
  scheme_name: "Schemaname",
  edit_profile: "Profil bearbeiten",
  redeem_gift: "Geschenk einlösen",
  schemes_desc: "Erfahren Sie mehr über unsere neuen und laufenden Programme.",
  brochure_desc: "Erfahren Sie mehr über unsere neue und fortlaufende Broschüre.",
  price_list_desc: "Erfahren Sie mehr über unsere neue und fortlaufende Preisliste.",
  event_desc: "Erfahren Sie mehr über unsere neuen und laufenden Veranstaltungen.",
  product_desc: "Erfahren Sie mehr über unsere neuen und laufenden Produkte.",
  events: "Fall",
  products: "Produkte",
  user_verified: "Vom Benutzer bestätigt",
  ledger: "Hauptbuch",
  gift_point: "Geschenkpunkte",
  current_year: "Laufendes Jahr",
  last_year: "Letztes Jahr",
  previous_data: "Vorherige Daten",
  current_year_points: "Punkte für das aktuelle Jahr",
  last_year_points: "Punkte des letzten Jahres",
  previous_data_points: "Vorherige Datenpunkte",
  scooter: "Roller",
  football: "Fußball",
  lucky_draw: "Glücksverlosung",
  hit_the_number: "Drücken Sie die Nummer",
  puzzle: "Puzzle",
  tapping: "Klopfen",
  prediction_game: "Tippspiel"
};
export const translationsPortugese = {
  key: "Portugese",
  current_balance: "Saldo atual",
  latest_achivements: "Últimas conquistas",
  badges: "Distintivos",
  quote: "Citar",
  your_opinion_matters: "Sua opinião importa",
  whats_new: "O que há de novo?",
  contest: "concurso",
  movement: "Movimento",
  avatar: "Avatar",
  banners: "Faixas",
  schemeList: "Lista de Esquemas",
  home: "Lar",
  scan: "Varredura",
  redeem: "Resgatar",
  stories: "Histórias",
  spin_win: "Gire e Ganhe",
  opportunities: "Oportunidades",
  analytics: "Análise",
  rewards_cashback: "Recompensas e reembolsos",
  points_information: "Informações de pontos",
  games: "Jogos",
  certificates: "Certificados",
  about_us: "Sobre nós",
  terms_conditions: "termos e Condições",
  settings: "Configurações",
  logout: "Sair",
  spins: "Rotaciona",
  You_havn_t_won_any_spin_and_win_till_now:
    "Você não ganhou nenhuma rodada e ganhou até agora!",
  total_cashback_won: "Reembolso total ganho",
  unotag_points: "Pontos UNOTAG",
  your_stickers: "Seus adesivos",
  unlock_card: "Desbloquear cartão de teses",
  cashback_won: "Reembolso ganho",
  date: "Encontro: Data",
  points: "Pontos",
  earned_points: "Pontos ganhos",
  redeem_points: "Resgatar pontos",
  active: "Ativo",
  upcoming: "Por vir",
  history: "História",
  rewards: "Recompensas",
  total_score: "Pontuação total",
  recents: "Recentes",
  seen: "Visto",
  language: "Linguagem",
  application_theme: "Tema do aplicativo",
  push_notification: "Notificação push",
  email_notification: "Notificação de Email",
  notification: "Notificação",
  app_permissions: "Permissões do aplicativo",
  camera: "Câmera",
  location: "Localização",
  select_your_method_to_tranfer_your_points:
    "Selecione seu método para transferir seus pontos",
  paytm_wallet: "Carteira Paytm",
  bank_transfer: "Transferência bancária",
  upi: "UPI",
  transfer_point: "Transferir Pontos",
  vouchers: "Vouchers",
  edit_details: "Editar Detalhes",
  transfer_money_to_paytm_wallet: "Transferir dinheiro para a carteira Paytm",
  mobile_number: "Número de telemóvel",
  enter_points: "Insira Pontos: 100",
  proceed: "Continuar",
  transfer_money_to_bank_accout: "Transferir dinheiro para conta bancária",
  name: "Nome",
  bank_name: "Nome do banco",
  ac_number: "Número do A/C",
  ifsc_code: "Código IFSC",
  transfer_money_through_upi: "Transferir dinheiro através da Upi",
  upi_id: "Código UPI",
  enter_mobile_number: "Digite o número do celular",
  transfer_points: "Transferir Pontos",
  qr_code: "Código QR",
  enter_qr_code: "Digite o código QR",
  achivement: "Realizações",
  progress: "Progresso",
  awards: "Prêmios",
  spin_the_wheel: "GIRE A RODA",
  UNO_TAG_stickers_are_here: "Chegaram os adesivos UNO TAG!",
  get_a_sticker_every_time_you_use_unotag:
    "Ganhe um adesivo toda vez que usar o UNO TAG.",
  sticker_shelf: "Prateleira de adesivos",
  survey: "Enquete",
  no_questions_yet: "Sem perguntas ainda!",
  submit: "Enviar",
  edit: "Editar",
  profile: "Perfil",
  total_points: "Total de pontos",
  rank: "Classificação",
  user_level: "Nível de usuário",
  address_details: "Detalhes do Endereço",
  country: "País",
  state: "Estado",
  city: "Cidade",
  pincode: "Código PIN",
  address: "Endereço",
  personal_details: "Detalhes pessoais",
  mobile_no: "Nº móvel",
  user_role: "Papel do usuário",
  email: "E-mail",
  gender: "Gênero",
  birth_date: "Data de nascimento",
  anniversary_date: "Data de aniversário",
  edit_user: "Editar usuário",
  add_user: "Adicionar usuário",
  full_name: "Nome completo",
  phone_number: "Número de telefone",
  mandatory_details: "Detalhes obrigatórios",
  kyc_number: "Número KYC",
  profile_photo: "Foto de perfil",
  district: "Distrito",
  payment_details: "Detalhes do pagamento",
  optional: "Opcional",
  bank_account_number: "Número da conta de banco",
  full_name_as_per_bank: "FullName como por conta bancária",
  bank_IFSC_code: "Código IFSC do banco",
  dealers: "Concessionárias",
  other_optional_details: "Outros detalhes opcionais",
  reset: "Redefinir",
  aadhar_card_number: "Número do Cartão Aadhar",
  chat: "Bate-papo",
  collected: "Coletado",
  cheers: "Saúde",
  let_celebrate_movement: "Vamos Celebrar o Movimento!",
  quiz: "Questionário",
  hangman_game: "Jogo da forca",
  slot_machine_game: "Caça-níqueis",
  poll: "Enquete",
  math_game: "jogo de matemática",
  slot_machine: "Máquina caça-níqueis",
  spin: "Rodar",
  no_of_spin_left: "Nº de rodadas restantes",
  casino_montecarlo: "cassino montecarlo",
  hangman: "Carrasco",
  find_the_hidden_word: "Encontre a palavra oculta - Digite uma letra",
  do_you_know: "Você sabe",
  math: "Matemática?",
  press_to_start_the_game: "Pressione para iniciar o jogo",
  start: "Começar",
  get_ready: "PREPARE-SE...",
  game_over: "FIM DE JOGO",
  final_score: "PONTUAÇÃO FINAL",
  retry: "TENTAR NOVAMENTE",
  another_player: "Outro jogador",
  activity: "Atividade",
  new_quiz: "Novo teste",
  see_all: "Ver tudo",
  running: "Encontro",
  completed: "Concluído",
  unattemted: "Não tentado",
  skip: "Pular",
  something_wrong: "Algo deu errado!",
  question: "Pergunta",
  rewards_points: "Pontos de recompensa",
  you_can_transfer_your_money_to_any_of_following_method: "Você pode transferir seu dinheiro para qualquer um dos seguintes métodos.",
  transfer_rate: "Taxa de transferência",
  transfer_to_paytm_wallet: "Transferir para Carteira Paytm",
  transfer_to_any_upi: "Transferência para qualquer UPI",
  transfer_to_bank: "Transferir para Banco",
  show_history: "Mostrar histórico",
  click_here_to_scan_from_product: "Clique aqui para digitalizar a partir do produto.",
  get_rewards_now: "Obter recompensa agora!",
  view: "Ver tudo",
  price_list: "Lista de preços",
  brochure: "Folheto",
  residential_details: "Detalhes residenciais",
  show_personal_details: "Mostrar detalhes pessoais",
  show_residential_details: "Mostrar detalhes residenciais",
  select_language: "Selecione o idioma",
  customer_care: "Servico de atendimento ao Consumidor",
  your_avatar: "Seu avatar",
  create_avatar: "Criar avatar",
  challenges: "desafios",
  kyc_verify: "Verificação KYC",
  kyc_verification: "Verificação KYC",
  send_otp: "Enviar OTP",
  paytm_number: "Número de pagamento",
  transfer_number: "Número de transferência",
  schemes: "Esquemas",
  non_redeem_points: "Pontos não resgatados",
  transcation_date: "Data da Transação",
  product_code: "Código do produto",
  status: "Status",
  product_name: "Nome do Produto",
  scheme_name: "Nome do Esquema",
  edit_profile: "Editar Perfil",
  redeem_gift: "Resgatar presente",
  schemes_desc: "Saiba mais sobre nossos esquemas novos e em andamento.",
  brochure_desc: "Saiba mais sobre nosso folheto novo e em andamento.",
  price_list_desc: "Saiba mais sobre nossa nova e atual lista de preços.",
  event_desc: "Saiba mais sobre nossos eventos novos e em andamento.",
  product_desc: "Saiba mais sobre nossos produtos novos e em andamento.",
  events: "Evento",
  products: "Produtos",
  user_verified: "Verificado pelo usuário",
  ledger: "Razão",
  gift_point: "Pontos de presente",
  current_year: "Ano atual",
  last_year: "Ano passado",
  previous_data: "Dados anteriores",
  current_year_points: "Pontos do ano atual",
  last_year_points: "Pontos do ano passado",
  previous_data_points: "Pontos de dados anteriores",
  scooter: "Lambreta",
  football: "Futebol",
  lucky_draw: "Sorteio",
  hit_the_number: "Acerte o número",
  puzzle: "Quebra-cabeça",
  tapping: "tocando",
  prediction_game: "jogo de previsão"
};
export const translationsJapanese = {
  key: "Japanese",
  current_balance: "経常収支",
  latest_achivements: "最新の成果",
  badges: "バッジ",
  quote: "引用",
  your_opinion_matters: "あなたの意見は重要です",
  whats_new: "新着情報？",
  contest: "コンテスト",
  movement: "動き",
  avatar: "アバター",
  banners: "バナー",
  schemeList: "スキームリスト",
  home: "家",
  scan: "スキャン",
  redeem: "償還",
  stories: "ストーリー",
  spin_win: "スピン＆ウィン",
  opportunities: "機会",
  analytics: "分析",
  rewards_cashback: "報酬とキャッシュバック",
  points_information: "ポイント情報",
  games: "ゲーム",
  certificates: "証明書",
  about_us: "私たちに関しては",
  terms_conditions: "利用規約",
  settings: "設定",
  logout: "ログアウト",
  spins: "スピン",
  You_havn_t_won_any_spin_and_win_till_now:
    "あなたは今までスピンと勝利を勝ち取ったことはありません！",
  total_cashback_won: "トータルキャッシュバック獲得",
  unotag_points: "UNOTAGポイント",
  your_stickers: "あなたのステッカー",
  unlock_card: "論文カードのロックを解除する",
  cashback_won: "キャッシュバック獲得",
  date: "日にち",
  points: "ポイント",
  earned_points: "獲得ポイント",
  redeem_points: "ポイントの交換",
  active: "アクティブ",
  upcoming: "今後の",
  history: "歴史",
  rewards: "報酬",
  total_score: "総得点",
  recents: "最近",
  seen: "見た",
  language: "言語",
  application_theme: "アプリケーションのテーマ",
  push_notification: "プッシュ通知",
  email_notification: "電子メール通知",
  notification: "通知",
  app_permissions: "アプリの許可",
  camera: "カメラ",
  location: "位置",
  select_your_method_to_tranfer_your_points:
    "ポイントを転送する方法を選択してください",
  paytm_wallet: "Paytmウォレット",
  bank_transfer: "銀行振込",
  upi: "UPI",
  transfer_point: "転送ポイント",
  vouchers: "バウチャー",
  edit_details: "詳細を編集する",
  transfer_money_to_paytm_wallet: "Paytmウォレットに送金する",
  mobile_number: "携帯電話番号",
  enter_points: "ポイントを入力してください：100",
  proceed: "続行",
  transfer_money_to_bank_accout: "銀行口座に送金する",
  name: "名前",
  bank_name: "銀行名",
  ac_number: "A/C番号",
  ifsc_code: "IFSCコード",
  transfer_money_through_upi: "Upiを介して送金する",
  upi_id: "UPIID",
  enter_mobile_number: "携帯電話番号を入力してください",
  transfer_points: "転送ポイント",
  qr_code: "QRコード",
  enter_qr_code: "QRコードを入力してください",
  achivement: "実績",
  progress: "進捗",
  awards: "賞",
  spin_the_wheel: "ホイールを回転させる",
  UNO_TAG_stickers_are_here: "UNO TAGステッカーはこちら！",
  get_a_sticker_every_time_you_use_unotag:
    "UNOTAGを使用するたびにステッカーを入手してください。",
  sticker_shelf: "ステッカー棚",
  survey: "調査",
  no_questions_yet: "まだ質問はありません！",
  submit: "送信",
  edit: "編集",
  profile: "プロフィール",
  total_points: "合計点",
  rank: "ランク",
  user_level: "ユーザーレベル",
  address_details: "住所の詳細",
  country: "国",
  state: "州",
  city: "街",
  pincode: "ピンコード",
  address: "住所",
  personal_details: "個人情報",
  mobile_no: "携帯番号",
  user_role: "ユーザーロール",
  email: "Eメール",
  gender: "性別",
  birth_date: "生年月日",
  anniversary_date: "記念日",
  edit_user: "ユーザーの編集",
  add_user: "ユーザーを追加する",
  full_name: "フルネーム",
  phone_number: "電話番号",
  mandatory_details: "必須の詳細",
  kyc_number: "KYC番号",
  profile_photo: "プロフィール写真",
  district: "区域",
  payment_details: "支払詳細",
  optional: "オプション",
  bank_account_number: "銀行の口座番号",
  full_name_as_per_bank: "銀行口座ごとの氏名",
  bank_IFSC_code: "銀行IFSCコード",
  dealers: "ディーラー",
  other_optional_details: "その他のオプションの詳細",
  reset: "リセット",
  aadhar_card_number: "アドハーカード番号",
  chat: "チャット",
  collected: "集めました",
  cheers: "乾杯",
  let_celebrate_movement: "動きを祝おう！",
  quiz: "クイズ",
  hangman_game: "絞首刑執行人ゲーム",
  slot_machine_game: "スロットマシンゲーム",
  poll: "投票",
  math_game: "数学ゲーム",
  slot_machine: "スロットマシン",
  spin: "スピン",
  no_of_spin_left: "残りのスピン数",
  casino_montecarlo: "カジノモンテカルロ",
  hangman: "絞首刑執行人",
  find_the_hidden_word: "隠された単語を見つける-文字を入力してください",
  do_you_know: "あなたは知っていますか",
  math: "算数？",
  press_to_start_the_game: "を押してゲームを開始します",
  start: "始める",
  get_ready: "準備をしなさい...",
  game_over: "ゲームオーバー",
  final_score: "最終スコア",
  retry: "リトライ",
  another_player: "別のプレイヤー",
  activity: "アクティビティ",
  new_quiz: "新しいクイズ",
  see_all: "すべてを見る",
  running: "ランニング",
  completed: "完了",
  unattemted: "試みられていない",
  skip: "スキップ",
  something_wrong: "何かがうまくいかなかった！",
  question: "質問",
  rewards_points: "報酬ポイント",
  you_can_transfer_your_money_to_any_of_following_method: "次のいずれかの方法で送金できます。",
  transfer_rate: "転送速度",
  transfer_to_paytm_wallet: "Paytm ウォレットへの送金",
  transfer_to_any_upi: "任意の UPI に転送",
  transfer_to_bank: "銀行振込",
  show_history: "履歴を表示",
  click_here_to_scan_from_product: "製品からスキャンするには、ここをクリックしてください。",
  get_rewards_now: "今すぐ報酬をゲット！",
  view: "すべて見る",
  price_list: "価格表",
  brochure: "パンフレット",
  residential_details: "住居の詳細",
  show_personal_details: "個人情報を表示",
  show_residential_details: "住宅の詳細を表示",
  select_language: "言語を選択する",
  customer_care: "カスタマーケア",
  your_avatar: "あなたのアバター",
  create_avatar: "アバターの作成",
  challenges: "課題",
  kyc_verify: "KYC検証",
  kyc_verification: "KYC検証",
  send_otp: "Otp を送信",
  paytm_number: "ペイメント番号",
  transfer_number: "転送番号",
  schemes: "スキーム",
  non_redeem_points: "ポイント交換不可",
  transcation_date: "取引日",
  product_code: "製品コード",
  status: "スターテス",
  product_name: "商品名",
  scheme_name: "スキーム名",
  edit_profile: "プロファイル編集",
  redeem_gift: "ギフトを引き換える",
  schemes_desc: "当社の新規および継続的なスキームについてご覧ください。",
  brochure_desc: "現在進行中の新しいパンフレットについてご覧ください。",
  price_list_desc: "新しい継続的な価格リストについてご覧ください。",
  event_desc: "新しいイベントと進行中のイベントについてご覧ください。",
  product_desc: "当社の新製品および継続的な製品についてご覧ください。",
  events: "イベント",
  products: "製品",
  user_verified: "ユーザー認証済み",
  ledger: "元帳",
  gift_point: "ギフトポイント",
  current_year: "今年",
  last_year: "去年",
  previous_data: "以前のデータ",
  current_year_points: "今年のポイント",
  last_year_points: "昨年のポイント",
  previous_data_points: "以前のデータポイント",
  scooter: "スクーター",
  football: "フットボール",
  lucky_draw: "ラッキードロー",
  hit_the_number: "数字をヒット",
  puzzle: "パズル",
  tapping: "タッピング",
  prediction_game: "予測ゲーム"
};
