import React from 'react'
import { Modal, Image } from "react-bootstrap";
import moment from 'moment';
import close from "/assets/images/popclose.png";
import { useAuth } from '../../context/auth-context';

function InvoiceDetailModal({invoiceData, showInvoiceModal, setShowInvoiceModal, handleInvoice, showApprove}) {
    const { user } = useAuth()

  return (
    <Modal
        fullscreen={true}
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
      >
        <Modal.Body className="my-5 mx-1 p-2">
          <span
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
            }}
          >
            <Image
              src={close}
              alt=""
              onClick={() => setShowInvoiceModal(false)}
            />
          </span>
          <table className="table  mb-5">
            <tbody>
              <tr>
                <th scope="row" >InvoiceDetailId</th>
                <td  style={{ width: "25%"}}>{invoiceData[0]?.InvoiceDetailID}</td>
              </tr>
              <tr>
                <th scope="row" >Date</th>
                <td >{moment(invoiceData[0]?.InvoiceDate,).format("DD-MM-YYYY")}</td>
              </tr>
              <tr>
                <th scope="row" >SkuId</th>
                <th scope="row" >Quantity</th>
                {/* <th scope="row">UnitPrice</th>
                <th scope="row">SubTotal</th> */}
              </tr>
              <tr>
                {invoiceData.length > 0 &&  invoiceData.map((item, i) => (
                    <React.Fragment key={i}>
                    <td >{item?.SkuUniqueId}</td>
                    <td >{item?.Quantity}</td>
                    {/* <td>{item?.UnitPrice}</td>
                    <td>{item?.SubTotal}</td> */}
                    </React.Fragment>
                ))
                }
              </tr>
              <tr>
              </tr>
              <tr>
              </tr>
            </tbody>
          </table>
          <div className='d-flex justify-content-center'>
          {((user?.profile?.roleId || user?.roleId) === "5" && showApprove) && (
          <div>
            <button
              onClick={() => handleInvoice(1)}
            type="button" 
            className="btn btn-primary mx-3"
            style={{
              backgroundColor: "#2E008B",
              border: "none",
              width: "90px"
            }}
            >
              Approve
              </button>

              <button
              onClick={() => handleInvoice(2)}
            type="button" 
            className="btn btn-primary mx-3"
            style={{
              backgroundColor: "#2E008B",
              border: "none",
              width: "90px"
            }}
            >
              Reject
              </button>
          </div>)}
          </div>
        </Modal.Body>
      </Modal>
  )
}

export default InvoiceDetailModal