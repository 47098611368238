import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useHistory } from "react-router";
// import { CiMenuKebab } from "react-icons/ci";
import CustomCarousel from "../component/Home/CustomCarousel";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { useLocalStorage } from "../hooks";

function Order() {
  const [bannerData, setBannerData] = useState([])
  const [currentFyData, setCurrentFyData] = useState([])
  const history = useHistory();
  const [user] = useLocalStorage("loginuser")

  let profileImgSrc = ((user?.profile?.profilePhotoPath || user?.profilePhotoPath) ?? "/assets/images/profile-placeholder.svg")

  const getCurrentFyTotal = async () => {
    const [error, result] = await asyncWrap(axios.get("fy-data"))
    if (!result) {
      console.log("Error in getting financial year total data")
      console.log(error);
      return;
    }
    if(result?.data?.data){
      setCurrentFyData(result.data.data)
    }
  }

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log("Error in getting home page banner data")
      console.log(error);
      return;
    }

    if(result?.data?.data?.HomePageBanner?.length > 0) {
      let imgUrlArr = result.data.data.HomePageBanner.map(item => ({
        ...item,
        imgUrl: item?.BannerImage
      }))
      setBannerData(imgUrlArr)
    }
  };


  useEffect(() => {
    getCurrentFyTotal();
    getCmsValues();
  }, [])

  return (
    <div className="d-flex flex-column p-3">
      <div className="d-flex flex-row justify-content-between my-2">
        <div className="d-flex flex-column text-start">
          <div>{user?.profile?.fullName?.trim() || user?.fullName?.trim()}</div>
          <div>{(user?.profile?.roleId || user?.roleId) === "5" ? "Distributor" : (user?.profile?.roleId || user?.roleId) === "6" ? "Wholesaler" : ""}</div>
        </div>
        <div
          style={{
            // background: "#2E008B",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
        >

          <img onClick={() => history.push("/profile")} src={profileImgSrc} style={{width: "100%"}} alt="profile" />
        </div>
      </div>

      <div className="orderCard text-white my-3 d-flex flex-column text-start p-2">
        <div className="d-flex flex-row justify-content-between">
          <div className="fs-5">
          {(user?.profile?.roleId || user?.roleId) === "5" ? "Total Quantity Purchased" : "Quantity Purchased"}
            </div>
          <div className="d-flex flex-row">
            {/* <div>Last Month</div> */}
            {/* <CiMenuKebab /> */}
          </div>
        </div>
        <div className="fs-1 py-2"> {
          currentFyData?.length > 0 ? currentFyData[0]?.CurrentFYTotalQty : 0
        }</div>
        {/* <div
          style={{ background: "#d9d9d9", width: "fit-content" }}
          className="py-1 px-3 rounded"
        >
          View in Detail
          <BsChevronDown style={{ marginLeft: "5px" }} />
        </div> */}
      </div>

      <div>
        <h2 className="text-start">Dashboard</h2>
        {/* options for retailer */}
        {(user?.profile?.roleId || user?.roleId) === "6" && (
        <div className="d-flex flex-column text-white">
          <div className="d-flex flex-row justify-content-between">
          <div
              onClick={() => {
                history.push("/target-list");
              }}
              className="order-card"
            >
              Targets
            </div>
          
            <div
              onClick={() => {
                history.push("/points-info");
              }}
              className="order-card"
            >
              Statement
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
          <div 
            className="order-card"
            onClick={() => {
              history.push("/redeem"); 
            }}
            >Redeem</div>
            <div 
            className="order-card"
            onClick={() => {
              history.push("/redeem")
            }}
            >Delivery Status</div>
            
          </div>
        </div>)}

        {/* options for distributor */}
        {(user?.profile?.roleId || user?.roleId) === "5" && (<div className="d-flex flex-column text-white">
        <div className="d-flex flex-row justify-content-between">
            <div 
            className="order-card"
            onClick={() => {
              history.push("/view-invoices", {
                userId: -1
              });
            }}
            >Pending Invoices</div>
            <div
              onClick={() => {
                history.push("/user-mapping");
              }}
              className="order-card"
            >
              View Wholesalers
            </div>
          </div>
        </div>)}
      </div>

      <div className="my-4">
        <CustomCarousel
          images={bannerData}
        />
      </div>

      {/* <div>
        <h2 className="text-start">Orders in process</h2>
        <div className="border rounded-4 d-flex flex-column orderCard">
          <div className="d-flex flex-row">
            <div className="p-2">
              <img
                style={{ background: "#d9d9d9" }}
                src="https://squaretoiletries.com/wp-content/uploads/2020/02/Spring-Air-Freshener-Pack-Orange-Fresh-product-info.png"
                width="80px"
                height="80px"
                className="p-1 rounded-4"
              />
            </div>
            <div className="p-2">
              <h6 className="text-start mb-0">A2 Paper</h6>
              <div className="order-process-card-text">150 GSM</div>
              <div className="order-process-card-text">Quality : 5 tons</div>
              <div className="order-process-card-text">
                price Quote : $180 ($36/ton)
              </div>
              <div className="order-process-card-text">
                Distributor : Rk Traders
              </div>
            </div>
            <div className="p-2">
              <div
                style={{ background: "#d9d9d9" }}
                className="px-3 py-1 rounded-2"
              >
                View
              </div>
              <div
                style={{ background: "#d9d9d9" }}
                className="px-3 py-1 rounded-2 mt-1"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-start">Previous Orders</h2>
        <div className="orderCard p-2">
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-start">Authorized Distributor</h2>
        <div className="orderCard p-2">
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-start">Authorized Salesmen</h2>
        <div className="orderCard p-2">
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Order;
