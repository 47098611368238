import React, { useEffect, useState } from 'react'
import { asyncWrap } from '../../utils/utils'
import axios from 'axios'
import { useAuth } from '../../context/auth-context'
import { Pagination, Spin } from 'antd'
import RetailerInvoicesCard from './RetailerInvoicesCard'
import moment from 'moment'
import InvoiceDetailModal from './InvoiceDetailModal'
import { usePagination } from '../../hooks'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

function RetailerInvoicesList({ status, refetch, setRefetch, showApprove }) {
    const [invoicesData, setInvoicesData] = useState([])
    // const [loading, setLoading] = useState(true)
    const [showInvoiceModal, setShowInvoiceModal] = useState(false)
    const [invoiceData, setInvoiceData] = useState([])
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null)
    const {user} = useAuth()
    const location = useLocation()

    // const getInvoices = async () => {
    //     setLoading(true)
    //     const [error, result] = await asyncWrap(axios.get(`/user-invoice?userId=${user?.userId}&status=${status}`))

    //     if(!result) {
    //         console.log("Error in getting invoice data")
    //         console.log(error)
    //         setLoading(false)
    //         return;
    //       }

    //       setLoading(false)
    //       setInvoicesData(result?.data?.data)
    // }

    // useEffect(() => {
    //     getInvoices()
    // }, [status])

    const { data, gotoPage, currentPage, loading, total, fetchData } =
    usePagination(`/user-invoice?userId=${location?.state?.userId || user?.profile?.userId || user?.userId}&status=${status}`);

        const getInvoiceDetail = async (record) => {
        setSelectedInvoiceId(record?.InvoiceID)

        const [error, result] = await asyncWrap(
          axios.get(`/user-invoice/details?id=${record?.InvoiceID}`)
        );
    
        if (!result) {
          console.log(error);
          Swal.fire("Error in getting invoice data");
          return;
        }
    
        setInvoiceData([{...result.data.data[0], InvoiceDate: record?.InvoiceDate}]);
        setShowInvoiceModal(true);
      };

      const handleInvoice = async (status) => {
        const [error, result] = await asyncWrap(axios.put(`user-invoice/update-status`, {
          id: selectedInvoiceId,
          status: status,
          comment: ""
        }))

        if(!result) {
          console.log("Error in invoice approval")
          console.log(error)
          return;
        }

        if(result?.data?.success) {
          setShowInvoiceModal(false)
          setRefetch(prev => !prev)
        }
      }
      

      useEffect(() => {
        fetchData(currentPage)
      }, [refetch])
  return (
    <div>
        {loading ? (
            <div>
                <Spin />
            </div>
        ) : data.length > 0 ? 
        <div>
        {data.map((item, i) => (
            <div className='mb-2' key={i}>
                <RetailerInvoicesCard data={item} getInvoiceDetail={getInvoiceDetail} />
            </div>
        ))}
          <nav
        className="mt-3"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Pagination 
        showQuickJumper
        current={currentPage}
        defaultPageSize={10}
        total={total}
        showSizeChanger={false}
        onChange={(page) => gotoPage(page)}
        />
        </nav>
        </div> : (
            <div>
                No {status === 0 ? "pending" : status === 1 ? "approved" : "rejected"} invoices
            </div>
        )}

        <InvoiceDetailModal 
          invoiceData={invoiceData}
          showInvoiceModal={showInvoiceModal}
          setShowInvoiceModal={setShowInvoiceModal}
          handleInvoice={handleInvoice}
          showApprove={showApprove}
        />
    </div>
  )
}

export default RetailerInvoicesList