import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Rank from "./pages/Rank";
import ProfilePage from "./component/ProfilePage/ProfilePage";
import Survey from "./pages/Survey";
import Settings from "./pages/Settings";
import Intro from "./pages/Intro";
import Login from "./pages/Login";
import Stickers from "./pages/Stickers";
import PointsInformation from "./pages/PointsInformation";
import Group from "./pages/Group";
import LoadingScreen from "./pages/LoadingScreen";
import RedeemPage from "./pages/RedeemPage";
import { setAxiosDefault, setToken } from "./axiosDefaults";
import { useAuth } from "./context/auth-context";
import ScanCode from "./pages/ScanCode";
import AddEndUser from "./pages/AddEndUser";
import AddRetailor from "./pages/AddRetailor";
import Contest from "./pages/Contest";
import { createContext, useEffect, useState } from "react";
import Challenges from "./pages/Challenges";
import GamePointsInformation from "./pages/GamePoint";
import { WebAppConfig } from "./context/WebAppConfig";
import { asyncWrap } from "./utils/utils";
import axios from "axios";
import GetStarted from "./pages/GetStarted";
import CustomerChat from "./pages/CustomerChat";
import KycVerify from "./pages/KycVerify";
import Home2 from "./pages/Home2";
import Brocher from "./component/Home/Brocher";
import Schema from "./component/Home/Schema";
import PriceList from "./component/Home/PriceList";
import Swal from "sweetalert2";
import Analytics from "./component/Analytics/Analytics";
import Events from "./pages/Events";
import Products from "./pages/Products";
import ScratchCardDetail from "./component/ScrachCardModal/ScratchCardDetail";
import TermsAndCondition from "./pages/TermsAndCondition";
import AboutUs from "./pages/AboutUs";
import i18next from "i18next";
import Notification from "./pages/Notification";
import { useMokKeysStore } from "./store";
import Order from "./pages/Order";
import Product from "./component/Order/Product";
import Manufactures from "./component/Order/Manufactures";
import Invoices from "./component/Order/Invoices";
import CartOrder from "./component/Order/CartOrder";
import OrderList from "./component/Order/OrderList";
import TargetList from "./component/Order/TargetList";
import { MokPopup, CheckListPopup, MokCarousel, MokOnBoarding } from "@mok.one/sdk/react";
import UserMapping from "./pages/UserMapping";
import ViewInvoices from "./pages/Invoices";
import ViewOrders from "./pages/OrderDetails";
import ViewInvoicePayments from "./pages/InvoicePayments";
import ViewInvoiceLedger from "./pages/InvoiceLedger";
import SalesInfoPage from "./pages/SalesInfoPage"
import StarRewards from "./pages/StarRewards";
import DivisionWiseSales from "./pages/DivisionWiseSales";
import Document from "./pages/Document";
import DispatchedMaterial from "./pages/DispatchedMaterial";
import Outstanding from "./pages/Outstanding";
import PaymentOutstanding from "./pages/PaymentOutstanding";
import PaymentReceipt from "./pages/PaymentReceipt";
import CreditDebitNote from "./pages/CreditDebitNote";
import PaymentHistory from "./pages/PaymentHistory";
import Catatogue from "./pages/Catatogue";
import ActiveScheme from "./pages/ActiveScheme";
import Policy from "./pages/Policy";
import Agreement from "./pages/Agreement";
import Enquiry from "./pages/Enquiry";
import ComboScheme from "./pages/ComboScheme";
import ComboSummaryReport from "./pages/ComboSummaryReport";
import TargetScheme from "./pages/TargetScheme";
import Videos from "./pages/Videos";
import Payment from "./pages/Payment";

export const Language_Context = createContext();
const App = () => {
  const fetchMokKeys = useMokKeysStore(state => state.getUpdateKeys);
  const mokKeys = useMokKeysStore(state => state.mokKeys);
  const [language, setLangauge] = useState();
  const [webAppConfig, setWebAppConfig] = useState([]);
  setAxiosDefault();
  const { user, setUser } = useAuth();
  if (user?.token) setToken(user.token);

  // const [loading, setLoading] = useState(true);
  // const [splaceScreen, setSplaceScreen] = useState();
  // const splashscreeenshow = true;
  // const getCmsSplaceScreen = async () => {
  //   const [error, result] = await asyncWrap(
  //     axios.get("/cmsValues/?cmsType=SplashScreen")
  //   );
  //   if (!result) {
  //     console.log(error);
  //     return;
  //   }
  //   setSplaceScreen(result.data.data.SplashScreen.SplashScreen);
  // };
  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000);
  // }, []);
  // useEffect(() => {
  //   getCmsSplaceScreen();
  // });

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(
      axios.get("/cmsValues/?cmsType=WebAppConfig")
    );
    if (!result) {
      console.log(error);
      return;
    }
    if (result.data.data.WebAppConfig) {
      setWebAppConfig(JSON.parse(result.data.data?.WebAppConfig?.Descriptions));
    }
  };

  const Logout = () => {
    localStorage.clear();
    setToken(null);
    window.location.reload();
  };

  useEffect(() => {
    if (window.location.search) {
      let token = window.location.search.split("=")[1];
      setToken(token);
      getUserProfile(token);
    }
    getCmsValues();
    if (user) {
      fetchMokKeys();
    }
    //eslint-disable-next-line
  }, [user]);

  const getUserProfile = async token => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      let data = result.data.data;
      data.token = token;
      setUser(data);
      setAxiosDefault();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("key_language")) {
      i18next.changeLanguage(localStorage.getItem("key_language"));
    }
  }, []);

  return (
    <WebAppConfig.Provider value={webAppConfig}>
      <Language_Context.Provider value={[language, setLangauge]}>
        <div className="App">
          {user ? (
            <BrowserRouter basename="/app">
              <div style={{ marginTop: 50, marginBottom: 50 }}>
                {/* <Navbar /> */}
                {mokKeys && (
                  <>
                  <MokPopup
                    readKey={mokKeys?.readKey}
                    writeKey={mokKeys?.writeKey}
                    id={user?.profile?.userId || user.userId}
                  />
                  <CheckListPopup
                    readKey={mokKeys?.readKey}
                    writeKey={mokKeys?.writeKey}
                    id={user?.profile?.userId || user.userId}
                  />
                  <MokCarousel
                    readKey={mokKeys?.readKey}
                    writeKey={mokKeys?.writeKey}
                    id={user?.profile?.userId || user.userId}
                    />
                    <MokOnBoarding
                    readKey={mokKeys?.readKey}
                    writeKey={mokKeys?.writeKey}
                    id={user?.profile?.userId || user.userId}
                    />
                    </>
                )}
                <Switch>
                  <Route path="/analytics" component={Analytics} />
                  <Route path="/home" component={Order} />
                  <Route path="/home2" component={Home2} />
                  <Route path="/rank" component={Rank} />
                  <Route path="/challenge" component={Challenges} />
                  <Route path="/group" component={Group} />
                  <Route path="/profile" component={ProfilePage} />
                  <Route path="/edit-profile/:userId" component={AddEndUser} />
                  <Route
                    path="/edit-retailer/:userId"
                    component={AddRetailor}
                  />
                  <Route path="/survey/:id" component={Survey} />
                  <Route path="/settings" component={Settings} />
                  {/* <Route path="/spin" component={Spins} /> */}
                  <Route path="/intro" component={Intro} />
                  <Route path="/stickers" component={Stickers} />
                  <Route path="/points-info" component={PointsInformation} />
                  <Route path="/game-point" component={GamePointsInformation} />
                  <Route path="/login" component={Login} />
                  <Route path="/loading" component={LoadingScreen} />
                  <Route path="/redeem" component={RedeemPage} />
                  <Route path="/scan" component={ScanCode} />
                  <Route path="/games" component={Contest} />
                  <Route path="/customer-chat" component={CustomerChat} />
                  <Route path="/kycverify" component={KycVerify} />
                  <Route path="/brochure" component={Brocher} />
                  <Route path="/pricelist" component={PriceList} />
                  <Route path="/schemes" component={Schema} />
                  <Route path="/scratchcard" component={ScratchCardDetail} />
                  <Route path="/events" component={Events} />
                  <Route path="/products" component={Products} />
                  <Route path="/terms" component={TermsAndCondition} />
                  <Route path="/aboutus" component={AboutUs} />
                  <Route path="/notificationspage" component={Notification} />
                  <Route path="/order" component={Order} />
                  <Route path="/product" component={Product} />
                  <Route path="/order-list" component={OrderList} />
                  <Route path="/manufactures" component={Manufactures} />
                  <Route path="/invoices" component={Invoices} />
                  <Route path="/cart" component={CartOrder} />
                  <Route path="/target-list" component={TargetList} />
                  <Route path="/logout" component={Logout} />
                  <Route path='/user-mapping' component={UserMapping} />
                  <Route path='/view-invoices' component={ViewInvoices} />
                  <Route path='/view-orders' component={ViewOrders} />
                  <Route path='/invoice-payments' component={ViewInvoicePayments} />
                  <Route path='/invoice-ledger' component={ViewInvoiceLedger} />
                  <Route path='/sales-info' component={SalesInfoPage} />
                  <Route path='/star-rewards' component={StarRewards} />
                  <Route path='/division-wise-sales' component={DivisionWiseSales} />
                  <Route path='/document' component={Document} />
                  <Route path='/dispatched-material' component={DispatchedMaterial} />
                  <Route path='/outstanding' component={Outstanding} />
                  <Route path='/payment-outstanding' component={PaymentOutstanding} />
                  <Route path='/payment-receipt' component={PaymentReceipt} />
                  <Route path='/credit-debit-note' component={CreditDebitNote} />
                  <Route path='/payment-history' component={PaymentHistory} />
                  <Route path='/catalogue' component={Catatogue} />
                  <Route path='/active-scheme' component={ActiveScheme} />
                  <Route path='/policy' component={Policy} />
                  <Route path='/agreement' component={Agreement} />
                  <Route path='/enquiry' component={Enquiry} />
                  <Route path='/combo-scheme' component={ComboScheme} />
                  <Route path='/combo-summary-report' component={ComboSummaryReport} />
                  <Route path='/target-scheme' component={TargetScheme} />
                  <Route path='/videos' component={Videos} />
                  <Route path="/payment" component={Payment} />
                  <Redirect to="/loading" />
                </Switch>
              </div>

              {/* <BottomTab /> */}
            </BrowserRouter>
          ) : (
            <BrowserRouter basename="/app">
              <Switch>
                <Route path="/add-user" component={AddEndUser} />
                <Route path="/add-retailer" component={AddRetailor} />
                <Route path="/login" component={Login} />
                <Route path="/get-started" component={GetStarted} />
                <Route path="/loading" component={LoadingScreen} />
                <Redirect to="/get-started" />
              </Switch>
            </BrowserRouter>
          )}
        </div>
      </Language_Context.Provider>
    </WebAppConfig.Provider>
  );
};

export default App;
