import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  FormGroup,
  Col,
  Label,
  Input,
  Card,
  CardBody,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import FormikInput from "./FormikInput";
import Select from "react-select";
import axios from "axios";
import { asyncWrap } from "../utils/utils";
// import { useParams, useHistory } from "react-router-dom";
import { Spin } from "antd";
import Swal from "sweetalert2";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

// eslint-disable-next-line
export default ({
  isRequiredField,
  isVisibleField,
  registerFields,
  initialValues,
  onSubmit,
  valSchema,
  BeatId,
  ...restofProps
}) => {
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [stateDist, setStateDist] = useState([]);
  // const [isRetailerRequired, setIsRetailerRequired] = useState<string>();

  const [beat, setBeat] = useState([]);
  const [userApproved, setUserApproved] = useState(false);
  // const { userId } = useParams<any>();
  // const history = useHistory();

  useEffect(() => {
    fetchBeats();
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    //eslint-disable-next-line
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again")
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    }
    setUserApproved(result.data.data?.callverification);
  };

  const fetchBeats = async () => {
    const [error, beats] = await asyncWrap(axios.get("masterData/beats"));

    if (error) {
      console.log(error.data.response.message);
    }

    setBeat(
      beats.data.data.map(item => ({
        ...item,
        label: item.BeatName,
        value: item.BeatId,
      }))
    );
  };

  const fetchStateData = async () => {
    const [error, result] = await asyncWrap(axios.get("masterData/regions"));

    if (error) {
      console.log(error);
      return;
    }

    setState(
      result.data.data.states.map(item => ({
        ...item,
        label: item.stateName,
        value: item.stateId,
      }))
    );
    setStateDist(
      result.data.data.districts.map(item => ({
        ...item,
        label: item.districtName,
        value: item.districtId,
      }))
    );
  };

  const handleDateError = (setFieldValue, name, value) => {
    if (value === "dd-mm-yyy") {
      setFieldValue(name, "");
      return;
    }
    setFieldValue(name, value);
  };

  const counterBoyAdd = (values, setValues) => {
    setValues({
      ...values,
      counterBoy: [
        {
          countername: "",
          contactnumber: "",
        },
        ...values.counterBoy,
      ],
    });
  };

  const deleteCountryBoy = (values, setValues, index) => {
    setValues({
      ...values,
      counterBoy: values.counterBoy.filter((item, i) => i !== index),
    });
  };


  // const fetchUserTypeSetting = async () => {
  //   const [error, result] = await asyncWrap(axios.get("config"));
  //   if (error) {
  //     console.log(error);
  //   } else {
  //     // const retailerRequired = result.data.data.filter(
  //     //   item => item.FlagName === "IsRetailerAllow"
  //     // )[0].Value;
  //     // setIsRetailerRequired(retailerRequired);
  //   }
  // };

  useEffect(() => {
    fetchStateData();
    // fetchUserTypeSetting();
  }, []);

  const newInitialVal = () => {
    if (BeatId) {
      const beatsEditData = beat.filter(item => item.BeatId === BeatId);
      return {
        ...initialValues,
        beatsName: beatsEditData,
      };
    }
    return initialValues;
  };

  // const deletePaymentDetails = async (type: string, userId: string, setFieldValue) => {
  //   try {
  //     const response = await axios({
  //       method: 'DELETE',
  //       url: 'users/deletePayment',
  //       data: { type, userId }
  //     })

  //     message.success({ content: response.data.message, style: { marginTop: '5vh' } })

  //     if (type === 'Paytm') {
  //       setFieldValue('paytmNumber', '')
  //     } else if (type === 'Bank') {
  //       setFieldValue('accHolderName', '')
  //       setFieldValue('bankAccNumber', '')
  //       setFieldValue('bankName', '')
  //       setFieldValue('ifscCode', '')
  //     } else if (type === 'Upi') {
  //       setFieldValue('UpiId', '')
  //     }
  //   } catch (error) {
  //     message.error({ content: `Failed to delete ${type} payment detail`, style: { marginTop: '5vh' } })
  //   }
  // }

  const isDisabled = () => {
    return false;
  };

  if (!registerFields.length) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
      >
        <Spin spinning tip="Loading..." size="large" />
      </div>
    );
  }

  const stateDistFilter = (value, setFieldValue) => {
    setFieldValue("state", value);
    setFieldValue("district", { label: "", value: "" });
    setDistrict(stateDist.filter(item => item.stateId === value.stateId));
  };

  return (
    <Formik
      initialValues={newInitialVal()}
      onSubmit={onSubmit}
      validationSchema={valSchema}
      {...restofProps}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setValues,
        isSubmitting,
        dirty,
      }) => (
        <Form>
          {isVisibleField("User Name") && (
            <FormikInput
              style={{ textAlign: "start" }}
              label="User Name"
              errors={errors["userName"]}
              name="userName"
              touched={touched["userName"]}
              type="text"
              required={isRequiredField("User Name")}
            />
          )}
             {isVisibleField("Full Name") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Full Name")}
              label="Owner Name"
              errors={errors["ownerName"]}
              name="ownerName"
              touched={touched["ownerName"]}
              type="text"
              onChange={e => {
                let value = e.target.value || "";
                value = value.toUpperCase().trim();
                setFieldValue("ownerName", value);
              }}
            />
          )}

          {isVisibleField("Phone Number") && (
            <FormikInput
              readOnly
              style={{ textAlign: "start" }}
              label="Owner Phone"
              errors={errors["ownerPhone"]}
              name="ownerPhone"
              touched={touched["ownerPhone"]}
              type="tel"
              required={isRequiredField("Phone Number")}
            />
          )}
          {isVisibleField("Shop Name") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Shop Name")}
              label="Shop Name"
              errors={errors["shopName"]}
              name="shopName"
              touched={touched["shopName"]}
              type="text"
              onChange={e => {
                let value = e.target.value || "";
                value = value.toUpperCase().trim();
                setFieldValue("shopName", value);
              }}
            />
          )}
       
          {isVisibleField("City") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("City")}
              label="City"
              errors={errors["city"]}
              name="city"
              touched={touched["city"]}
              type="text"
            />
          )}
          {isVisibleField("State") && (
            <FormGroup row>
              <Col xs="12" md="9" className="p-0">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#3A69DD" : "#B0B0B0",
                      padding: "10px",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0px",
                    }),
                  }}
                  id="state"
                  options={state}
                  isSearchable
                  closeMenuOnSelect
                  value={values.state}
                  onChange={val => {
                    stateDistFilter(val, setFieldValue);
                  }}
                />
              </Col>
            </FormGroup>
          )}
          {isVisibleField("District") && (
            <FormGroup row>
              <Col xs="12" md="9" className="p-0">
                <Select
                  isDisabled={!values.state.stateId}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#3A69DD" : "#B0B0B0",
                      padding: "10px",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0px",
                    }),
                  }}
                  id="district"
                  options={district}
                  isSearchable
                  closeMenuOnSelect
                  value={values.district}
                  onChange={val => {
                    setFieldValue("district", val);
                  }}
                />
              </Col>
            </FormGroup>
          )}
          {/* {isVisibleField("User Role") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                <Field
                  style={{ textAlign: "start" }}
                  required={isRequiredField("User Role")}
                  type="select"
                  id="roleId"
                  name="roleId"
                  as={Input}
                  onChange={e => {
                    let val = e.target.value;
                    let location;
                    if (val === "5" || val === "7") {
                      if (!userId) {
                        location = {
                          pathname: "add-user",
                          state: { roleId: val },
                        };
                      } else {
                        location = {
                          pathname: `/edit-profile/${userId}`,
                          state: { roleId: val },
                        };
                      }
                      history.push(location);
                    } else {
                      setFieldValue("roleId", val);
                    }
                  }}
                >
                  <option>Choose User Type</option>
                  <option value="5">Distributor</option>
                  {isRetailerRequired === "true" && (
                    <option value="6">Retailer</option>
                  )}
                  <option value="7">Influencer</option>
                </Field>
                <Label htmlFor="roleId">User Type</Label>
              </FormGroup>
            </Col>
          )} */}
          {/* <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
            Mandotary Details
          </h6> */}
          {/* <hr /> */}
          {isVisibleField("KYC Document Type") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                <Field
                  style={{ textAlign: "start", border: "1px solid #B0B0B0" }}
                  required={isRequiredField("KYC Document Type")}
                  as="select"
                  id="kycTypeId"
                  name="kycTypeId"
                  className="form-control"
                >
                  <option value="1">PAN Card</option>
                  <option value="2">Aadhar Card</option>
                </Field>
                <Label htmlFor="kycTypeId">KYC Documnet Type</Label>
              </FormGroup>
            </Col>
          )}
          {isVisibleField("KYC Number") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("KYC Number")}
              label="KYC Number of Owner"
              errors={errors["kycNumber"]}
              name="kycNumber"
              touched={touched["kycNumber"]}
              type="text"
            />
          )}

          {isVisibleField("Document Type") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                <Field
                  style={{ textAlign: "center" }}
                  type="select"
                  id="docType"
                  name="docType"
                  as={Input}
                  required={isRequiredField("Document Type")}
                >
                  <option value="1">GSTIN Certificate</option>
                  <option value="3">Shop License Certificate</option>
                </Field>
                <Label htmlFor="docType">Document Type</Label>
              </FormGroup>
            </Col>
          )}
          <small className="text-muted">
            Either GSTIN or Shop License Certificate is Required.
          </small>
          {isVisibleField("GSTIN") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("GSTIN")}
              label="GSTIN"
              errors={errors["gstIn"]}
              name="gstIn"
              touched={touched["gstIn"]}
              type="text"
            />
          )}
          {isVisibleField("AAdhar Card Number") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("AAdhar Card Number")}
              label="Aadhar card number"
              errors={errors["aadharOwner"]}
              name="aadharOwner"
              touched={touched["aadharOwner"]}
              type="text"
            />
          )}
          {isVisibleField("Pan Number") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Pan Number")}
              label="PAN card number"
              errors={errors["PanNumber"]}
              name="PanNumber"
              touched={touched["PanNumber"]}
              type="text"
            />
          )}
          <hr />
          {isVisibleField("Regional Sales Manager") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Regional Sales Manager")}
              label="Regional Sales Manager"
              errors={errors["RSM"]}
              name="RSM"
              touched={touched["RSM"]}
              type="text"
            />
          )}
          {isVisibleField("Area Sales Manager") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Area Sales Manager")}
              label="Area Sales Manager"
              errors={errors["ASM"]}
              name="ASM"
              touched={touched["ASM"]}
              type="text"
            />
          )}
          {isVisibleField("Zone") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                <Field
                  style={{ textAlign: "start", border: "1px solid #B0B0B0" }}
                  type="select"
                  id="zone"
                  name="zone"
                  as={Input}
                  required={isRequiredField("Zone")}
                >
                  <option>Choose Zone</option>
                  <option value="North">North</option>
                  <option value="South">South</option>
                  <option value="West">West</option>
                  <option value="Central">Central</option>
                  <option value="East">East</option>
                </Field>
                <Label htmlFor="zone">Zone</Label>
              </FormGroup>
            </Col>
          )}
          {/* <br /> */}
          <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
            Shop Details
          </h6>
          {/* <hr /> */}
          {isVisibleField("Date of Birth") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Date of Birth")}
              label="Owner Date Of Birth"
              errors={errors["ownerDob"]}
              name="ownerDob"
              touched={touched["ownerDob"]}
              type="date"
              onChange={e =>
                handleDateError(setFieldValue, "ownerDob", e.target.value)
              }
            />
          )}
          {isVisibleField("Whatsapp Number") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Whatsapp Number")}
              label="Owner WhatsApp Number"
              errors={errors["ownerWhatsApp"]}
              name="ownerWhatsApp"
              touched={touched["ownerWhatsApp"]}
              type="tel"
            />
          )}
          {isVisibleField("Aniversary") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Aniversary")}
              label="Owner Anniversary"
              errors={errors["ownerAnniversary"]}
              name="ownerAnniversary"
              touched={touched["ownerAnniversary"]}
              type="date"
              onChange={e =>
                handleDateError(
                  setFieldValue,
                  "ownerAnniversary",
                  e.target.value
                )
              }
            />
          )}
          {isVisibleField("Language") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                <Field
                  style={{ textAlign: "start", border: "1px solid #B0B0B0" }}
                  required={isRequiredField("Language")}
                  type="select"
                  id="language"
                  name="language"
                  as={Input}
                >
                  <option>Choose Language</option>
                  <option value="Hindi">Hindi</option>
                  <option value="English">English</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Tamil">Tamil</option>
                  <option value="Telgu">Telgu</option>
                  <option value="Malyalam">Malyalam</option>
                  <option value="Marathi">Marathi</option>
                  <option value="Bengali">Bengali</option>
                  <option value="Gujrati">Gujrati</option>
                  <option value="Punjabi">Punjabi</option>
                  <option value="Odiya">Odiya</option>
                  <option value="Assamese">Assamese</option>
                </Field>
                <Label htmlFor="language">Language</Label>
              </FormGroup>
            </Col>
          )}

          {isVisibleField("Email") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Email")}
              label="Outlet Email"
              errors={errors["outletEmail"]}
              name="outletEmail"
              touched={touched["outletEmail"]}
              type="email"
              onChange={e => {
                let value = e.target.value || "";
                value = value.toUpperCase().trim();
                setFieldValue("outletEmail", value);
              }}
            />
          )}
          {isVisibleField("Landline Number") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Landline Number")}
              label="Outlet Landline Number"
              errors={errors["outletLandlineNumber"]}
              name="outletLandlineNumber"
              touched={touched["outletLandlineNumber"]}
              type="tel"
            />
          )}

          {isVisibleField("Outlet Type") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                <Field
                  style={{ textAlign: "start", border: "1px solid #B0B0B0" }}
                  required={isRequiredField("Outlet Type")}
                  type="select"
                  id="outletType"
                  name="outletType"
                  as={Input}
                >
                  <option value="A">A</option>
                  <option value="+A">A+</option>
                  <option value="B">B</option>
                  <option value="+B">B+</option>
                </Field>
                <Label htmlFor="outletType">Outlet Type</Label>
              </FormGroup>
            </Col>
          )}

          {isVisibleField("Address") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Address")}
              label="Address"
              errors={errors["address"]}
              name="address"
              touched={touched["address"]}
              type="text"
              onChange={e => {
                let value = e.target.value || "";
                value = value.toUpperCase().trim();
                setFieldValue("address", value);
              }}
            />
          )}

          {isVisibleField("Pin Code") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Pin Code")}
              label="Pin Code"
              errors={errors["pinCode"]}
              name="pinCode"
              touched={touched["pinCode"]}
              type="number"
            />
          )}
          {/* {isVisibleField("Beat ControlName of User") && (
            <FormGroup row>
              {/* <Col md="3">
                <Label htmlFor="beatsName">Beats Name</Label>
              </Col> 
              <Col xs="12" md="9" className="p-0">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#3A69DD" : "#B0B0B0",
                      padding: "10px",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "0px",
                    }),
                  }}
                  placeholder="beat name"
                  id="beatsName"
                  options={beat}
                  isSearchable
                  closeMenuOnSelect
                  value={values.beatsName}
                  onChange={val => {
                    setFieldValue("beatsName", val);
                  }}
                />
              </Col>
            </FormGroup>
          )} */}
          <Card>
            <CardBody>
              <Label className="kp">
                <PlusCircleOutlined
                  style={{ color: "green", fontSize: "16px" }}
                  onClick={() => counterBoyAdd(values, setValues)}
                />
                Add Counter Boy
              </Label>
              <hr />
              {values.counterBoy.map((item, i) => (
                <div key={i}>
                  <FormikInput
                    style={{ textAlign: "start" }}
                    label={`Counter Boy Name`}
                    errors={errors["counterBoy"]}
                    name={`counterBoy[${i}].countername`}
                    touched={touched["counterBoy"]}
                    type="text"
                  />
                  <FormikInput
                    style={{ textAlign: "start" }}
                    label={`Counter Boy Number`}
                    errors={errors["counterBoy"]}
                    name={`counterBoy[${i}].contactnumber`}
                    touched={touched["counterBoy"]}
                    type="number"
                  />
                  <DeleteOutlined
                    style={{ color: "red", fontSize: "16px" }}
                    onClick={() => deleteCountryBoy(values, setValues, i)}
                  />
                  <hr />
                </div>
              ))}
            </CardBody>
          </Card>
          {/* <br /> */}
          <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
            Payment Details
          </h6>
          {/* <hr /> */}
          {isVisibleField("Full ControlName as per bank") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                {/* {
              isDisabled() &&
              <Col xs="12" md="1">
                <Tooltip title="Clear bank details">
                  <MinusCircleTwoTone
                    twoToneColor="red"
                    onClick={() => deletePaymentDetails('Bank', userId, setFieldValue)}
                  />
                </Tooltip>
              </Col>
            } */}
                <Field
                  style={{ textAlign: "start", border: "1px solid #B0B0B0" }}
                  required={isRequiredField("Full ControlName as per bank")}
                  type="text"
                  id="accHolderName"
                  name="accHolderName"
                  placeholder="Account Holder Name"
                  as={Input}
                />
                <Label htmlFor="accHolderName">Account Holder Name</Label>
              </FormGroup>
            </Col>
          )}
          {isVisibleField("Bank account number") && (
            <>
              <FormikInput
                style={{ textAlign: "start" }}
                required={isRequiredField("Bank account number")}
                label="Bank Account Number"
                errors={errors["bankAccNumber"]}
                name="bankAccNumber"
                touched={touched["bankAccNumber"]}
                type="text"
                disabled={isDisabled()}
              />
              <FormikInput
                style={{ textAlign: "start" }}
                label="Confirm Bank Account Number"
                errors={errors["bankAccNumber"]}
                name="bankAccNumber"
                touched={touched["bankAccNumber"]}
                type="text"
                disabled={isDisabled()}
              />
            </>
          )}

          {isVisibleField("ControlName of your bank") && (
            <FormikInput
              style={{ textAlign: "start" }}
              label="Bank Name"
              errors={errors["bankName"]}
              name="bankName"
              touched={touched["bankName"]}
              type="text"
              disabled={isDisabled()}
            />
          )}

          {isVisibleField("Bank IFSC Code") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Bank IFSC Code")}
              label="IFSC Code"
              errors={errors["ifscCode"]}
              name="ifscCode"
              touched={touched["ifscCode"]}
              type="text"
            />
          )}

          {isVisibleField("Paytm Number") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                {/* {
              isDisabled() &&
              <Col xs="12" md="1">
                <Tooltip title="Clear paytm number">
                  <MinusCircleTwoTone
                    twoToneColor="red"
                    onClick={() => deletePaymentDetails('Paytm', userId, setFieldValue)}
                  />
                </Tooltip>
              </Col>
            } */}
                <Field
                  style={{ textAlign: "start", border: "1px solid #B0B0B0" }}
                  required={isRequiredField("Paytm Number")}
                  type="number"
                  id="paytmNumber"
                  name="paytmNumber"
                  placeholder="Paytm Number"
                  as={Input}
                  disabled={isDisabled()}
                />
                <Label htmlFor="paytmNumber">Paytm Number</Label>
              </FormGroup>
            </Col>
          )}
          {/* <FormikInput
            label="UPI ID"
            errors={errors['upiId']}
            name="upiId"
            touched={touched['upiId']}
            type="text"
          /> */}
          {/* <br /> */}
          <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
            Shop Details
          </h6>
          {/* <hr /> */}

          {isVisibleField("Organisation Establishment") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Organisation Establishment")}
              label="Organisation Establishment"
              errors={errors["orgEstDate"]}
              name="orgEstDate"
              touched={touched["orgEstDate"]}
              type="date"
              className="form-control"
            />
          )}

          {isVisibleField("Firm Question") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Firm Question")}
              label="What products does the Shop Sell ?"
              errors={errors["questAnswer"]}
              name="questAnswer"
              touched={touched["questAnswer"]}
              type="text"
            />
          )}
          {/* <br /> */}
          <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
            Other Optional Details
          </h6>
          {/* <hr /> */}
          {isVisibleField("Alternate Phone Number") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Alternate Phone Number")}
              label="Alternate Phone Number"
              errors={errors["AlterNetNumber"]}
              name="AlterNetNumber"
              touched={touched["AlterNetNumber"]}
              type="tel"
            />
          )}
          {isVisibleField("Salutation") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Salutation")}
              label="Salutation"
              errors={errors["Salutation"]}
              name="Salutation"
              touched={touched["Salutation"]}
              type="text"
            />
          )}
          {isVisibleField("Primary Mobile Number") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Primary Mobile Number")}
              label="Primary Mobile Number"
              errors={errors["PrimaryMobile"]}
              name="PrimaryMobile"
              touched={touched["PrimaryMobile"]}
              type="tel"
            />
          )}
          {isVisibleField("Secoundary Mobile Number") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Secoundary Mobile Number")}
              label="Secondary Mobile Number"
              errors={errors["SecondaryMobile"]}
              name="SecondaryMobile"
              touched={touched["SecondaryMobile"]}
              type="tel"
            />
          )}
          {isVisibleField("Facebook Profile URL") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Facebook Profile URL")}
              label="Facebook Profile URL"
              errors={errors["FacebookId"]}
              name="FacebookId"
              touched={touched["FacebookId"]}
              type="text"
            />
          )}
          {isVisibleField("Instagram Id") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Instagram Id")}
              label="Instagram Id"
              errors={errors["InstagramId"]}
              name="InstagramId"
              touched={touched["InstagramId"]}
              type="text"
            />
          )}
          {isVisibleField("LinkDIn Profile URL") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("LinkDIn Profile URL")}
              label="LinkedIn Profile URL"
              errors={errors["LinkedInProfile"]}
              name="LinkedInProfile"
              touched={touched["LinkedInProfile"]}
              type="text"
            />
          )}
          {isVisibleField("Twitter Handle ") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Twitter Handle ")}
              label="Twittter Handle"
              errors={errors["TwittterHandle"]}
              name="TwittterHandle"
              touched={touched["TwittterHandle"]}
              type="text"
            />
          )}
          {isVisibleField("First ControlName") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("First ControlName")}
              label="First Name"
              errors={errors["FirstName"]}
              name="FirstName"
              touched={touched["FirstName"]}
              type="text"
            />
          )}
          {isVisibleField("Middle ControlName") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Middle ControlName")}
              label="Middle Name"
              errors={errors["MiddleName"]}
              name="MiddleName"
              touched={touched["MiddleName"]}
              type="text"
            />
          )}
          {isVisibleField("Last ControlName") && (
            <FormikInput
              style={{ textAlign: "start" }}
              required={isRequiredField("Last ControlName")}
              label="Last Name"
              errors={errors["LastName"]}
              name="LastName"
              touched={touched["LastName"]}
              type="text"
            />
          )}
          {isVisibleField("UPI Id") && (
            <Col xs="12" md="9">
              <FormGroup floating row>
                {/* {
              isDisabled() &&
              <Col xs="12" md="1">
                <Tooltip title="Clear UPI Id">
                  <MinusCircleTwoTone
                    twoToneColor="red"
                    onClick={() => deletePaymentDetails('Upi', userId, setFieldValue)}
                  />
                </Tooltip>
              </Col>
            } */}
                <Field
                  style={{ textAlign: "start", border: "1px solid #B0B0B0" }}
                  required={isRequiredField("UPI Id")}
                  type="text"
                  id="UpiId"
                  name="UpiId"
                  placeholder="Upi id"
                  as={Input}
                  disabled={isDisabled()}
                />
                <Label htmlFor="UpiId">UPI Id</Label>
              </FormGroup>
            </Col>
          )}

          <div className="d-flex justify-content-center flex-row">
            <Button
              style={{
                marginRight: "10px",
                background: "#3A69DD",
                padding: "6px 20px",
              }}
              type="submit"
              size="sm"
              color="primary"
              className="mr5 rounded-5"
              disabled={!dirty || userApproved}
            >
              <i className="fa fa-dot-circle-o"></i>
              Submit
            </Button>
            {isSubmitting ? <Spinner color="warning" /> : null}
            <Button
              style={{
                // marginRight: "10px",
                // background: "#3A69DD",
                padding: "6px 20px",
              }}
              type="reset"
              className="rounded-5"
              size="sm"
              color="danger"
            >
              <i className="fa fa-ban"></i> Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
