import React, { useState } from "react";
import { Tabs } from "antd";
import RetailerInvoicesList from "../Invoices/RetailerInvoicesList";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Invoices() {
  const [refetch, setRefetch] = useState(false)
  return (
    <div className="p-2">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="fs-1">Order Confirmation</div>
        {/* <div className="d-flex flex-row">
          <Link
          to={"/home"}
            style={{
              background: "#2E008B",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          ></Link>
        </div> */}
      </div>

      <div className="my-3">
        <input
          className="input-grey-rounded"
          type="text"
          placeholder="Search"
        ></input>
      </div>

<Tabs defaultActiveKey="0">
    <Tabs.TabPane tab="Pending" key="0">
      <RetailerInvoicesList status={0} refetch={refetch} setRefetch={setRefetch} showApprove={true} />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Approved" key="1">
      <RetailerInvoicesList status={1} refetch={refetch} setRefetch={setRefetch} showApprove={false} />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Rejected" key="2">
      <RetailerInvoicesList status={2} refetch={refetch} setRefetch={setRefetch} showApprove={false} />
    </Tabs.TabPane>
  </Tabs>
    </div>
  );
}

export default Invoices;
